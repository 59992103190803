/**
 * 系统配置
 */

// 内网域账户登录地址
export const INTRANET_LOGIN_URL =
  'https://login.ops.qihoo.net:4436/sec/login?ref='

// 360账户登录地址
export const QIHOO_LOGIN_URL =
  'https://i.360.cn/login?src=pcw_sandboxcloud&domainList=360.net,360.cn&destUrl='

// Github账户登录地址
export const GITHUB_LOGIN_URL =
  'https://github.com/login/oauth/authorize?client_id=429ea9308fd472806152&redirect_uri='
