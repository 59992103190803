<template>
  <div class="other-layout__header">
    <div class="left">
      <!-- <app-logo /> -->
      <app-logo type="home"/>
      <app-menu />
    </div>
    <div class="right">
      <div class="transform">
        <div :class="{searching: isHeaderSearching}" class="duration">
          <!-- <span></span>
          <p>360全网安全大脑高效赋能 <font>{{diffDay}}</font> 天</p> -->
        </div>
        <!-- <el-divider direction="vertical" /> -->
        <app-search @focusFn="focusFn"/>
      </div>
      <app-lang style="margin-right: 35px;" />
      <!-- <div class="right-space"></div> -->
      <app-theme></app-theme>
      <!-- <div class="right-space"></div> -->
      <!-- <div class="right-space" v-if="loginState"></div> -->
      <app-notice v-if="loginState" />
      <!-- <div class="right-space"></div> -->
      <app-account />
    </div>
  </div>
</template>

<script setup>
import AppLogo from '../app-logo'
import AppMenu from '../app-menu-standard'
import AppSearch from '../app-search'
import AppLang from '../app-lang'
import AppTheme from '../app-theme'
import AppNotice from '../app-notice'
import AppAccount from '../app-account'
// import dayjs from 'dayjs'
import { useStore } from 'vuex'
import { ref, computed } from 'vue'

const store = useStore()
const loginState = computed(() => {
  return store.getters.isLogin
})

// const diffDay = dayjs().diff(dayjs('2020-09-30'), 'day')
const isHeaderSearching = ref(false)
function focusFn(val) {
  isHeaderSearching.value = val
}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
.other-layout__header {
  width: 100%;
  height: $header-height;
  background-color: var(--color-bg-1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px 0 18px;
  box-sizing: border-box;
  box-shadow: 0 4px 5px 0 #0000000f;
  position: relative;
  z-index: 25;
  .left,
  .right {
    display: flex;
    height: 100%;
    align-items: center;
    .transform {
      display: flex;
      align-items: center;
      width: 295px;
      padding-right: 40px;
      position: relative;
      .el-divider {
        height: 1.5em;
      }
      .searching {
        width: 62px !important;
      }
      .duration  {
        width: 255px;
        height: 50px;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: var(--color-text-1)cc;
        transition: width .5s;
        span  {
          display: block;
          width: 26px;
          height: 16px;
          background-image: url('~@/assets/img/index/brain.png');
          background-size: 100% 100%;
          margin-right: 8px;
          opacity: 0.8;
        }
        p {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          flex:1;
        }
        font  {
          margin: 0 3px;
          color: #02a979;
        }
      }
    }
  }
  .right-space {
    width: 20px;
  }
}
</style>
