/**
 * 判断是否为外部资源
 */
export function isExternal(str) {
  return /^(https?:|mailto:|tel:)/.test(str)
}

export function isUserName(str) {
  return /^([\u4E00-\uFA29]|[\uE7C7-\uE7F3]|[a-zA-Z0-9_-]|(\.)){1,100}$/.test(
    str
  )
}

export function isPassword(str) {
  return (
    /[a-z]/.test(str) &&
    /[A-Z]/.test(str) &&
    /[0-9]/.test(str) &&
    /[`\-~!@#$%^&*()_+<>?:"{},./;'[\]]/.test(str)
  )
}

export function isVerCode(str) {
  return /^[a-zA-Z0-9]{4}$/.test(str)
}

// 验证咨询相关
export function isOrgName(str) {
  return /^[a-zA-Z0-9_\u4e00-\u9fa5]{1,50}$/.test(str)
}

export function isEmail(str) {
  return /^[A-Za-z0-9]+([_.-][A-Za-z0-9]+)*@([A-Za-z0-9-]+\.)+[A-Za-z]{2,6}$/.test(str)
}

export function isPhone(str) {
  return /^1(3|4|5|6|7|8|9)\d{9}$/.test(str)
}
