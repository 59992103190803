import { watch } from 'vue'
import i18n from 'i18n'
import store from '@/store'

/**
 *
 * @param  {...any} cbs 所有的回调
 */
export function watchSwitchLang(...cbs) {
  watch(
    () => store.getters.language,
    () => {
      cbs.forEach((cb) => cb(store.getters.language))
    }
  )
}

export function isZh() {
  return store?.getters.language === 'zh'
}

/**
 * 国际化快捷函数
 * @param  {...any} params
 * @returns
 */
export function t(...params) {
  return i18n.global.t(...params)
}

/**
 * 国际化请选择
 * @param  {...any} params
 * @returns
 */
export function tSelect(...keys) {
  keys = keys.map((key) => t(key))
  return t('tip.select', keys)
}

/**
 * 国际化请输入
 * @param  {...any} keys
 * @returns
 */
export function tInput(...keys) {
  keys = keys.map((key) => t(key))
  return t('tip.input', keys)
}

/**
 * 国际化标签
 * @param {*} key
 * @returns
 */
export function tLabel(key) {
  return t('tip.label', [t(key)])
}

/**
 * 国际化规则
 * @param {*} rule
 * @param  {...any} params
 * @returns
 */
export function tRule(rule, ...params) {
  return t(
    `rule.${rule}`,
    params.map((param) => t(param))
  )
}

export default (app) => {
  app.config.globalProperties.$tSelect = tSelect
  app.config.globalProperties.$tInput = tInput
  app.config.globalProperties.$tLabel = tLabel
  app.config.globalProperties.$tRule = tRule
  app.config.globalProperties.$isZh = isZh
}
