export default {
  account_manager: {
    account_info: '账号信息',
    basic_info: '基本信息',
    apikey_desc: 'API 服务已经升级，V1版本 API 接口将持续提供服务到 2023 年 2 月 28 日 15 点，之后将不再提供服务。为了保证您业务使用正常，请您尽快按照“帮助中心”说明文档，使用新的 API KEY 和 V2 版本 API 接口请求方式进行 API 服务调用。',
    regist_time: '注册时间',
    create_apikey: '生成 API KEY',
    reset_apikey: '重置 API KEY',
    apikey_info: 'API 说明',
    bind_email: '绑定邮箱地址',
    bind_phone: '绑定手机号码',
    bind_new_email: '绑定新邮箱地址',
    bind_new_phone: '绑定新手机号码',
    verification_code: '验证码',
    country: '国家地区',
    chinese_mainland: '中国大陆 {0}',
    reset_apikey_info: '重置 API KEY 后，原 API KEY 将不可用，请确认是否重置？',
    upload_user_pic: {
      crop_pic: '裁剪图片',
      pic_preview: '头像预览',
      reupload: '重新上传'
    },
    edit_user: {
      action: '修改用户名',
      safety_verification: '安全验证',
      safety_info: '为了保护您的账号安全，操作前请您进行安全验证',
      used_email: '使用已绑定邮箱地址 {0}',
      used_phone: '使用已绑定手机号码 {0}',
      get_captach: '获取验证码',
      captach_interval: '{0}秒后重试',
      change_verification: '切换{0}验证',
      username_valid: '用户名不符合规范',
      username_empty: '用户名不能为空',
      email_length: '邮箱长度不能大于30个字符',
      email_valid: '邮箱不符合规范',
      email_empty: '邮箱不能为空',
      verify_code_length: '请输入6位验证码',
      verify_code: '请输入验证码',
      phone_valid: '手机号不符合规范',
      phone_empty: '手机号不能为空',
      phone_required: '请先填写手机号码',
      email_required: '请先填写邮箱地址',
      username_modified: '用户名修改成功',
      code_send_err: '验证码发送失败',
      code_obtain: '验证码发送失败，请重新获取！',
      code_exceed: '今日验证次数已用完',
      frequency_too_fast: '发送频率过快，请稍后尝试！',
      phone_unbinded: '手机号码未绑定，请先进行绑定！',
      email_modify_total_limited: '邮箱地址修改超过当⽇修改次数，请明天再试！',
      email_modify_frequency_limited: '邮箱地址修改超过当前修改频次，请稍后再试！',
      email_binded_by_other: '此邮箱地址已有绑定用户，请更换',
      email_unbinded: '邮箱地址未绑定，请先进行绑定！',
      code_sended: '验证码发送成功',
      phone_modify_total_limited: '手机号码修改超过当⽇修改次数，请明天再试！',
      phone_modify_frequency_limited: '手机号码修改超过当前修改频次，请稍后再试！',
      phone_binded_by_other: '此手机号码已有绑定用户，请更换',
      err_code: '验证码错误',
      safety_verification_err: '安全验证未通过',
      email_modified: '邮箱地址修改成功',
      phone_modified: '手机号码修改成功',
      reset_apikey: '重置 API KEY 需要绑定邮箱地址或手机号码安全验证，请先进行绑定！',
      generate_apikey: '生成 API KEY 需要绑定邮箱地址或手机号码安全验证，请先进行绑定！',
      avatar_size_limit: '上传图片不得超过3M',
      avatar_type_limit: '请上传 JPG、PNG、GIF 类型图片',
      avatar_updated: '用户头像更新成功'
    },
    subscription: {
      title: '订阅版本/配额',
      current_version: '当前版本',
      unlimited: '无限制',
      max_file_size: '文件大小上限',
      max_time: '检测时长上限',
      task_frequency_1d: '提交任务配额(每天)',
      task_frequency_1m: '投递频率限制(每分钟)',
      team_count: '所在团队总数',
      team_invate: '单个团队添加成员',
      team_quota: '创建团队',
      view_task_frequency_1d: '查看任务配额(每天)',
      max_count_once: '单次提交任务数量限制',
      search_frequency_1d: '搜索次数配额(每天)',
      search_frequency_1m: '搜索频率限制(每分钟)',
      dropped_frequency_1d: '下载释放文件配额(每天)',
      pcap_frequency_1d: '下载PCAP配额(每天)',
      sample_frequency_1d: '下载样本配额(每天)',
      memdumps_frequency_1d: '下载释放内存配额(每天)',
      pdf_frequency_1d: '下载PDF报告配额(每天)',
      ai_frequency_1d: '分析助手提问配额(每天)',
      ai_frequency_1m: '分析助手提问频率限制(每分钟)',
      bout: '次',
      indivual: '个',
      create: '创建',
      used: '已用',
      second: '秒',
      minute: '分',
      hour: '小时'
    }
  },
  point_manager: {
    title: '我的积分',
    current_point: '当前积分',
    used_point: '累计消费积分',
    used_point_info: '累计消费积分说明',
    get_point: '累计获得积分',
    get_point_info: '累计获得积分说明',
    recharge: '充值',
    point_record: '积分记录',
    point_benefits: '积分权益',
    time: '时间',
    point_change: '积分变动',
    content_change: '内容变动',
    no_data: '暂无积分权益数据',
    all: '全部',
    get: '获得',
    used: '消费',
    get_type: '获取方式',
    rechange_count: '充值数额',
    used_type: '消费方式',
    point_dialog: {
      get_first_line: '沙箱云账户注册日期至今累计获得的积分',
      get_second_line: '积分可通过充值获得。可在“我的积分”页面或通过兑换权益功能充值。兑换权益包括：任务配置权益、报告内容展示权益、文件下载权益、公开任务展示时间范围。',
      get_third_line: '目前积分可以充值 100、200、500、1000、2000、自定义（最高 5000）',
      used_first_line: '沙箱云账户注册日期至今累计消费的积分',
      used_second_line: '积分可通过兑换权益消费。可以兑换的权益包括：',
      used_third_line: '任务配置权益：专业版 5 次（50 积分）；专业增强版 5 次（80 积分）',
      used_fourth_line: '报告内容展示权益：专业版 1 天（30 积分）；专业增强版 1 天（50 积分）',
      used_fifth_line: '下载文件权益：10 个（200 积分）；20 个（400 积分）；50 个（1000 积分）；100 个 （2000 积分）',
      used_sixth_line: '公开任务展示时间范围：专业版 1 天（30 积分）；专业增强版 1 天（50 积分）'
    },
    point_desc: {
      used: '已使用',
      task_conf_benefits: '任务配置权益',
      show_report_content: '报告内容展示',
      expire_date: '到期时间',
      available_time: '（可用时长）',
      available_count: '（可用数量）',
      show_sample_tiem_range: '样本展示时间范围',
      download_file: '下载文件'
    }
  },
  subscribe: {
    pakage_plan: '选择套餐方案',
    renewal_version: '续费版本',
    updated_version: '升级版本',
    renewal_time: '续费时长',
    updated_time: '升级时长',
    upgrade_duration: '{0} 天',
    orginal_price: '原价¥ {0}',
    save: '立省{0}元',
    total_price: '商品总价',
    saved: '原价¥ {0} 已为您节省{1}元',
    upgrade_plan_info_tips: '订阅专业增强版的时长*（专业增强版每月价格/30天-专业版每月价格/30天）。',
    plan_info_tips: '订阅满1年享受 5 折优惠',
    duration: '升级服务时间 {0} 至 {1}',
    to_renewal: '立即续费',
    to_updated: '立即升级',
    read_agree: '阅读并同意',
    user_agreement: '《360沙箱云用户协议》',
    user_agreement_tip: '请阅读并同意“360沙箱云用户协议”',
    professional: '提供 Windows 7 64 位操作系统分析环境，增加 HTTPS 流量解密和检测专项漏洞利用、勒索病毒和检测逃避行为的能力。',
    enterprise: '提供 Windows 10 32/64 位操作系统分析环境，增加本地参数配置、重启检测等特性以及增加对已知漏洞攻击、关键漏洞利用和 APT 攻击行为的检测能力。',
    confirm_pd_info: '确认商品信息',
    back: '返回重新选择',
    product_title: '360沙箱云',
    product_desc_renew: '续费{0}',
    product_desc_upgrade: '由专业版升级至专业增强版',
    product_desc_free: '由免费版升级至{0}',
    service_time: '服务时间',
    total_items: '商品总计',
    discount: '优惠',
    annual_discount: '按年折扣',
    actually_paid: '实付金额',
    submit_order: '提交订单',
    count_down: '订单提交成功，请您在 {hour} 时 {min} 分 {sec} 秒内完成支付',
    order_num: '订单号',
    amounts_payable: '应付金额',
    confirm_payment: '确认支付',
    check_order: '查看订单',
    payment_failed: '支付失败',
    payment_cancel: '订单已取消',
    payment_paid: '支付成功',
    payment_unsubscribe: '退定中',
    payment_timeout: '订单已超时',
    unsubscribed: '退定成功',
    order_err: '订单出错',
    product_score: '积分购买 {0}积分',
    product_duration: '{0}个月',
    left_time: '(剩余 {0} 时 {1} 分 {2} 秒)'
  },
  subscription_manager: {
    title: '我的订阅',
    account_version: '账号版本',
    start_time: '服务开通时间',
    end_time: '服务截止时间',
    upgrade: '升级',
    renew: '续费'
  },
  order_manager: {
    title: '订单记录',
    index: '订单编号',
    create_time: '创建时间',
    service_content: '服务内容',
    type: '交易类型',
    amount: '金额({0})',
    status: '交易状态',
    action: '操作',
    pay_order: '去支付',
    cancel_order: '取消订单',
    product_map: {
      professional: '专业版套餐',
      enterprise: '专业增强版套餐',
      score: '积分购买'
    },
    type_map: {
      purchase: '新购',
      renew: '续费',
      upgrade: '升级',
      refund: '退款'
    },
    status_map: {
      non_payment: '待支付',
      pay_timeout: '已取消(支付超时)',
      paid: '完成',
      payment_failed: '已取消',
      cancelled: '已取消',
      cancel: '已取消',
      unsubscribe: '完成',
      unsubscribed_successfully: '完成'
    }
  },
  team_manager: {
    owner_team: '我的团队',
    create_team: '创建团队',
    no_team_info: '您暂无团队，请创建团队~',
    invitation_received: '我收到的邀请',
    invitation_dialog_title: '请升级服务方案',
    invitation_dialog_content: '加入团队配额已满，请升级至专业版或专业增强版获得更多配额。',
    index: '序号',
    team_name: '团队名称',
    team_owner: '团队管理员',
    receive_time: '邀请时间',
    accept: '接受',
    accepted: '已接受',
    reject: '拒绝',
    rejected: '已拒绝',
    expired: '已失效',
    quited: '已退出',
    show_team_task: '查看团队任务',
    add_member: '添加成员',
    quit_team: '退出团队',
    edit_team: '编辑团队',
    dele_team: '删除团队',
    dele_member: '移除成员',
    re_invite: '重新邀请',
    is_mine: '（我）',
    edit_remark: '编辑备注',
    place_input_remark: '请输入备注',
    place_input_content: '请输入所有内容',
    quit_team_info: '退出团队后将无法查看团队任务，是否确认退出“{0}”团队？',
    dele_team_info: '删除团队后团队成员和团队任务列表信息将被删除，请确认是否删除团队？',
    invisible_each_other: '管理员已设置成员互不可见',
    admin: '管理员',
    member: '成员',
    dialog: {
      input_username_or_email: '输入用户名、邮箱搜索邀请人员',
      joined: '已加入',
      invited: '已邀请',
      no_search_user: '没有搜索到相关用户',
      member: '成员',
      visible_each_other: '团队成员相互可见',
      team_name: '团队名称',
      input_team_name: '请输入团队名称',
      valid_team_name: '团队名称不能为空',
      valid_team_name_length: '长度在 1 到 15 个字符',
      input_remark: '请输入备注',
      create_team_info: '创建或加入团队配额已满，请升级至专业增强版获得更多配额',
      added: '添加成功',
      invited_success: '邀请成功'
    }
  }
}
