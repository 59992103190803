import dayjs from 'dayjs'
import { t } from 'app/i18n'

const unique = (list, attr) => {
  const res = new Map()
  return list.filter((item) => !res.has(item[attr]) && res.set(item[attr], 1))
}

const download = ({ content, type, filename }) => {
  const blob = new Blob([content], { type })
  const urlObject = window.URL || window.webkitURL || window
  const a = document.createElement('a')
  const url = urlObject.createObjectURL(blob)
  a.href = url
  a.download = filename.replace(new RegExp('"', 'g'), '')
  // console.log(filename)
  a.click()
  urlObject.revokeObjectURL(url)
}

const isExpired = (timestemp) => {
  if (!timestemp) return true
  return +dayjs(timestemp) < +new Date()
}

const splitUnit = (times) => {
  if (times === '') return
  const count = times.split('')[0]
  const unit = times.split('')[1].includes('m') ? t('account_manager.subscription.minute') : count === 30 ? t('other.utils.month') : t('other.utils.day')
  // const unit = times.split('')[1].includes('m') ? '分钟' : count === 30 ? '月' : '日'
  return { count, unit }
}

export {
  unique,
  download,
  isExpired,
  splitUnit
}
