/**
 * 浏览器BOM工具类
 */

// 获取URL查询参数值
export function getQuery(name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  var r = window.location.search.substr(1).match(reg)
  if (r != null) return unescape(r[2])
  return null
}

// 序列化URL查询参数
export function qs(data) {
  let res = ''
  for (const key in data) {
    res += encodeURIComponent(key) + '=' + encodeURIComponent(data[key]) + '&'
  }
  if (res.length > 0) {
    res = '?' + res.substring(0, res.length - 1)
  }
  return res
}

// 打开Url
export function openUrl(url, type = '_blank') {
  window.open(url, type)
}

// 下载Url
export function downloadUrl(url, filename = '') {
  console.log('downloadUrl: ' + url + '\nfilename:' + filename)
  const downloadLink = document.createElement('a')
  downloadLink.download = filename
  downloadLink.style.display = 'none'
  downloadLink.href = url
  downloadLink.setAttribute('download', filename)
  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}

// 返回上个路由
export function back(name) {
  console.log(window.history.back())
}

// 将URL字符串解析成对应的{host: '', hostname: ''}数据
// extra:{addProtocal: false, protocolType: 'http'}
// addProtocal是否额外添加协议头进行转换，例如传入 www.baidu.com 需要额外添加http://前缀
export function parseUrlData(urlStr, base, extra = {}) {
  let { addProtocal, protocolType } = extra || {}
  if (addProtocal) {
    protocolType = protocolType || 'http'
    urlStr = `${protocolType}://${urlStr}`
  }
  if (!urlStr || typeof urlStr !== 'string') {
    console.error('parseUrlData: urlStr(string required)')
    return urlStr
  }
  return new URL(urlStr, base)
}

// 是否为IE内核 IE不支持grid布局 当前项目定位不支持IE
export function isIE() {
  const status = !!window.ActiveXObject || 'ActiveXObject' in window
  if (status) {
    console.error('not support current browser', status)
  }
  return status
}

export function changeFavicon(url, id = 'favicon') {
  const dom = document.querySelector(`#${id}`)
  url && dom && dom.setAttribute('href', url)
}

export function changeTitle(title) {
  document.title = title
}

export function getFullPath() {
  const { href, host } = window.location
  const arr = href.split(host)
  return arr.length === 2 ? arr[1] : ''
}
