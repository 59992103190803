export default {
  chatgpt: {
    info: {
      title: '360 沙箱云分析助手',
      desc: '360 沙箱云分析助手，基于沙箱云报告查看场景对交互方式进行重塑，实现智能化报告解读，帮助用户理解报告中关键内容，提升用户安全运营的效率。',
      footerText: '后续我们还将持续挖掘更多场景，帮助用户解决更多问题，敬请期待',
      step1: {
        title: '1.生成报告总结',
        desc: '对报告内容，生成语句连贯、逻辑缜密、通俗易懂的报告总结，聚焦在报告中最关键的内容，提升理解报告效率。',
        line1: '“总结这个分析报告”',
        line2: '“报告的概览信息”',
        line3: '“结合报告概览信息，这个文件是不是恶意的”'
      },
      step2: {
        title: '2.解读报告内容片段',
        desc: '对报告特定内容，可以提供文字解读的同时，还可以以图片内容呈现。',
        line1: '“有哪些进程，以字符串图的形式绘制进程树”',
        line2: '“那些名为 cmd.exe 的进程，它们的分值是多少”',
        line3: '“1633 的进程图，以 mermaid 展示”'
      },
      step3: {
        title: '3.解答报告专业术语',
        desc: '对报告中的内容或描述，基于威胁百科知识库，解答标签、规则、各项字段等信息。',
        line1: '“自启动什么意思”',
        line2: '“通过反病毒引擎将目标文件认定为危险文件什么意思”',
        line3: '“在注册表中修改某些 INTERNET SETTING 设置什么意思”'
      },
      step4: {
        title: '4.生成处置建议',
        desc: '根据报告的检测结果，可以生成通俗易懂的处置建议，实现威胁检测和处置建议的全流程。',
        line1: '“报告中的样本我怎么处理”',
        line2: '“这个样本修改了我的注册表，怎么办”',
        line3: '“这个样本删除了我的文件目录，怎么办”'
      }
    },
    chat: {
      title: '沙箱云分析助手',
      desc: '简介',
      descTip: '分析助手简介',
      deleteTitle: '删除历史会话',
      stop: '停止生成',
      copy: '复制',
      placeholder: '请问需要什么帮助呢...',
      reanswer: '重新回答',
      dialog: {
        title: '请确认是否清空记录',
        content: '您正在清空历史分析记录，清空后将不能恢复，请确认是否仍要清空？'
      },
      message: {
        tip1: '这个文件是恶意的吗？为什么？',
        tip2: '这个报告中存在哪些恶意行为？',
        tip3: '这个文件运行了哪些进程？',
        tip4: '这个报告中存在哪些 ATT&CK 技术点？',
        tip5: '这个文件有哪些网络通信行为？',
        deleteWait: '请等待上一条消息回复后再删除历史会话。',
        stoped: '已停止回答',
        wait: '请等待上一条消息回复后再发送新消息。',
        placeInput: '请输入内容再进行提问',
        copySuccessful: '复制成功',
        tip: '我是沙箱云分析助手，很高兴能为您提供服务！您可以向我提出有关沙箱云的问题或指令，我将为您解答或执行您的指令。',
        placeWait: '思考中，请等待',
        serverError: '服务异常，请稍后尝试。',
        runOut: '本日频率已用完，请明日再来',
        tooHigh: '当前发送频率过高，请稍后发送',
        reTry: '服务开小差了，请稍后重试',
        notUnderstand: '抱歉，我还不够聪明，无法理解你的意思。'
      }
    }
  }
}
