export default {
  account_manager: {
    account_info: 'Profile',
    basic_info: 'Account',
    apikey_desc: 'The API service has been upgraded. The V1 version API interface will continue to provide services until 15:00 on February 28, 2023, and will no longer provide services after that. In order to ensure the normal use of your business, please follow the "Help Center" documentation as soon as possible and use the new API KEY and V2 version API interface request method to make API service calls.',
    regist_time: 'Registration time',
    create_apikey: 'Create API Key',
    reset_apikey: 'Regenerate API Key',
    apikey_info: 'API description',
    bind_email: 'Bind email address',
    bind_phone: 'Bind mobile phone number',
    bind_new_email: 'Bind new email address',
    bind_new_phone: 'Bind new mobile number',
    verification_code: 'Verification code',
    country: 'country',
    chinese_mainland: 'Mainland China {0}',
    reset_apikey_info: 'After resetting the API KEY, the original API KEY will be unavailable. Please confirm whether to reset it?',
    upload_user_pic: {
      crop_pic: 'Crop picture',
      pic_preview: 'Avatar preview',
      reupload: 're-upload'
    },
    edit_user: {
      action: 'Modify username',
      safety_verification: 'safety verification',
      safety_info: 'please perform security verification before operation.',
      used_email: 'Use bound email address {0}',
      used_phone: 'Use bound mobile phone number {0}',
      get_captach: 'get verification code',
      captach_interval: 'Try again in {0} seconds',
      change_verification: 'Toggle {0} verification',
      username_valid: 'Username does not meet specifications',
      username_empty: 'Username can not be empty',
      email_length: 'Email length cannot be greater than 30 characters',
      email_valid: 'Email does not meet specifications',
      email_empty: 'E-mail can not be empty',
      verify_code_length: 'Please enter the 6-digit verification code',
      verify_code: 'please enter verification code',
      phone_valid: 'Mobile phone number does not meet the specifications',
      phone_empty: 'Mobile phone number cannot be empty',
      phone_required: 'Please fill in your mobile phone number first',
      email_required: 'Please fill in your email address first',
      username_modified: 'Username modified successfully',
      code_send_err: 'Failed to send verification code',
      code_obtain: 'The verification code failed to be sent, please obtain it again!',
      code_exceed: 'The number of verifications today has been exhausted',
      frequency_too_fast: 'The sending frequency is too fast, please try again later!',
      phone_unbinded: 'The mobile phone number is not bound, please bind it first!',
      email_modify_total_limited: 'The email address has been modified more than the number of modifications on that day, please try again tomorrow!',
      email_modify_frequency_limited: 'This email address is already bound to a user, please change it.',
      email_binded_by_other: 'This email address is already bound to a user, please change it',
      email_unbinded: 'The email address is not bound, please bind it first!',
      code_sended: 'Verification code sent successfully',
      phone_modify_total_limited: 'The mobile phone number has been modified more than the number of modifications on that day. Please try again tomorrow!',
      phone_modify_frequency_limited: 'The mobile phone number has been modified more than the current modification frequency, please try again later!',
      phone_binded_by_other: 'This mobile number is already bound to a user, please change it.',
      err_code: 'Verification code error',
      safety_verification_err: 'Security verification failed',
      email_modified: 'Email address modified successfully',
      phone_modified: 'Mobile phone number modified successfully',
      reset_apikey: 'Resetting the API KEY requires binding your email address or mobile phone number for security verification. Please bind it first!',
      generate_apikey: 'Generating API KEY requires binding your email address or mobile phone number for security verification, please bind it first!',
      avatar_size_limit: 'Upload images must not exceed 3mb',
      avatar_type_limit: 'Please upload JPG, PNG, GIF type images',
      avatar_updated: 'User avatar updated successfully'
    },
    subscription: {
      title: 'Plan and Limits',
      current_version: 'Your plan',
      unlimited: 'Unlimited',
      max_file_size: 'Max input file size',
      max_time: 'Timeout',
      task_frequency_1d: 'Submission Limits / day',
      task_frequency_1m: 'Submission Limits / min',
      team_count: 'Total number of teams',
      team_invate: 'Add members to a single team',
      team_quota: 'Create a team',
      view_task_frequency_1d: 'View task limits / day',
      max_count_once: 'Single submission task limit',
      search_frequency_1d: 'Search limits / day',
      search_frequency_1m: 'Search limits / min',
      dropped_frequency_1d: 'Export dropped files / day',
      pcap_frequency_1d: 'Export PCAP / day',
      sample_frequency_1d: 'Export samples / day',
      memdumps_frequency_1d: 'Export Memdumps / day',
      pdf_frequency_1d: 'Export PDF report / day',
      ai_frequency_1d: 'Analysis assistant questions / day',
      ai_frequency_1m: 'Analysis assistant questions / min',
      bout: '',
      indivual: '',
      create: 'create',
      used: '',
      second: '',
      minute: 'point',
      hour: 'Hour'
    }
  },
  point_manager: {
    title: 'My points',
    current_point: 'Current points',
    used_point: 'Accumulated consumption points',
    used_point_info: 'Accumulated consumption points',
    get_point: 'Accumulated points earned',
    get_point_info: 'Accumulated points earned',
    recharge: 'top up',
    point_record: 'Points record',
    point_benefits: 'Points benefits',
    time: 'time',
    point_change: 'Points changes',
    content_change: 'Content changes',
    no_data: 'No points benefit data yet',
    all: 'all',
    get: 'get',
    used: 'Consumption',
    get_type: 'method of obtaining',
    rechange_count: 'Recharge amount',
    used_type: 'Consumption patterns',
    point_dialog: {
      get_first_line: 'Points accumulated since the date of sandbox cloud account registration',
      get_second_line: 'Points can be obtained by recharging. You can recharge on the "My Points" page or through the redemption benefit function. Exchange rights include: task configuration rights, report content display rights, file download rights, and public task display time range.',
      get_third_line: 'Currently, points can be recharged to 100, 200, 500, 1000, 2000, or customized (up to 5000)',
      used_first_line: 'Points accumulated from the sandbox cloud account registration date to date',
      used_second_line: 'Points can be spent by redeeming benefits. Benefits that can be redeemed include:',
      used_third_line: 'Task configuration rights: Pro plan 5 times (50 points); Pro Plus plan 5 times (80 points)',
      used_fourth_line: 'Report content display rights: Pro plan for 1 day (30 points);Pro Plus plan for 1 day (50 points)',
      used_fifth_line: 'Rights for downloading files: 10 (200 points); 20 (400 points); 50 (1000 points); 100 (2000 points)',
      used_sixth_line: '公开任务展示时间范围：专业版 1 天（30 积分）；专业增强版 1 天（50 积分）'
    },
    point_desc: {
      used: 'Used',
      task_conf_benefits: 'Task configuration rights',
      show_report_content: 'Report content display',
      expire_date: 'Expire date',
      available_time: '(Available duration)',
      available_count: '(Quantity Available)',
      show_sample_tiem_range: 'Sample display time range',
      download_file: 'download file'
    }
  },
  subscribe: {
    pakage_plan: 'Choose a package plan',
    renewal_version: 'Renewal plan',
    updated_version: 'Upgrade plan',
    renewal_time: 'Renewal time',
    updated_time: 'Upgrade duration',
    // eslint-disable-next-line no-template-curly-in-string
    upgrade_duration: '{0} days',
    // eslint-disable-next-line no-template-curly-in-string
    orginal_price: 'Original price ${0}',
    // eslint-disable-next-line no-template-curly-in-string
    save: 'Save ${0}',
    total_price: 'Total product price',
    // eslint-disable-next-line no-template-curly-in-string
    saved: 'Original price ${0} has saved you ${1}',
    upgrade_plan_info_tips: 'The length of subscription to Pro Plus plan* (Monthly Price of Pro Plus plan /30 Days - Monthly Price of Pro plan /30 Days), and you will enjoy a 50% discount if you subscribe for one year.',
    plan_info_tips: 'Subscribe for 1 year and enjoy 50% off',
    duration: 'Upgrade service time {0} to {1}',
    to_renewal: 'Renew now',
    to_updated: 'upgrade immediately',
    read_agree: 'Read and agree',
    user_agreement: '"360 ATA Cloud User Agreement"',
    user_agreement_tip: 'Please read and agree to the "360 ATA Cloud User Agreementt"',
    professional: 'Provides an analysis environment for Windows 7 64-bit operating systems, adding the ability to decrypt HTTPS traffic and detect specialized exploits, ransomware, and detect evasion behaviors.',
    enterprise: 'Provides an analysis environment for the Windows 10 32/64-bit operating system, adds features such as local parameter configuration and restart detection, and increases the ability to detect known vulnerability attacks, key vulnerability exploits, and APT attack behaviors.',
    confirm_pd_info: 'Confirm product information',
    back: 'Return to reselect',
    product_title: '360 ATA Cloud',
    product_desc_renew: 'Renew {0}',
    product_desc_upgrade: 'Upgrade from Pro plan to  Pro Plus plan',
    product_desc_free: 'Upgrade from free version to {0}',
    service_time: 'service hours',
    total_items: 'Total items',
    discount: 'discount',
    annual_discount: 'Annual discount',
    actually_paid: 'The amount actually paid',
    submit_order: 'Submit Order',
    count_down: 'The order was submitted successfully. Please complete the payment within {hour} hours {min} minutes {sec} seconds.',
    order_num: 'order number',
    amounts_payable: 'Amounts payable',
    confirm_payment: 'confirm payment',
    check_order: 'check order',
    payment_failed: 'Payment failed',
    payment_cancel: 'Order canceled',
    payment_paid: 'payment successful',
    payment_unsubscribe: 'Retreating',
    payment_timeout: 'Order has timed out',
    unsubscribed: 'Unsubscribe successfully',
    order_err: 'Order error',
    product_score: 'Points purchase {0} points',
    product_duration: '{0} months',
    left_time: '({0} hours {1} minutes {2} seconds remaining)'
  },
  subscription_manager: {
    title: 'My plan',
    account_version: 'Account version',
    start_time: 'Service opening time',
    end_time: 'Service deadline',
    upgrade: 'upgrade',
    renew: 'Renewal'
  },
  order_manager: {
    title: 'Order record',
    index: 'order number',
    create_time: 'creation time',
    service_content: 'Service Content',
    type: 'Transaction Type',
    amount: 'Amount({0})',
    status: 'trading status',
    action: 'operate',
    pay_order: 'to pay',
    cancel_order: 'cancel order',
    product_map: {
      professional: 'Pro plan Package',
      enterprise: 'Pro Plus plan Package',
      score: 'Points purchase'
    },
    type_map: {
      purchase: 'New purchase',
      renew: 'Renewal',
      upgrade: 'upgrade',
      refund: 'Refund'
    },
    status_map: {
      non_payment: 'To be paid',
      pay_timeout: 'Canceled (payment timeout)',
      paid: 'Finish',
      payment_failed: 'Cancelled',
      cancelled: 'Cancelled',
      cancel: 'Cancelled',
      unsubscribe: 'Finish',
      unsubscribed_successfully: 'Finish'
    }
  },
  team_manager: {
    owner_team: 'My team',
    create_team: 'Create a team',
    no_team_info: 'You do not have a team yet, please create a team~',
    invitation_received: 'Invitations i received',
    invitation_dialog_title: 'Please upgrade your service plan',
    invitation_dialog_content: 'The quota for joining the team is full. Please upgrade to the Pro plan or Pro Plus plan to get more quotas.',
    index: 'serial number',
    team_name: 'Team Name',
    team_owner: 'Team Admin',
    receive_time: 'Invitation time',
    accept: 'accept',
    accepted: 'accepted',
    reject: 'reject',
    rejected: 'rejected',
    expired: 'expired',
    quited: 'Exited',
    show_team_task: 'View team tasks',
    add_member: 'Add member',
    quit_team: 'Exit the team',
    edit_team: 'Editorial Team',
    dele_team: 'Delete team',
    dele_member: 'Remove member',
    re_invite: 're-invite',
    is_mine: '(I)',
    edit_remark: `Editor's Notes`,
    place_input_remark: 'Please enter remarks',
    place_input_content: 'Please enter everything',
    quit_team_info: 'After exiting the team, you will not be able to view team tasks. Are you sure you want to exit the "{0}" team?',
    dele_team_info: 'After deleting the team, team members and team task list information will be deleted. Please confirm whether to delete the team?',
    invisible_each_other: 'The admin has set members to be invisible to each other',
    admin: 'admin',
    member: 'member',
    dialog: {
      input_username_or_email: 'Enter username and email to search for invitees',
      joined: 'joined',
      invited: 'Invited',
      no_search_user: 'No relevant users found',
      member: 'member',
      visible_each_other: 'Team members are visible to each other',
      team_name: 'Team Name',
      input_team_name: 'Please enter team name',
      valid_team_name: 'Team name cannot be empty',
      valid_team_name_length: '1 to 15 characters in length',
      input_remark: 'Please enter remarks',
      create_team_info: 'The quota for creating or joining a team is full. Please upgrade to Professional Plus to get more quota.',
      added: 'Added successfully',
      invited_success: 'Invitation successful'
    }
  }
}
