<template>
    <el-dialog
      custom-class="payOrder"
      :title="$t('other.tip')"
      v-model="show"
      width="500px"
      @close='close'
    >
      <span>
        {{ $t('other.components.notPayText') }}
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="payed">{{ $t('other.components.goTo') }}</el-button>
        </span>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref, defineEmits } from 'vue'

const show = ref(true)
const emit = defineEmits(['closeDialog'])

const payed = () => {
  emit('closeDialog', true)
}

const close = () => {
  emit('closeDialog')
}
</script>

<style lang="scss" scoped>
:deep(.payOrder) {
  background: #21274a;
  .el-dialog__header  {
    text-align: left;
    background: #242B52;
    box-shadow: 0 1px 0 0 #2A315D;
    border-radius: 2px 2px 0 0;
    .el-dialog__title  {
      font-size: 14px;
      color: var( --color-unkown-text);
    }
  }
  .el-dialog__body  {
    font-size: 14px;
    color: var( --color-unkown-text);
    padding: 36px 34px 36px 34px;
    border-bottom: 1px solid #2A315D;
    text-align: center;
    .countDown  {
      font-size: 14px;
    }
  }
  .el-dialog__footer  {
    padding: 12px 20px 12px 20px;
    text-align: center;
    .el-button  {
      &:first-child  {
        background: #537EF3;
        color: var(--color-text-1);
      }
    }
  }
}
</style>
