export default {
  message: {
    upgrade: 'Upgrade',
    detail: 'View details',
    integral: 'Points redemption'
  },
  upgrade: {
    title: 'Please upgrade service plan',
    point: 'This feature requires upgrading to Pro plan or Pro Plus plan to unlock, or redeeming it with points.',
    normal: 'This feature requires upgrading to Pro plan or Pro Plus plan to unlock',
    toPrice: 'View service prices',
    action_upgrade: 'upgrade',
    action_point: 'Redeem with points',
    lockdata: '{0} more behavioral traits are available in Pro plan or Pro Plus plan'
  },
  authorization: {
    tologin: 'Please check after {0}',
    login: 'Sign in',
    unauth: 'The current status does not have permission to view, please Sign in to view.',
    toupgrade: 'This feature requires upgrading to {0} Pro Plus plan to unlock{1}',
    toupgrade_0: 'Pro plan or',
    toupgrade_1: '，Or use points to redeem.'
  },
  copy: 'copy',
  ti: 'TI',
  suffix: {
    byte: 'Bytes'
  },
  nodata: 'No data yet',
  no_result_found: 'No results found',
  dialog: {
    delete: {
      title: 'Delete',
      content: 'The task deletion operation is irreversible. All contents reported by this task will no longer be visible to anyone. Please confirm the deletion?',
      confirm: 'Delete'
    },
    detect: {
      label: 'Submit',
      redetect: 'Resubmit this task',
      url: 'Submit the visited URL for detecting',
      dropped: 'Dropped file submission detection',
      use_origin_param: 'Use original task configuration',
      origin_param_info: 'Use the original configuration to re-detect the task. Configuration items that exceed the permission range will be automatically modified to be within the allowed range. A new task report will be generated during the re-detection.',
      new_param_info: 'Retest this task using the respecified configuration, and a new task report will be generated when retesting.',
      failed: 'The processing of this project failed and the detection task cannot be submitted!',
      submitted: 'Submission completed'
    },
    cancel: 'Cancel',
    confirm: 'Confirm'
  },
  pagination: {
    part: '{0} items in total, only the first 10,000 items are displayed',
    total: 'Total {0} items'
  },
  loading: 'loading',
  action: {
    handle: 'Operation successful',
    share: 'Share success',
    cancel_share: 'Cancel sharing successfully',
    delete: 'successfully deleted',
    cancel: 'Cancel',
    confirm: 'confirm',
    clear: 'Clear',
    confirm_quit: 'OK to exit',
    quit_success: 'exit successfully',
    show_detail: 'check the details',
    upgrade: 'upgrade',
    confirm_remove: 'confirm deletion',
    edit: 'edit',
    edit_success: 'Edited successfully',
    invite_success: 'Invitation successful',
    remove: 'Delete',
    net_err: 'The operation failed due to network delay, please resubmit.',
    expand: 'Expand',
    fold: 'Close'
  },
  copied: 'Copied successfully',
  download: {
    action: 'Download',
    not_found: 'file does not exist',
    failed: 'Download failed'
  },
  success: 'success',
  failed: 'fail',
  risk: {
    low: 'Low',
    middle: 'middle',
    high: 'high',
    low_risk: 'low',
    middle_risk: 'medium',
    high_risk: 'high',
    not_detected: 'Clean',
    malice: 'Malicious',
    suspicious: 'Suspicious',
    safe: 'Safety'
  },
  label: {
    username: 'Name',
    email: 'Email Address',
    phone: 'Phone Number',
    attention: 'Attention',
    loading_data: 'loading',
    role: 'Role',
    remark: 'Note',
    action: 'operate',
    status: 'state',
    company: 'Company Name'
  },
  tip: {
    input: 'Please enter {0}{1}',
    noData: 'No data yet',
    noYet: 'None yet',
    unknow: 'unknown',
    label: '{0}：'
  }
}
