import { getTeamShareTask, getTeamSubmitter } from 'services/team'
import isEqual from '@/utils/checkEqual'

const state = {
  teamtasklist: [],
  tasktype: '',
  total: 0,
  filter: {
    score: '',
    filetype: '',
    tag: '',
    owner: ''
  },
  teamId: '',
  page: 1,
  size: 50,
  teamTaskSubmitter: []
}

const mutations = {
  SET_FILTER (state, payload) {
    state.filter[payload.type] = payload.data
  },
  SET_TASK_TYPE (state, payload) {
    state.tasktype = payload
  },
  RESET_FILTER (state) {
    state.filter = {
      score: '',
      filetype: '',
      tag: '',
      owner: ''
    }
  },
  SET_LIST (state, payload) {
    state.teamtasklist = payload
  },
  SET_TOTAL (state, payload) {
    state.total = payload
  },
  SET_PAGE (state, payload) {
    state.page = payload
  },
  SET_SIZE (state, payload) {
    state.size = payload
  },
  SET_TEAM_TASK_SUBMITTER (state, payload) {
    state.teamTaskSubmitter = payload
  },
  SET_TEAMID (state, payload) {
    state.teamId = payload
  }
}

const actions = {
  getTeamTask ({ state, commit, rootGetters }, payload) {
    const scoreList = state.filter.score.split(',')
    const score = scoreList.length === 2 ? { score_min: scoreList[0], score_max: scoreList[1] } : {}
    const filetype = state.filter.filetype ? { file_type: state.filter.filetype } : {}
    const tag = state.filter.tag ? { tag: state.filter.tag } : {}
    if (rootGetters['team/teamList'].length === 0) return
    return new Promise((resolve, reject) => {
      getTeamShareTask({
        team_id: payload,
        page: state.page,
        size: state.size,
        category: state.tasktype,
        ...filetype,
        ...score,
        ...tag,
        owner_username: state.filter.owner
      }).then(res => {
        commit('SET_LIST', res.task)
        commit('SET_TOTAL', res.total)
        resolve('getteamShareTask Success')
      })
    })
  },
  getTeamTaskSubmitter ({ state, commit }, teamId) {
    getTeamSubmitter({ team_id: teamId }).then(res => {
      commit('SET_TEAM_TASK_SUBMITTER', res)
    })
  }
}

const getters = {
  score: state => state.filter.score,
  filetype: state => state.filter.filetype,
  tasktype: state => state.tasktype,
  teamtasklist: state => state.teamtasklist,
  page: state => state.page,
  size: state => state.size,
  total: state => state.total,
  teamId: state => state.teamId,
  teamTaskSubmitter: state => state.teamTaskSubmitter,
  isFilter: state => {
    const keyMap = {
      score: '',
      filetype: '',
      tag: '',
      owner: ''
    }
    const keyMap2 = [
      'score',
      'filetype',
      'tag',
      'owner'
    ]
    return !(keyMap2.every(item => isEqual(keyMap[item], state.filter[item])))
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
