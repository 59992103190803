export default {
  message: {
    upgrade: '升级',
    detail: '查看详情',
    integral: '积分兑换'
  },
  upgrade: {
    title: '请升级服务方案',
    point: '该功能需要升级专业版或专业增强版来解锁，或使用积分兑换。',
    normal: '该功能需要升级专业版或专业增强版来解锁。',
    toPrice: '查看服务价格',
    action_upgrade: '升级',
    action_point: '用积分兑换',
    lockdata: '还有 {0} 条行为特征可在专业版或专业增强版中查看'
  },
  authorization: {
    tologin: '请{0}后查看',
    login: '登录',
    unauth: '当前状态暂无查看权限，请登录后查看',
    toupgrade: '该功能需要升级{0}专业增强版来解锁{1}',
    toupgrade_0: '专业版或',
    toupgrade_1: '，或使用积分兑换。'
  },
  copy: '复制',
  ti: 'TI 搜索',
  suffix: {
    byte: '字节'
  },
  nodata: '暂无数据',
  no_result_found: '未搜到结果',
  dialog: {
    delete: {
      title: '确认删除任务',
      content: '任务删除操作不可恢复，任何人将不再可见此任务报告的所有内容，请确认是否删除？',
      confirm: '确认删除'
    },
    detect: {
      label: '提交检测',
      redetect: '重新检测此任务',
      url: '将访问网址提交检测',
      dropped: '将释放文件提交检测',
      use_origin_param: '使用原任务配置',
      origin_param_info: '使用原有配置对任务进行重新检测，超出权限范围的配置项将自动修改为当前权限允许范围内，重新检测时将生成新的任务报告。',
      new_param_info: '使用重新指定的配置对此任务进行重新检测，重新检测时将生成新的任务报告。',
      failed: '此项目处理失败，无法提交检测任务！',
      submitted: '重新检测提交完成'
    },
    cancel: '取消',
    confirm: '确认'
  },
  pagination: {
    part: '共 {0} 条，仅呈现前 10000 条',
    total: '共 {0} 条'
  },
  loading: '数据加载中',
  action: {
    handle: '操作成功',
    share: '分享成功',
    cancel_share: '取消分享成功',
    delete: '删除成功',
    cancel: '取消',
    confirm: '确认',
    clear: '清空',
    confirm_quit: '确定退出',
    quit_success: '退出成功',
    show_detail: '查看详情',
    upgrade: '升级',
    confirm_remove: '确认删除',
    edit: '编辑',
    edit_success: '编辑成功',
    invite_success: '邀请成功',
    remove: '删除',
    net_err: '操作失败，网络延时，请重新提交',
    expand: '展开',
    fold: '收起'
  },
  copied: '复制成功',
  download: {
    action: '下载',
    not_found: '文件不存在',
    failed: '下载失败'
  },
  success: '成功',
  failed: '失败',
  risk: {
    low: '低',
    middle: '中',
    high: '高',
    low_risk: '低危',
    middle_risk: '中危',
    high_risk: '高危',
    not_detected: '未检出',
    malice: '恶意',
    suspicious: '可疑',
    safe: '无风险'
  },
  label: {
    username: '用户名',
    email: '邮箱地址',
    phone: '手机号码',
    attention: '注意',
    loading_data: '数据加载中',
    role: '角色',
    remark: '备注',
    action: '操作',
    status: '状态',
    company: '所属单位'
  },
  tip: {
    input: '请输入{0}{1}',
    noData: '暂无数据',
    noYet: '暂无',
    unknow: '未知',
    label: '{0}：'
  }
}
