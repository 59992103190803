<template>
  <div class="standard-layout">
    <div class="standard-layout__header" :class="[scrollFlag && 'navActive', route.name === 'Home' && 'nav']">
      <div class="left">
        <app-logo :mode="!scrollFlag && route.name === 'Home'" type="home"/>
        <app-menu v-show="isNotChat" />
      </div>
      <div class="right">
        <app-lang style="margin-right:35px;"/>
        <app-theme></app-theme>
        <!-- <div class="right-space"></div> -->
        <app-notice v-if="loginState" />
        <!-- <div class="right-space"></div> -->
        <app-account />
      </div>
    </div>
    <div class="standard-layout__content"><router-view /></div>
    <app-footer bg="#1B1D21" home></app-footer>
  </div>
  <app-login></app-login>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import AppLogo from 'components/app/app-logo'
import AppMenu from 'components/app/app-menu-home'
// import AppLang from 'components/app/app-lang'
import AppTheme from 'components/app/app-theme'
import AppLang from 'components/app/app-lang'
import AppNotice from 'components/app/app-notice'
import AppAccount from 'components/app/app-account'
import AppFooter from 'components/app/app-footer'
import AppLogin from 'components/app/app-login'
const route = useRoute()
const store = useStore()
const loginState = computed(() => {
  return store.getters.isLogin
})
const scrollFlag = ref(route.name !== 'Home')
const isNotChat = computed(() => {
  return route.name !== 'chat'
})

function handleScroll () {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
  if (scrollTop > 100) {
    scrollFlag.value = true
  } else {
    scrollFlag.value = false
  }
}

onMounted(() => window.addEventListener('scroll', handleScroll))
onBeforeUnmount(() => window.removeEventListener('scroll', handleScroll))
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
.standard-layout {
  width: 100%;
  overflow-x: hidden;
  background: var(--color-bg-2);
  &__header {
    width: 100%;
    height: $header-height;
    background: var(--home-header);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px 0 18px;
    box-shadow: 0 4px 5px 0 #0000000f;
    min-width: 1200px;
    overflow: hidden;
    position: fixed;
    top: 0;
    z-index: 100;
    box-sizing: border-box;
    transition: background 0.6s;
    .left,
    .right {
      display: flex;
      height: 100%;
      align-items: center;
    }
    .right-space {
      width: 20px;
    }
  }
  &__content {
    width: 100%;
    min-height: calc(100vh - $footer-height);
    // background-color: var(--color-bg-2);
    display: flex;
  }
}
.nav {
  :deep(.app-menu-home__item) {
    color: white;
  }
  :deep(.app-notice__icon) {
    color: white;
  }
  :deep(.app-account__name) {
    color: white;
  }
}
.navActive {
  background: var(--home-header-active);
  box-shadow: 0 4px 5px 0 #0000000f;
  :deep(.app-menu-home__item) {
    color: var(--color-text-1);
  }
  :deep(.app-notice__icon) {
    color: var(--color-text-1);
  }
  :deep(.app-account__name) {
    color: var(--color-text-1);
  }
}
</style>
