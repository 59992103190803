import SvgIcon from './svg-icon'
import NoData from './common/nodata.vue'
import BackToTop from './common/backTotop.vue'
import CopyIcon from './common/copyIcon.vue'
import Widgest from './common/widgest.vue'
import SearchInput from './common/searchInput.vue'
import Loading from './common/Loading.vue'
import Authorization from './common/Authorization.vue'
import DetailFooter from './report/footer.vue'

export default (app) => {
  app.component('svg-icon', SvgIcon)
  app.component('no-data', NoData)
  app.component('backTo-top', BackToTop)
  app.component('copy-icon', CopyIcon)
  app.component('Widgest', Widgest)
  app.component('SearchInput', SearchInput)
  app.component('Loading', Loading)
  app.component('Authorization', Authorization)
  app.component('detail-footer', DetailFooter)
}
