export default {
  chatgpt: {
    info: {
      title: '360 ATA Cloud Assistant',
      desc: '360 ATA Cloud Assistant reshapes the interaction method based on the sandbox cloud report viewing scenario to achieve intelligent report interpretation, help users understand the key content in the report, and improve the efficiency of user security operations.',
      footerText: 'In the future, we will continue to explore more scenarios and help users solve more problems, so stay tuned.',
      step1: {
        title: '1. Generate report summary',
        desc: 'For the report content, generate a coherent, logical, and easy-to-understand report summary, focusing on the most critical content in the report to improve the efficiency of understanding the report.',
        line1: '"Summary of this analysis report"',
        line2: '"Reported Overview Information"',
        line3: '"Combined with the report overview information, is this file malicious?"'
      },
      step2: {
        title: '2. Interpret the report content fragments',
        desc: 'For the specific content of the report, we can not only provide text interpretation, but also present it in the form of pictures.',
        line1: '"What processes are there? Draw the process tree in the form of a string graph"',
        line2: '"Those processes named cmd.exe, what is their score"',
        line3: '"Progress diagram of 1633, shown in mermaid"'
      },
      step3: {
        title: '3. Answer professional terminology in reports',
        desc: 'For the content or description in the report, based on the threat encyclopedia knowledge base, answer labels, rules, fields and other information.',
        line1: '“What does self-starting mean”',
        line2: '"What does it mean when a target file is identified as a dangerous file by an anti-virus engine?"',
        line3: '"What does it mean to modify some INTERNET SETTING settings in the registry"'
      },
      step4: {
        title: '4. Generate disposal recommendations',
        desc: 'Based on the reported detection results, easy-to-understand disposal suggestions can be generated to realize the entire process of threat detection and disposal suggestions.',
        line1: '"What should I do with the samples in the report"',
        line2: '"This sample modified my registry, what should I do"',
        line3: '"This sample deleted my file directory, what should I do"'
      }
    },
    chat: {
      title: '360 ATA Cloud Assistant',
      desc: 'Introduction',
      descTip: 'Introduction to 360 ATA Cloud Assistant',
      deleteTitle: 'Delete historical conversations',
      stop: 'Stop generating',
      copy: 'copy',
      placeholder: 'What kind of help do you need...',
      reanswer: 'Reply',
      dialog: {
        title: 'Please confirm whether to clear the record',
        content: 'You are clearing the historical analysis records. Once cleared, it cannot be restored. Please confirm whether you still want to clear it?'
      },
      message: {
        tip1: 'Is this file malicious? Why?',
        tip2: 'What malicious behavior is present in this report?',
        tip3: 'What processes are running this file?',
        tip4: 'What ATT&CK technical points are present in this report?',
        tip5: 'What network communication behaviors does this file have?',
        deleteWait: 'Please wait for a reply to the previous message before deleting the historical conversation.',
        stoped: 'Stopped answering',
        wait: 'Please wait for a reply to the previous message before sending a new message.',
        placeInput: 'Please enter the content before asking the question',
        copySuccessful: 'Copied successfully',
        tip: `I'm a 360 ATA Cloud Assistant and I'm happy to serve you! You can ask me questions or instructions about Sandbox Cloud and I will answer or execute your instructions for you.`,
        placeWait: 'Thinking, please wait',
        serverError: 'Service abnormality, please try again later.',
        runOut: `Today's frequency has been used up, please come back tomorrow`,
        tooHigh: 'The current sending frequency is too high, please send later',
        reTry: 'The service is out of service, please try again later.',
        notUnderstand: `Sorry, I'm not smart enough to understand what you mean.`
      }
    }
  }
}
