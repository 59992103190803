<template>
  <!-- <el-button @click="onThemeChange">{{ $t('app.switchTheme') }}</el-button> -->
  <label class="switch">
    <input :checked="store.getters.theme !== 'dark'" type="checkbox" :onChange="onThemeChange"/>
    <span class="slider"></span>
  </label>
  <!-- <img class="beta" :src="require('@/assets/img/svg/beta.svg')" alt=""> -->
</template>

<script setup>
import { useStore } from 'vuex'

const store = useStore()

const onThemeChange = () => {
  const theme = store.getters.theme === 'dark' ? 'light' : 'dark'
  store.commit('theme/setTheme', theme)
  window.document.documentElement.setAttribute('data-theme', theme)
}
</script>

<style lang="scss" scoped>
.beta {
  position: relative;
    top: -13px;
    left: -4px;
    scale: 1.3;
}
/* The switch - the box around the slider */
.switch {
  font-size: 13px;
  position: relative;
  display: inline-block;
  width: 3.5em;
  height: 2em;
  top: 2px;
  right: 16px;
  // margin-right: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  height: 22px;
  // --background: #28096b;
  --background: #2c2c2c;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background);
  transition: 0.5s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 1.4em;
  width: 1.4em;
  border-radius: 50%;
  left: 10%;
  bottom: 10%;
  box-shadow: inset 8px -4px 0px 0px var(--color-priamry);
  // box-shadow: inset 8px -4px 0px 0px var(--color-priamry);
  background: var(--background);
  transition: 0.5s;
}

input:checked + .slider {
  // background-color: #522ba7;
  background-color: #f2f2f2;
}

input:checked + .slider:before {
  transform: translateX(100%);
  box-shadow: inset 15px -4px 0px 15px var(--color-priamry);
  // box-shadow: inset 15px -4px 0px 15px #fff000;
}
</style>
