import { createRouter, createWebHistory } from 'vue-router'
import Store from '../store'

import homeLayout from '../layout/home-layout'
import standardLayout from '../layout/standard-layout'
import helpLayout from '../layout/help-layout'
// import huntLayout from '../layout/hunt-layout'
import noticeLayout from '../layout/notice-layout'
import userCenterLayout from '../layout/user-center-layout'
import Home from '../views/home/home'
import { t } from 'app/i18n'
// import { TOKEN } from '@/constants'
// import { getItem } from 'utils'

/**
 * 私有路由表
 */
export const privateRoutes = []

/**
 * 公开路由表
 */
export const publicRoutes = [
  {
    path: '/home',
    redirect: '/',
    component: homeLayout,
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
          // title: '360沙箱云 - 专业的高级威胁分析平台，洞悉恶意样本每一行为'
          title: t('other.router.homeTitle')
        }
      },
      {
        path: '/intro',
        name: 'Intro',
        component: () => import(/* webpackChunkName: "intro" */ '../views/home/intro'),
        meta: {
          title: t('app.intro.title')
        }
      },
      {
        path: '/tracked',
        name: 'tracked',
        meta: {
          // title: '360沙箱云 - 威胁跟踪'
          title: t('other.router.trackedTitle')
        },
        component: () => import(/* webpackChunkName: "tracked" */ '../views/home/tracked')
      },
      {
        path: '/chat',
        name: 'chat',
        meta: {
          // title: '沙箱云助手简介 - 360沙箱云'
          title: t('other.router.chatTitle')
        },
        component: () =>
          import(/* webpackChunkName: "chat" */ '../views/home/chat')
      }
    ]
  },
  {
    path: '/standard',
    redirect: '/dashboard',
    component: standardLayout,
    children: [
      {
        path: '/detect',
        name: 'Detection',
        component: () => import(/* webpackChunkName: "detection" */ '../views/detection'),
        meta: {
          // title: '提交分析 - 360沙箱云'
          title: t('other.router.detectTitle')
        }
      },
      {
        path: '/report/:taskId/:type?',
        name: 'report',
        meta: {
          title: ''
        },
        component: () => import(/* webpackChunkName: "report" */ '../views/report')
      },
      {
        path: '/price',
        name: 'price',
        meta: {
          // title: '升级服务 - 360沙箱云'
          title: t('other.router.priceTitle')
        },
        component: () => import(/* webpackChunkName: "intro" */ '../views/price')
      },
      {
        path: '/taskstatus',
        name: 'taskstatus',
        component: () => import(/* webpackChunkName: "report" */ '../views/report/task-status'),
        meta: {
          // title: '任务列表 - 360沙箱云'
          title: t('other.router.taskListTitle')
        }
      }
    ]
  },
  {
    path: '/refresh',
    component: import('../views/report/components/refresh'),
    name: 'refresh'
  },
  {
    path: '/license',
    component: () => import(/* webpackChunkName: "license" */ '../views/home/license'),
    name: 'license'
  },
  {
    path: '/app-auth',
    component: () => import(/* webpackChunkName: "license" */ '../views/other/app-auth'),
    name: 'app-auth'
  },
  {
    path: '/taskhub/no-file-hash-submission',
    name: 'err-hash',
    component: () =>
      import(/* webpackChunkName: "hash" */ '../views/taskhub/history/errHash'),
    meta: {
      // title: '异常哈希提交记录 - 360沙箱云'
      title: t('other.router.errHashTitle')
    }
  },
  {
    path: '/taskhub',
    redirect: () => { return `/taskhub/${Store.getters['user/menuDefault']}` },
    meta: {
      // title: '任务中心 - 360沙箱云'
      title: t('other.router.taskhubTitle')
    },
    component: standardLayout,
    children: [
      {
        path: '/taskhub/public',
        name: 'public',
        component: () =>
          import(/* webpackChunkName: "puplic" */ '../views/taskhub/selected'),
        meta: {
          // title: '公开任务 - 360沙箱云'
          title: t('other.router.publicTaskTitle')
        }
      },
      {
        path: '/taskhub/history',
        name: 'history',
        meta: {
          // title: '我的任务 - 360沙箱云'
          title: t('other.router.myTaskTitle')
        },
        component: () =>
          import(/* webpackChunkName: "history" */ '../views/taskhub/history')
      },
      {
        path: '/taskhub/teamtask',
        name: 'teamtask',
        meta: {
          // title: '团队任务 - 360沙箱云'
          title: t('other.router.teamTaskTitle')
        },
        component: () =>
          import(/* webpackChunkName: "teamtask" */ '../views/taskhub/team-task')
      },
      {
        path: '/taskhub/global',
        name: 'global',
        meta: {
          title: '全站任务 - 360沙箱云'
        },
        component: () =>
          import(/* webpackChunkName: "teamtask" */ '../views/taskhub/global')
      },
      {
        path: '/taskhub/advancedsearch',
        name: 'advanced',
        component: () =>
          import(/* webpackChunkName: "advancedsearch" */ '../views/taskhub/advanced-search'),
        meta: {
          // title: '高级搜索 - 360沙箱云'
          title: t('other.router.advancedSearchTitle')
        }
      },
      {
        path: '/taskhub/search',
        name: 'search',
        component: () =>
          import(/* webpackChunkName: "search" */ '../views/taskhub/search'),
        meta: {
          title: ''
        }
      },
      {
        path: '/taskhub/statistics',
        meta: {
          // title: '我的统计 - 360沙箱云'
          title: t('other.router.statisticsTitle')
        },
        name: 'statistics',
        component: () =>
          import(/* webpackChunkName: "statistics" */ '../views/taskhub/statistics')
      }
    ]
  },
  {
    path: '/help',
    redirect: '/help/docs',
    component: helpLayout,
    children: [
      {
        path: '/help/docs',
        name: 'docs',
        redirect: '/help/docs/introduction',
        component: () =>
          import(/* webpackChunkName: "docs" */ '../views/help/docs'),
        children: [
          {
            path: '/help/docs/introduction',
            name: '/help/docs/introduction',
            meta: {
              // title: '平台简介 - 360沙箱云'
              title: t('other.router.introductionTitle')
            },
            component: () =>
              import(/* webpackChunkName: "introduction" */ '../views/help/docs/pages/info')
          },
          {
            path: '/help/docs/terms',
            name: '/help/docs/terms',
            meta: {
              // title: '用户协议 - 360沙箱云'
              title: t('other.router.termsTitle')
            },
            component: () =>
              import(/* webpackChunkName: "terms" */ '../views/help/docs/pages/protocol')
          },
          {
            path: '/help/docs/privacy',
            name: '/help/docs/privacy',
            meta: {
              // title: '隐私政策 - 360沙箱云'
              title: t('other.router.privacyTitle')
            },
            component: () =>
              import(/* webpackChunkName: "privacy" */ '../views/help/docs/pages/privacy')
          },
          {
            path: '/help/docs/faq',
            meta: {
              // title: '常见问题 - 360沙箱云'
              title: t('other.router.faqTitle')
            },
            name: '/help/docs/faq',
            component: () =>
              import(/* webpackChunkName: "faq" */ '../views/help/docs/pages/question')
          },
          {
            path: '/help/docs/api/introduction',
            meta: {
              // title: '接口简介 - 360沙箱云'
              title: t('other.router.apiIntroductionTitle')
            },
            name: '/help/docs/api/introduction',
            component: () =>
              import(/* webpackChunkName: "explain" */ '../views/help/docs/pages/api-info')
          },
          {
            path: '/help/docs/api/userlimit',
            meta: {
              // title: '用户须知 - 360沙箱云'
              title: t('other.router.userLimitTitle')
            },
            name: '/help/docs/api/userlimit',
            component: () =>
              import(/* webpackChunkName: "uselimit" */ '../views/help/docs/pages/api-use')
          },
          {
            path: '/help/docs/api/list/submittask',
            meta: {
              // title: '提交检测分析任务 - 360沙箱云'
              title: t('other.router.submitTaskTitle')
            },
            name: '/help/docs/api/list/submittask',
            component: () =>
              import(/* webpackChunkName: "submitfile" */ '../views/help/docs/pages/api-submit-task')
          },
          {
            path: '/help/docs/api/list/searchtask',
            meta: {
              // title: '搜索历史任务报告 - 360沙箱云'
              title: t('other.router.searchTaskTitle')
            },
            name: '/help/docs/api/list/searchtask',
            component: () =>
              import(/* webpackChunkName: "searchhistory" */ '../views/help/docs/pages/api-search-history')
          },
          {
            path: '/help/docs/api/list/getsubmitlist',
            meta: {
              // title: '查询历史任务列表 - 360沙箱云'
              title: t('other.router.getsubmitlistTitle')
            },
            name: '/help/docs/api/list/getsubmitlist',
            component: () =>
              import(/* webpackChunkName: "getsubmitlist" */ '../views/help/docs/pages/api-submit-list')
          },
          {
            path: '/help/docs/api/list/gettaskstatus',
            meta: {
              // title: '查询任务分析状态 - 360沙箱云'
              title: t('other.router.gettaskstatusTitle')
            },
            name: '/help/docs/api/list/gettaskstatus',
            component: () =>
              import(/* webpackChunkName: "searchtaskstatus" */ '../views/help/docs/pages/api-search-task')
          },
          {
            path: '/help/docs/api/list/gettaskoverview',
            meta: {
              // title: '查看任务报告内容 - 360沙箱云'
              title: t('other.router.gettaskoverviewTitle')
            },
            name: '/help/docs/api/list/gettaskoverview',
            component: () =>
              import(/* webpackChunkName: "searchtaskstatus" */ '../views/help/docs/pages/api-task-overview')
          }
        ]
      },
      {
        path: '/help/wiki',
        name: 'wiki',
        meta: {
          // title: '威胁百科 - 360沙箱云'
          title: t('other.router.wikiTitle')
        },
        component: () =>
          import(/* webpackChunkName: "wiki" */ '../views/help/wiki')
      },
      {
        path: '/help/consulting',
        name: 'consulting',
        meta: {
          // title: '购买咨询 - 360沙箱云'
          title: t('other.router.consultingTitle')
        },
        component: () =>
          import(/* webpackChunkName: "consulting" */ '../views/help/consulting')
      },
      {
        path: '/help/feedback',
        name: 'feedback',
        meta: {
          // title: '提交反馈 - 360沙箱云'
          title: t('other.router.feedbackTitle')
        },
        component: () =>
          import(/* webpackChunkName: "feedback" */ '../views/help/feedback')
      }
    ]
  },
  // {
  //   path: '/hunting',
  //   redirect: '/hunting',
  //   component: huntLayout,
  //   children: [
  //     {
  //       path: '/hunting',
  //       name: 'hunting',
  //       meta: {
  //         title: '狩猎中心 - 360沙箱云'
  //       },
  //       component: () =>
  //         import(/* webpackChunkName: "wiki" */ '../views/hunting/index')
  //     },
  //     {
  //       path: '/hunting/static',
  //       name: 'hunting-static',
  //       meta: {
  //         title: '静态狩猎 - 360沙箱云'
  //       },
  //       component: () =>
  //         import(/* webpackChunkName: "consulting" */ '../views/hunting/static')
  //     },
  //     {
  //       path: '/hunting/static-add',
  //       name: 'hunting-static-add',
  //       meta: {
  //         title: '新增静态狩猎 - 360沙箱云'
  //       },
  //       component: () =>
  //         import(/* webpackChunkName: "consulting" */ '../views/hunting/static/add-rule')
  //     },
  //     {
  //       path: '/hunting/threat',
  //       name: 'hunting-threat',
  //       meta: {
  //         title: '威胁狩猎 - 360沙箱云'
  //       },
  //       component: () =>
  //         import(/* webpackChunkName: "feedback" */ '../views/hunting/hunt')
  //     },
  //     {
  //       path: '/hunting/threat-add',
  //       name: 'hunting-threat-add',
  //       meta: {
  //         title: '新增威胁狩猎 - 360沙箱云'
  //       },
  //       component: () =>
  //         import(/* webpackChunkName: "feedback" */ '../views/hunting/hunt/add-rule')
  //     },
  //     {
  //       path: '/hunting/operation',
  //       name: 'hunting-operation',
  //       meta: {
  //         title: '样本运营 - 360沙箱云'
  //       },
  //       component: () =>
  //         import(/* webpackChunkName: "feedback" */ '../views/hunting/operation')
  //     }
  //   ]
  // },
  {
    path: '/notice',
    redirect: '/notice/message',
    component: noticeLayout,
    children: [
      {
        path: '/notice/message',
        name: 'message',
        meta: {
          auth: true,
          // title: '我的消息 - 360沙箱云'
          title: t('other.router.myMessageTitle')
        },
        component: () =>
          import(/* webpackChunkName: "message" */ '../views/notice/notice-message')
      },
      {
        path: '/notice/settings',
        name: 'setting',
        meta: {
          auth: true,
          // title: '消息设置 - 360沙箱云'
          title: t('other.router.messageSettingTitle')
        },
        component: () =>
          import(/* webpackChunkName: "setting" */ '../views/notice/notice-setting')
      }
    ]
  },
  {
    path: '/usercenter',
    redirect: '/usercenter/message',
    component: userCenterLayout,
    children: [
      {
        path: '/usercenter/account',
        name: 'account',
        meta: {
          auth: true,
          // title: '账户信息 - 360沙箱云'
          title: t('other.router.accountTitle')
        },
        component: () =>
          import(/* webpackChunkName: "account" */ '../views/user-center/account')
      },
      {
        path: '/usercenter/point',
        name: 'point',
        meta: {
          auth: true,
          // title: '我的积分 - 360沙箱云'
          title: t('other.router.pointTitle')
        },
        component: () =>
          import(/* webpackChunkName: "point" */ '../views/user-center/point')
      },
      {
        path: '/usercenter/subscription',
        name: 'subscription',
        meta: {
          auth: true,
          // title: '我的订阅 - 360沙箱云'
          title: t('other.router.subscriptionTitle')
        },
        component: () =>
          import(/* webpackChunkName: "subscription" */ '../views/user-center/subscription')
      },
      {
        path: '/usercenter/subscribe',
        name: 'subscribe',
        meta: {
          auth: true,
          title: t('app.intro.title')
        },
        component: () =>
          import(/* webpackChunkName: "subscribe" */ '../views/user-center/subscribe')
      },
      {
        path: '/usercenter/confim-subscribe',
        name: 'confimSubscribe',
        meta: {
          auth: true,
          title: t('app.intro.title')
        },
        component: () =>
          import(/* webpackChunkName: "confim-subscribe" */ '../views/user-center/subscribe/confim-subscribe')
      },
      {
        path: '/usercenter/confim-order',
        name: 'confimOrder',
        meta: {
          auth: true,
          // title: '确认订单 - 360沙箱云'
          title: t('other.router.confimOrderTitle')
        },
        component: () =>
          import(/* webpackChunkName: "confim-order" */ '../views/user-center/subscribe/confim-order')
      },
      {
        path: '/usercenter/order',
        name: 'order',
        meta: {
          auth: true,
          // title: '订单记录 - 360沙箱云'
          title: t('other.router.orderTitle')
        },
        component: () =>
          import(/* webpackChunkName: "order" */ '../views/user-center/order')
      },
      {
        path: '/usercenter/inviteteam',
        name: 'inviteteam',
        meta: {
          auth: true,
          // title: '我收到的邀请 - 360沙箱云'
          title: t('other.router.inviteteamTitle')
        },
        component: () =>
          import(/* webpackChunkName: "inviteteam" */ '../views/user-center/team/invite-team')
      },
      {
        path: '/usercenter/ownerteam',
        name: 'ownerteam',
        meta: {
          auth: true,
          // title: '我的团队 - 360沙箱云'
          title: t('other.router.ownerteamTitle')
        },
        component: () =>
          import(/* webpackChunkName: "ownerteam" */ '../views/user-center/team/owner-team')
      }
    ]
  },
  {
    path: '/wndoreport/:taskId/:type?',
    name: 'wndoreport',
    meta: {
      title: t('app.intro.title')
    },
    component: () => import(/* webpackChunkName: "report" */ '../views/report-pdf')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/home'
  }
]

/**
 * 初始化路由表
 */
export function resetRouter() {
  if (
    Store.getters.userInfo &&
    Store.getters.userInfo.permission &&
    Store.getters.userInfo.permission.menus
  ) {
    // const menus = store.getters.userInfo.permission.menus
    // menus.forEach((menu) => {
    //   router.removeRoute(menu)
    // })
  }
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: publicRoutes
})

router.beforeEach((to, from, next) => {
  if ((to.matched.length === 0 && to.fullPath === '/report') || to.fullPath === '/report/') {
    next({ path: '/' })
  }
  if (to.name === 'search' && !Object.keys(to.query).length) { next({ path: '/' }) }
  if (to.name === 'isc2021') {
    const docEl = document.documentElement
    const recalc = function () {
      const clientWidth = docEl.clientWidth
      if (!clientWidth) return
      docEl.style.fontSize = 100 * (clientWidth / 1920) + 'px'
    }
    recalc()
  } else {
    const docEl = document.documentElement
    const recalc = function () {
      const clientWidth = docEl.clientWidth
      if (!clientWidth) return
      docEl.style.fontSize = '16px'
    }
    recalc()
  }
  if (to.meta.title) {
    if (to.name === 'search') {
      document.title = to.query.querystr + to.meta.title
    } else if (to.name === 'report_running' || to.name === 'report') {
      // document.title = to.params.taskId + to.meta.title
    } else {
      document.title = to.meta.title
    }
  } else {
    document.title = t('app.intro.title')
  }
  window.scrollTo(0, 0)
  // if (to.meta && to.meta.auth) {
  //   const token = getItem(TOKEN)
  //   if (!token) {
  //     next({ name: 'dashboard', query: { redirect: to.fullPath } })
  //   } else {
  //     next()
  //   }
  // } else {
  //   next()
  // }
  if (from.query && from.query.lang === 'en' && Object.keys(to.query).length === 0) {
    const nextDestination = {
      ...to,
      query: from.query
    }
    next(nextDestination)
  }
  next()
})

router.afterEach((to, from) => {
  if (from.name === 'report_running' && to.name === 'report') {
    location.reload()
  }
})

export default router
