import cloneDeep from 'lodash/cloneDeep'

const isEqual = (v1, v2) => {
  if (getType(v1) !== getType(v2)) return false
  if (getType(v1) === 'string' && v1 === v2) return true
  if (getType(v1) === 'array' && arrayIsEqual(v1, v2)) return true
}

const arrayIsEqual = (arr1, arr2) => {
  const temp1 = Array.from(cloneDeep(arr1))
  const temp2 = Array.from(cloneDeep(arr2))
  if (temp1.length === 0 && temp2.length === 0) return true
  if (temp1.length !== temp2.length) return false
  let isEqual = true
  for (const [key, value] of Object.entries(temp1)) {
    if (value !== temp2[key]) {
      isEqual = false
      break
    }
  }
  return isEqual
}

const getType = (p1) => {
  return Object.prototype.toString.call(p1).slice(8, -1).toLowerCase()
}

export default isEqual
