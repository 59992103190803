import { mapActions, mapGetters, mapState, createNamespacedHelpers, useStore } from 'vuex'

import { computed } from 'vue'

/**
 *
 * @param {*} moduleName 模块名称
 * @param {*} mapper 方法名集合 ['fn1', 'fn2']
 * @returns
 */
export function useActions(moduleName, mapper) {
  let mapperFn = mapActions

  if (Object.prototype.toString.call(moduleName) === '[object String]' && moduleName.length > 0) {
    mapperFn = createNamespacedHelpers(moduleName).mapActions
  }

  return useActionMapper(mapper, mapperFn)
}

function useStateMapper(mapper, mapFn) {
  const store = useStore()

  const storeStateFns = mapFn(mapper)

  const storeState = {}
  Object.keys(storeStateFns).forEach(fnKey => {
    const fn = storeStateFns[fnKey].bind({ $store: store })
    storeState[fnKey] = computed(fn)
  })

  return storeState
}

function useActionMapper(mapper, mapFn) {
  const store = useStore()

  const storeActionsFns = mapFn(mapper)

  const storeAction = {}

  Object.keys(storeActionsFns).forEach(fnKey => {
    storeAction[fnKey] = storeActionsFns[fnKey].bind({ $store: store })
  })

  return storeAction
}

/**
 *
 * @param {*} moduleName 模块名称
 * @param {*} mapper getters属性集合 ['name', 'age']
 * @returns
 */
export function useGetters(moduleName, mapper) {
  let mapperFn = mapGetters

  if (Object.prototype.toString.call(moduleName) === '[object String]' && moduleName.length > 0) {
    mapperFn = createNamespacedHelpers(moduleName).mapGetters
  }

  return useStateMapper(mapper, mapperFn)
}

/**
 *
 * @param {*} moduleName 模块名称
 * @param {*} mapper state属性集合 ['name', 'age']
 * @returns
 */
export function useState(moduleName, mapper) {
  let mapperFn = mapState

  if (Object.prototype.toString.call(moduleName) === '[object String]' && moduleName.length > 0) {
    mapperFn = createNamespacedHelpers(moduleName).mapState
  }

  return useStateMapper(mapper, mapperFn)
}
