import { isZh } from 'app/i18n'

function addComma (num) {
  if (num.toLocaleString) {
    return num.toLocaleString()
  }
  return num
}

function formatNumberByUnit (num) {
  if (isZh()) {
    if (num >= 100000000) {
      return (Math.round(num / 1000000) / 100).toFixed(2) + '亿'
    }
    if (num >= 10000000) {
      return (Math.round(num / 100000) / 100).toFixed(2) + '千万'
    }
    if (num >= 10000) {
      return (Math.round(num / 100) / 100).toFixed(2) + '万'
    }
    return addComma(num)
  }
  if (num >= 1000000000) {
    return Math.round(num / 1000000000).toFixed(2) + 'B'
  }
  if (num >= 1000000) {
    return Math.round(num / 1000000).toFixed(2) + 'M'
  }
  if (num >= 1000) {
    return Math.round(num / 1000).toFixed(2) + 'K'
  }
  return addComma(num)
}

function numFormat(num, digits) {
  var si = [
    { value: 1, symbol: '' },
    { value: 1E3, symbol: 'k' }
    // { value: 1E4, symbol: 'W' }
  ]
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  var i
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break
    }
  }
  return [(num / si[i].value).toFixed(digits).replace(rx, '$1'), si[i].symbol]
}

function getPercentValue(arrList, index, precision) {
  // arrList要计算数据的数组
  // index要计算数组中值的下表
  // precision百分比保留几位小数，默认保留2位小数
  // 判断是否为空
  if (!arrList[index]) {
    return 0
  }
  if (!precision) precision = 2
  // 求和
  const sum = arrList.reduce(function(acc, val) {
    return acc + (isNaN(val) ? 0 : val)
  }, 0)
  if (sum === 0) {
    return 0
  }
  // 10的2次幂是100，用于计算精度。
  const digits = Math.pow(10, precision)
  // 扩大比例100，
  const votesPerQuota = arrList.map(function(val) {
    return (isNaN(val) ? 0 : val) / sum * digits * 100
  })
  // 总数，扩大比例意味的总数要扩大
  const targetSeats = digits * 100
  // 再向下取值，组成数组
  const seats = votesPerQuota.map(function(votes) {
    return Math.floor(votes)
  })
  // 再新计算合计，用于判断与总数量是否相同，相同则占比会100%
  let currentSum = seats.reduce(function(acc, val) {
    return acc + val
  }, 0)
  // 余数部分的数组：原先数组减去向下取值的数组，得到余数部分的数组
  const remainder = votesPerQuota.map(function(votes, index) {
    return votes - seats[index]
  })
  // 给最大最大的余额加1，凑个占比100%；
  while (currentSum < targetSeats) {
    //  找到下一个最大的余额，给其加1
    let max = Number.NEGATIVE_INFINITY
    let maxId = null
    for (let i = 0, len = remainder.length; i < len; ++i) {
      if (remainder[i] > max) {
        max = remainder[i]
        maxId = i
      }
    }
    // 对最大项余额加1
    ++seats[maxId]
    // 已经增加最大余数加1，则下次判断就可以不需要再判断这个余额数。
    remainder[maxId] = 0
    // 总的也要加1，为了判断是否总数是否相同，跳出循环。
    ++currentSum
  }
  // 这时候的seats就会总数占比会100%
  return seats[index] / digits
}

export { formatNumberByUnit, addComma, numFormat, getPercentValue }
