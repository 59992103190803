<template>
  <div class="wrapper">
    <p>{{$t('app.sidebar.setting.label')}}</p>
    <div class="header">
      <span>{{$t('app.sidebar.setting.page')}}</span>
      <span>{{$t('app.sidebar.setting.show')}}</span>
      <span>{{$t('app.sidebar.setting.default')}}</span>
    </div>
    <div class="content">
      <div class="item">
        <span v-for="(item, index) in menusList" :key="index">{{$t(`app.sidebar.${item.name}`)}}</span>
      </div>
      <div class="show">
        <el-checkbox  v-for="(item, index) in menusList" :key="index" :disabled="item.disabled" v-model="item.show" size="large" />
      </div>
      <div class="default">
        <el-radio-group v-model="defaultRoute">
          <el-radio size="large" :label="item.name" v-for="(item, index) in menusList" :key="index">{{''}}</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="btns">
      <el-button text @click="cancel">{{$t('dialog.cancel')}}</el-button>
      <el-button type="primary" @click="submit"
          >{{$t('dialog.confirm')}}</el-button
      >
    </div>
  </div>
</template>

<script setup>
import { setMenus } from 'services/report'
import { useStore } from 'vuex'
import { useGetters } from '@/hooks/useVuex'
import { computed, defineEmits, defineProps, toRefs } from 'vue'
import cloneDeep from 'lodash/cloneDeep'

const emit = defineEmits(['close'])

const props = defineProps({
  menusList: {
    type: Array
  }
})

const store = useStore()

const { menus } = useGetters('user', ['menus'])
const { menusList } = toRefs(props)

const defaultRoute = computed({
  get: () => menusList.value.find(v => v.is_default).name,
  set: (val) => {
    menusList.value.map(v => { v.is_default = false })
    menusList.value.find(v => v.name === val).is_default = true
  }
})

function cancel() {
  store.commit('user/setMenus', cloneDeep(window.taskhubMenus))
  emit('close')
}

function submit() {
  const currentMenus = cloneDeep(menusList.value)
  if (menus.value.find(v => v.name === 'global')) {
    currentMenus.push(menus.value.find(v => v.name === 'global'))
  }
  setMenus({
    menus: currentMenus
  }).then(res => {
    store.dispatch('user/getTaskhubMenus')
  })
  emit('close')
}
</script>
<style lang="scss" scoped>
.wrapper {
  box-sizing: border-box;
  padding: 16px;
  width: 280px;
  height: 334px;
  background: var(--detect-config-bg);
  box-shadow: var(--shadow);
  font-size: 14px;
  border-radius: 2px;
  position: relative;
  p {
    margin: 0 0 20px;
    color: var(--color-text-2);
    line-height: 22px;
  }
  .header {
    display: flex;
    color: var(--color-text-2);
    font-weight: 400;
    span {
      display: flex;
      justify-content: center;
      &:nth-child(1) {
        justify-content: flex-start;
        flex: 2;
      }
      &:nth-child(2) {
        flex: 1;
      }
      &:nth-child(3) {
        flex: 1;
      }
    }
  }
  .content {
    margin-top: 12px;
    display: flex;
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      &:nth-child(1) {
        flex: 2;
        align-items: flex-start;
      }
      &:nth-child(2) {
        align-items: center;
        flex: 1;
        label {
          margin: 0;
          height: 35px;
        }
      }
      &:nth-child(3) {
        flex: 1;
        label {
          height: 35px;
          width: 60px;
          margin: 0;
          display: flex;
          justify-content: center;
        }
      }
    }
    .item span {
      height: 35px;
      line-height: 35px;
    }
  }
  .btns {
    position: absolute;
    bottom: 24px;
    right: 24px;
    button{
      &:first-child {
        border: none;
      }
    }
  }
}
</style>
