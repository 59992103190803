import { getItem, setItem } from '@/utils/storage'
import { THEME } from '@/constants'

// const isDarkTheme = window.matchMedia('(prefers-color-scheme: dark)')

export default {
  namespaced: true,
  state: () => ({
    value: getItem(THEME) || 'light'
    // value: getItem(THEME) || (isDarkTheme.matches ? 'dark' : 'light')
  }),
  mutations: {
    /**
     * 设置主题
     */
    setTheme(state, theme) {
      state.value = theme
      setItem(THEME, theme)
    }
  }
}
