import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from 'element-plus/lib/locale/lang/zh-cn'
// import * as Icons from '@element-plus/icons-vue'

// function transElIconName(iconName) {
//   return (
//     'el' + iconName.replace(/[A-Z]/g, (match) => '-' + match.toLowerCase())
//   )
// }

export default (app) => {
  app.use(ElementPlus, { locale })
  // for (const key in Icons) {
  //   app.component(key, Icons[key])
  // }
}
