<template>
  <div :class="`auth-container__${authModule}`">
    <template v-if="gid === 0">
      <div v-if="!fullScreen" class="auth-wrap">
        <!-- 请
        <span class="auth-wrap-link login" @click="handleClick('login')">登录</span>
        后查看 -->
        <i18n-t keypath="authorization.tologin" tag="p">
          <span class="auth-wrap-link login" @click="handleClick('login')">{{$t('authorization.login')}}</span>
        </i18n-t>
      </div>
      <div class="auth-wrap-fullScreen" v-else>
        <img :src="$img('report/unAuthorization.png')" alt="" />
        <p>{{$t('authorization.unauth')}}</p>
        <el-button class="login" type="primary" @click="handleClick('login')">{{$t('authorization.login')}}</el-button>
      </div>
    </template>
    <!-- 威胁指标关联分析不能积分兑换 只能升级 -->
    <template v-else-if="gid < needGid && !(hasPayment && showPoint)">
      <div :class="[fullScreen ? 'auth-wrap-fullScreen' : 'auth-wrap']">
        <img v-if="fullScreen" :src="$img('report/unAuthorization.png')" alt="" />
        <p>{{$t('authorization.toupgrade', [needGid === 2 ? $t('authorization.toupgrade_0') : '', showPoint ? $t('authorization.toupgrade_1') : ''])}}</p>
        <div class="operation" v-if="!fullScreen">
          <span class="auth-wrap-link" @click="handleClick('upgrade')">{{$t('message.upgrade')}}</span>
          <span v-if="showPoint" class="auth-wrap-link" @click="handleClick('integral')">{{$t('message.integral')}}</span>
          <span class="auth-wrap-link" @click="handleClick('detail')">{{$t('message.detail')}}</span>
        </div>
        <div class="operation" v-if="fullScreen">
          <el-button class="update" type="primary" @click="handleClick('upgrade')">{{$t('message.upgrade')}}</el-button>
          <el-button class="detail" plain @click="handleClick('detail')">{{$t('message.detail')}}</el-button>
        </div>
      </div>
    </template>
    <template v-else-if="gid >= needGid || (showPoint && hasPayment)">
      <slot name="payment"></slot>
    </template>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import emitter from '@/app/emitter.js'

const router = useRouter()
const store = useStore()
defineProps({
  gid: {
    type: Number,
    required: true
  },
  authModule: {
    type: String,
    default () {
      return 'module1'
    }
  },
  hasPayment: {
    type: Boolean,
    default: false
  },
  showPoint: {
    type: Boolean,
    default: true
  },
  needGid: {
    type: Number,
    default: 2
  },
  fullScreen: {
    type: Boolean,
    default: false
  }
})

const handleClick = (type) => {
  switch (type) {
    case 'login':
      emitter.emit('showLogin')
      break
    case 'upgrade':
      router.push({
        name: 'subscribe',
        query: {
          type: 'purchase',
          group: 'professional',
          meal_type: 'professional',
          price_type: 'one_month'
        }
      })
      break
    case 'integral':
      store.commit('SHOW_EXCHANGE_DIALOG', 'B')
      break
    case 'detail':
      // eslint-disable-next-line no-case-declarations
      const { href } = router.resolve({ name: 'price' })
      window.open(href, '_blank')
      break
    default:
  }
}
</script>

<style lang="scss" scoped>
  div[class^="auth-container__"] {
    min-height: 40px;
    color: var( --color-unkown-text);
    .auth-wrap {
      line-height: 40px;
      text-align: center;
      font-size: 14px;
      display: flex;
      justify-content: center;
      position: relative;
      align-items: space-between;
      .auth-wrap-link {
        color: var(--color-priamry);
        cursor: pointer;
        &.login {
          margin: 0 2px;
        }
      }
      p {
        margin: 0px;
      }
      .operation {
        // position: absolute;
        // right: 0;
        margin-left: 20px;
        .auth-wrap-link {
          margin-left: 10px;
        }
      }
    }
    .auth-wrap-fullScreen {
      padding-top: 100px;
      // height: calc(100vh - 460px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: var(--color-text-3);
      img {
        width: 226px;
      }
      p {
        margin: 18px;
      }
      .login, .update, .detail{
        width: 102px;
      }
      .detail {
        border-color: var(--color-priamry);
        color: var(--color-priamry);
      }
    }
  }
</style>
