<template>
  <div v-on:keyup.enter="searchWay(false)" ref="homeWrap"  v-on:keyup.down="(e) => keyupFn(e, 1)" v-on:keyup.up="(e) => keyupFn(e, -1)" class="home" :class="{ 'sandFileUpload-labelActive': isDrag }">
    <!-- <div class="duration">
      <span></span>
      360全网安全大脑高效赋能 <font>{{diffDay}}</font> 天
    </div> -->
    <div class="container">
      <div class="box1">
        <div class="titlelogo"></div>
        <el-input
          :placeholder="$t('app.home.inputPlaceholder')"
          maxlength="256"
          v-model="searchValue"
          spellcheck="false"
          ref='input'
          @input="onInput"
          class="report-search"
          @blur="isInputFocus=false"
          @focus="isInputFocus=true"
        >
          <!-- <template #suffix><el-icon  @click="searchWay(0)"><Search /></el-icon></template> -->
          <template #append>
            <!-- <svg-icon v-if="isInputFocus && userInfo.group === 'qihoo'" class="advancedsearch" name="gaojisousuo" @click="$router.push('/taskhub/advancedsearch')"></svg-icon> -->
            <el-icon  @click="searchWay(0)"><Search /></el-icon>
          </template>
        </el-input>
        <el-collapse-transition>
          <div class="search-type" v-show="isShowSelect">
            <div class="item" :class="{active:isShowSelect&&searchType==0}" @click="(e) => searchWay(e)">
              <el-icon><Search /></el-icon>
              {{$t('app.home.searchHistory')}} {{searchValue}}
            </div>
            <div class="item" :class="{active:isShowSelect&&searchType==1}" @click="searchWay(2)">
              <el-icon><Upload /></el-icon>
              {{$t('app.home.newTask')}} {{searchValue}}
            </div>
          </div>
        </el-collapse-transition>
        <input
          type="file"
          id="file"
          ref="file"
          multiple
          @change="clickInput"
          @click="checkStatus"
          class="sandFileUpload-input"
        />
        <label
          for="file"
          class="sandFileUpload-drag"
          @drop="dropHandler"
          @dragover="dragOverHandler"
          @dragenter="dragenter"
          @dragleave="dragleave"
        >
          <img class="sandFileUpload-drag__img" src="@/assets/img/index/upload.png" />
          <div class="sandFileUpload-drag__msg">{{$t('app.home.upload')}}</div>
        </label>
      </div>
      <div class="statistics">
        <div class="switch">
          <div class="block" :style="{left:dayOrWeek ? '84px' : '4px'}"></div>
          <span :class="{'active':dayOrWeek==0}" @click="dayOrWeek=0">{{$t('app.home.hour')}}</span>
          <span :class="{'active':dayOrWeek==1}" @click="dayOrWeek=1">{{$t('app.home.day')}}</span>
        </div>
        <div class="cards">
          <div class="card" style="padding: 20px 0">
            <p class="card-title" style="padding-left: 30px">{{$t('app.home.fullTask')}}</p>
            <div class="content">
              <div ref="level" class="level"></div>
              <div ref="fileType" class="file-type"></div>
            </div>
          </div>
          <div class="card">
            <p class="card-title">{{$t('app.home.trendingTags')}}</p>
            <div class="content" v-if="tags.length">
              <div class="left">
                <div class="tag" v-for="(item,index) in tags.slice(0,5)" :key="item.key">
                  <span class="flex" @click="toPublic(item)">
                    <span class="tagLeft">
                      {{$isZh() ? item.zhcn_name : item.enus_name}}
                      <span class="range" :style="{color:rangeColor[index],borderColor:rangeColor[index]}"  v-if="index<3">TOP{{index+1}}</span>
                    </span>
                    <span class="tagCount">{{item.doc_count}}</span>
                  </span>
                  <img :src="$img('home/tagRight.svg')" alt="" />
                </div>
              </div>
              <div class="right" v-if="tags.length > 5">
                <div class="tag" v-for="item in tags.slice(5,10)" :key="item.key" @click="toPublic(item)">
                  <span class="flex">
                    <span class="tagLeft">{{$isZh() ? item.zhcn_name : item.enus_name}}</span>
                    <span class="tagCount">{{item.doc_count}}</span>
                  </span>
                  <img :src="$img('home/tagRight.svg')" alt="" />
                </div>
              </div>
            </div>
            <no-data style="margin-top:50px" v-else></no-data>
          </div>
        </div>
      </div>
      <div class="wrap">
        <p style="font-weight: 700;">{{$t('app.home.threatmap')}}</p>
        <div class="threatMap" :class="!$isDark() ? 'light' : ''" ref="worldMap"></div>
        <div class="zoom">
          <el-icon :color="$isDark() ? '' : '#525966'" @click="roamMap(1)"><Plus /></el-icon>
          <el-icon :color="$isDark() ? '' : '#525966'" @click="roamMap(-1)"><Minus /></el-icon>
          <el-icon :color="$isDark() ? '' : '#525966'" @click="roamMap(0)"><Refresh /></el-icon>
        </div>
      </div>
      <div class="org">
        <div class="cards">
          <div class="card nopadding-left">
            <p class="card-title">{{$t('app.home.trendingfamilies')}}</p>
            <div class="content marginleft" v-if="clan.length">
              <div class="level">
                <div class="tag" @click="toPublic(item)" v-for="(item,index) in clan.slice(0,5)" :key="index">
                  <span class="flex" :style="getColor(index)">
                    <span class="tagLeft">
                      <span class="icon" :style="getBg(index, 'clan')"></span>
                      {{ $isZh() ? item.zhcn_name : item.enus_name }}
                    </span>
                    <span class="tagCount">{{item.doc_count}}</span>
                  </span>
                  <span class="stripe"></span>
                </div>
              </div>
              <div class="file-type" v-if="clan.length > 5">
                <div class="tag" @click="toPublic(item)" v-for="(item,index) in clan.slice(5,10)" :key="index">
                  <span class="flex">
                    <span class="tagLeft">
                      <span class="icon"></span>
                      {{ $isZh() ? item.zhcn_name : item.enus_name }}
                    </span>
                    <span class="tagCount">{{item.doc_count}}</span>
                  </span>
                  <span class="stripe"></span>
                </div>
              </div>
            </div>
            <no-data style="margin-top:50px" v-else></no-data>
          </div>
          <div class="card nopadding-left">
            <p class="card-title">{{$t('app.home.trendinggroup')}}</p>
            <div class="content marginleft" v-if="org.length">
              <div class="level">
                <div class="tag" @click="toPublic(item)" v-for="(item,index) in org.slice(0,5)" :key="index">
                  <span class="flex" :style="getColor(index)">
                    <span class="tagLeft">
                      <span class="icon family-icon" :style="getBg(index, 'family')"></span>
                      {{ $isZh() ? item.zhcn_name : item.enus_name }}
                    </span>
                    <span class="tagCount">{{item.doc_count}}</span>
                  </span>
                  <span class="stripe"></span>
                </div>
              </div>
              <div class="file-type" v-if="org.length > 5">
                <div class="tag" @click="toPublic(item)" v-for="(item,index) in org.slice(5,10)" :key="index">
                  <span class="flex">
                    <span class="tagLeft">
                      <span class="icon family-icon"></span>
                      {{ $isZh() ? item.zhcn_name : item.enus_name }}
                    </span>
                    <span class="tagCount">{{item.doc_count}}</span>
                  </span>
                  <span class="stripe"></span>
                </div>
              </div>
            </div>
            <no-data style="margin-top:50px" v-else></no-data>
          </div>
        </div>
      </div>
      <div class="quickStart">
        <div class="title"></div>
        <div class="content">
          <div class="item" :class="!$isDark() && 'light'" @click="$router.push('/help/docs/introduction')" @mouseenter="isCenterFocus = false" @mouseleave="isCenterFocus = true">
            <div class="left">
              <p>{{$t('app.home.quickstart1')}}</p>
              <p>{{$t('app.home.quickstart2')}}</p>
              <p>{{$t('app.home.quickstart3')}}</p>
            </div>
            <div class="right"></div>
          </div>
          <div class="item" :class="!$isDark() && 'light'" @click="$router.push('/intro')">
            <div class="left">
              <p>{{$t('app.home.feature1')}}</p>
              <p>{{$t('app.home.feature2')}}</p>
              <p><el-icon class="playicon"><VideoPlay /></el-icon>{{$t('app.home.feature3')}}</p>
            </div>
            <div class="right"></div>
          </div>
          <div class="item" :class="!$isDark() && 'light'" @click="$router.push('/price')" @mouseenter="isCenterFocus = false" @mouseleave="isCenterFocus = true">
            <div class="left">
              <p>{{$t('app.home.service1')}}</p>
              <p>{{$t('app.home.service2')}}</p>
              <p>{{$t('app.home.service3')}}</p>
            </div>
            <div class="right"></div>
          </div>
        </div>
      </div>
    </div>
    <backTo-top></backTo-top>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import * as echarts from 'echarts'
import { numFormat } from 'utils/lib/formatters'
import worldMap from 'assets/map/world.json'
import hainan from 'assets/map/qundao.js'
import nameMap from 'assets/map/country-name-zh.json'
// import dayjs from 'dayjs'
import { Search, Upload, Plus, Minus, Refresh, VideoPlay } from '@element-plus/icons-vue'
import { t, watchSwitchLang, isZh } from 'app/i18n'
import { isDark, watchSwitchTheme } from 'app/style'
import emitter from '@/app/emitter.js'
import { checkLogin } from 'services/auth'

import {
  getGlobalViewMonitor,
  getGlobalViewAnalysisresult,
  getGlobalViewThreatmap,
  getGlobalViewHotTags,
  getGlobalViewHotOrgs
} from 'services/index'

worldMap.features.forEach((item, i) => {
  if (item.properties.name === '中国') {
    const code = item.geometry.coordinates
    item.geometry.coordinates = code.concat(hainan)
  }
})
export default {
  data () {
    return {
      searchValue: '',
      dayOrWeek: 1,
      isInputFocus: false,
      rangeColor: ['#FF6D6F', '#FF8636', '#FCAD14'],
      searchType: 0,
      tags: [
        // { key: '静态病毒', doc_count: 1456112 },
        // { key: '连接外网', doc_count: 1633424 },
        // { key: '利用', doc_count: 992131 },
        // { key: '宏', doc_count: 871342 },
        // { key: '勒索病毒', doc_count: 662251 },
        // { key: '修改诱饵文件', doc_count: 543666 },
        // { key: '批量扩展名', doc_count: 231071 },
        // { key: '自启动', doc_count: 111287 },
        // { key: '资源管理器运行进程', doc_count: 90951 },
        // { key: '已知漏洞', doc_count: 87112 }
      ],
      clan: [
        // { key: 'ZEUS', doc_count: 1456112 },
        // { key: 'BEGSEABUG', doc_count: 1633424 },
        // { key: 'WINSXSBOT', doc_count: 992131 },
        // { key: 'WINSXSBOT', doc_count: 871342 },
        // { key: '勒索病毒', doc_count: 662251 },
        // { key: '修改诱饵文件', doc_count: 543666 },
        // { key: '批量扩展名', doc_count: 231071 },
        // { key: '自启动', doc_count: 111287 },
        // { key: '资源管理器运行进程', doc_count: 90951 },
        // { key: '已知漏洞', doc_count: 87112 }
      ],
      org: [
        // { key: 'APT-C-39', doc_count: 1456112 },
        // { key: 'APT-C-39', doc_count: 1633424 },
        // { key: 'APT-C-39', doc_count: 992131 },
        // { key: 'APT-C-39', doc_count: 871342 },
        // { key: 'APT-C-39', doc_count: 662251 },
        // { key: 'APT-C-39', doc_count: 543666 },
        // { key: 'APT-C-39', doc_count: 231071 },
        // { key: 'APT-C-39', doc_count: 111287 },
        // { key: 'APT-C-39', doc_count: 90951 },
        // { key: 'APT-C-39', doc_count: 87112 }
      ],
      mapMin: 0,
      mapMax: 0,
      threatmap: [],
      tagList: [],
      isDrag: false,
      // diffDay: dayjs().diff(dayjs('2020-09-30'), 'day'),
      isCenterFocus: true,
      fileList: [],
      count: 0,
      levelData: [],
      data: []
    }
  },
  components: {
    Search,
    Upload,
    Refresh,
    Minus,
    Plus,
    VideoPlay
  },
  mounted() {
    this.getAllData()
    document.querySelectorAll('.el-input__inner')[0].addEventListener('keyup', function(e) {
      function focusEnd(id) {
        var inpEl = document.querySelectorAll('.el-input__inner')[0]
        inpEl.focus()
        var length = inpEl.value.length
        if (document.selection) {
          var range = inpEl.createTextRange()
          range.moveStart('character', length)
          range.collapse()
          range.select()
        } else if (typeof inpEl.selectionStart === 'number' && typeof inpEl.selectionEnd === 'number') { // selectionStart和selectionEnd两个属性是当前选中文本起始位置的索引
          inpEl.selectionStart = inpEl.selectionEnd = length
        }
      }
      // e.keyCode === 40 && this.blur()
      e.keyCode === 38 && focusEnd()
    }, false)
    watchSwitchLang((l) => {
      this.instance.setOption(this.option)
      this.fileInstance.setOption(this.levelOption)
    })
    watchSwitchTheme((l) => {
      this.option = this.config(this.data)
      this.instance.setOption(this.option)
      this.levelOption = this.config(this.levelData)
      this.fileInstance.setOption(this.levelOption)
      this.initMap()
    })
    // if (typeof window.orientation !== 'undefined') {
    //   // 当前设备是移动设备
    //   this.$refs.homeWrap.style.transform = 'scale(1.15)'
    //   this.$refs.homeWrap.style.transformOrigin = 'top center'
    // }
    window.addEventListener('resize', this.resize, true)
  },
  unmounted () {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    resize() {
      this.fileInstance.resize()
      this.instance.resize()
    },
    open (to) {
      window.open(to)
    },
    toPublic(item) {
      // this.$router.push({
      //   name: 'public',
      //   query: {
      //     homeTag: item.name
      //   }
      // })
    },
    keyupFn(ev, v) {
      let origin = this.searchType
      origin += v
      origin = origin > 1 ? 0 : origin < 0 ? 1 : origin
      this.searchType = origin
    },
    searchWay(v) {
      if (v) this.searchType = v - 1
      if (!this.searchValue) return
      if (this.searchValue.length > 256) {
        this.$store.commit('app/showMessage', {
          type: 'error',
          message: t('other.views.home.inputMsg'),
          duration: 1500
        })
        return
      }
      if (this.searchType) {
        const reg = /^((ht|f)tps?):\/\/[\w\-]+(\.[\w\-]+)+.+$/ // eslint-disable-line
        const reg2 = /(^[a-fA-F0-9]{32}$)|(^[a-fA-F0-9]{40}$)|(^[a-fA-F0-9]{64}$)/
        const file = { data: this.searchValue }
        if (reg.test(this.searchValue)) {
          file.type = 'url'
        } else if (reg2.test(this.searchValue)) {
          file.type = 'hash'
          if (this.userInfo.group !== 'qihoo') {
            this.$store.commit('app/showMessage', {
              type: 'error',
              message: t('other.views.home.usertypeHash'),
              duration: 1500
            })
            return
          }
        }
        window.transferFile = file
        this.toDetection()
      } else {
        this.$router.push({ name: 'search', query: { querystr: this.searchValue } })
      }
    },
    dropHandler(ev) {
      const emntyList = []
      const files = []
      emntyList.forEach.call(ev.dataTransfer.files, (file) => {
        files.push(file)
      }, false)
      ev.preventDefault()
      this.isDrag = false
      // 判断登录
      checkLogin().then((res) => {
        if (!res.is_login) {
          emitter.emit('showLogin')
        } else {
          window.transferFile = {
            type: 'file',
            data: files
          }
          files.map((item) => {
            this.handleChange(item, files.length)
          })
        }
      })
    },
    clickInput() {
      const files = this.$refs.file.files
      window.transferFile = {
        type: 'file',
        data: files
      }
      Array.from(files).forEach((item) => {
        this.handleChange(item, files.length)
      })
    },
    checkStatus(ev) {
      if (!this.loginStatus) {
        ev.preventDefault()
        // 判断登录
        checkLogin().then((res) => {
          if (!res.is_login) {
            this.loginStatus = false
            emitter.emit('showLogin')
          } else {
            this.loginStatus = true
            this.$refs.file.click()
            setTimeout(() => {
              this.loginStatus = false
            }, 200)
          }
        })
      }
    },
    handleChange(file, total) {
      this.$store.commit('app/closeMessage')
      let flag = true
      const reader = new FileReader()
      reader.readAsArrayBuffer(file)
      reader.onerror = (e) => {
        this.$store.commit('app/showMessage', {
          type: 'error',
          message: t('other.views.sendfileupload.noFolder'),
          duration: 1000
        })
        flag = false
      }
      reader.onloadend = (e) => {
        this.count += 1
        const size = file.size
        if (size === 0) {
          this.$store.commit('app/showMessage', {
            type: 'error',
            message: t('other.views.home.noemptyFolder'),
            duration: 1000
          })
          flag = false
        }
        if (flag) {
          this.fileList.push({
            size: file.size,
            name: file.name,
            file: file
          })
        }
        if (this.count === total) this.toDetection()
      }
    },
    toDetection() {
      this.$router.push('/detect')
    },
    dragOverHandler(ev) {
      ev.preventDefault()
    },
    dragenter(e) {
      e.stopPropagation()
      // if (e.target.className === 'sandFileUpload-drag__msg') return false
      this.isDrag = true
    },
    dragleave(e) {
      e.stopPropagation()
      // if (e.target.className === 'sandFileUpload-drag__msg') return false
      this.isDrag = false
    },
    getBg(index, type) {
      const colors = ['#ff4d4f', '#ff8f46', '#fcad14']
      if (index > 2) return
      return {
        backgroundImage: 'url(' + require(`assets/img/index/${type}-${index}.png`) + ')',
        color: colors[index]
      }
    },
    getColor(index) {
      const colors = ['#ff4d4f', '#ff8f46', '#fcad14']
      if (index > 2) return
      return {
        color: colors[index]
      }
    },
    getAllData(flag) {
      // 威胁地图
      getGlobalViewThreatmap(this.queryTime)
        .then((res) => {
          const arr = []
          for (const en in nameMap) {
            arr.push({
              name: nameMap[en],
              en_name: en,
              value: 0
            })
          }
          arr.map(v => {
            res.forEach((item) => {
              if (item.key === v.name) {
                v.value = item.doc_count
              }
              this.mapMax = Math.max(this.mapMax, item.doc_count)
              this.mapMin = Math.min(this.mapMax, item.doc_count)
            })
            return v
          })
          this.threatmap = arr
          this.initMap()
        })
        .catch((e) => {
          console.log(e)
        })

      getGlobalViewMonitor(this.queryTime).then(res => {
        this.fileInstance = echarts.init(this.$refs.fileType, null, { renderer: 'svg' })
        this.levelData = [
          { value: res.file + res.md5, name: 'files', itemStyle: { color: '#008FE1' } },
          { value: res.url, name: 'urls', itemStyle: { color: '#00DAA6' } }
        ]
        this.levelOption = this.config(this.levelData)
        this.fileInstance.setOption(this.levelOption)
      })
      this.getGlobalViewAnalysisresult()
      // 热门标签
      getGlobalViewHotTags({
        ...this.queryTime,
        lang: isZh() ? 'zh' : 'en'
      })
        .then((res) => {
          this.tags = res.total
        })
        .catch((e) => {
          console.log(e)
        })
      getGlobalViewHotOrgs({
        ...this.queryTime,
        lang: isZh() ? 'zh' : 'en'
      })
        .then((res) => {
          this.clan = res.malware
          this.org = res.apt
        })
        .catch((e) => {
          console.log(e)
        })
    },
    getGlobalViewAnalysisresult() {
      getGlobalViewAnalysisresult(this.queryTime).then(res => {
        const keys = ['count_may_threat', 'count_not_threat', 'count_threat']
        const values = keys.map(v => res.find(k => k.key === v)).map(v => v.count)
        this.instance = echarts.init(this.$refs.level, null, { renderer: 'svg' })
        this.data = [
          { value: values[2], name: 'malicious', alias: 'malicious', itemStyle: { color: '#FF4D4F' } },
          { value: values[0], name: 'suspicious', alias: 'suspicious', itemStyle: { color: '#FCAD14' } },
          { value: values[1], name: 'clean', alias: 'clean', itemStyle: { color: '#52C41A' } }
        ]
        this.option = this.config(this.data)
        this.instance.setOption(this.option)
      })
    },
    onInput(e) {
    },
    config(data) {
      const total = data.reduce((total, cur) => (total += cur.value), 0)
      let newData = data.map(k => {
        const v = k.value
        let percent = !v ? 0 : ((v / total) * 100).toFixed(0)
        if (v !== total && percent > 99) { percent = '99' }
        if (v !== 0 && percent < 1) { percent = '1' }
        return {
          ...k,
          percent
        }
      })
      newData = newData.sort((a, b) => a.percent - b.percent)
      if (newData.length > 2) newData[1].percent = 100 - (Number(newData[0].percent) + Number(newData[2].percent))
      const totalText = total >= 10000 ? `${numFormat(total)[0]}{unit|${numFormat(total)[1]}}` : total
      return {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return t(`app.home.${params.name}`) + '&nbsp&nbsp&nbsp&nbsp' + params.value
          }
        },
        title: {
          text: totalText,
          left: 'center',
          top: '18%',
          // padding:[24,0],
          textStyle: {
            color: isDark() ? '#ffffff' : '#242933',
            fontSize: 22,
            fontFamily: 'DIN Alternate Bold',
            align: 'center',
            rich: {
              unit: {
                fontSize: '14px',
                fontFamily: 'PingFang SC',
                color: isDark() ? '#ffffff' : '#242933',
                padding: [4, 0, 0, 3]
              }
            }
          }
        },
        legend: {
          width: '100%',
          top: '60%',
          left: '10%',
          orient: 'vertical',
          itemGap: 15,
          textStyle: {
            color: isDark() ? '#ffffff' : '#242933',
            fontSize: '14px',
            fontFamily: 'DIN Alternate Bold',
            rich: {
              a: {
                width: 50,
                padding: [0, 20, 0, 0]
              },
              value: {
                width: 60,
                fontWeight: 500,
                fontFamily: 'DIN Alternate Bold',
                padding: [0, 30, 0, 10],
                align: 'right'
              },
              c: {
                width: 30,
                padding: [0, 20, 0, 0],
                fontWeight: 500,
                fontFamily: 'DIN Alternate Bold',
                align: 'right'
              }
            }
          },
          icon: 'circle',
          formatter: function (name) {
            let tarValue
            let percent
            for (var i = 0; i < newData.length; i++) {
              if (newData[i].name === name) {
                tarValue = newData[i].value
                percent = newData[i].percent
              }
            }
            const formatValue = String(tarValue).replace(/(\d)(?=(\d{3})+\b)/g, '$1,')
            return `{a|${t(`app.home.${name}`)}}{value|${formatValue}}{c|${percent}%}`
          }
        },
        series: [
          {
            name: '全站任务',
            type: 'pie',
            minAngle: 2,
            radius: ['40%', '46%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 2,
              // borderColor: '#1a2024',
              borderWidth: 1
            },
            center: ['50%', '25%'],
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data
          }
        ]
      }
    },
    initMap() {
      if (!this.mapChart) {
        echarts.registerMap('world', worldMap)
        this.mapChart = echarts.init(this.$refs.worldMap)
      }
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            // if (!params.value) return ''
            console.log(params)
            return isZh() ? params.name + '<br/>' + t('other.views.home.numberOfNet') + (params.value || 0).toLocaleString() : params.data?.en_name + '<br/>' + 'Number of network connection events: ' + (params.value || 0).toLocaleString()
          },
          backgroundColor: 'rgba(0,0,0,0.5)',
          textStyle: {
            color: '#ffffff'
          }
        },
        geo3D: {
          map: 'world',
          shading: 'realistic',
          silent: true,
          environment: '#333',
          viewControl: {
            distance: 70,
            alpha: 89,
            panMouseButton: 'left',
            rotateMouseButton: 'right'
          }
        },
        // visualMap: [
        //   {
        //     dimension: 0,
        //     left: 10,
        //     itemWidth: 12,
        //     min: this.mapMin,
        //     max: this.mapMax,
        //     text: [`${this.mapMax}`, `${this.mapMin}`],
        //     textStyle: {
        //       color: '#ddd'
        //     },
        //     inRange: {
        //       color: ['rgba(0,182,130,0.2)']
        //     }
        //   }
        // ],
        visualMap: {
          type: 'piecewise', // 定义为分段型 visualMap
          min: 0,
          max: 1000,
          itemWidth: 20,
          itemHeight: 18,
          bottom: 20,
          left: 30,
          itemGap: 3,
          itemSymbol: 'rect',
          pieces: [
            { gt: 6000, label: '6000+', color: this.$isDark() ? '#9EFFE2' : '#1F9170' },
            { gt: 5000, lte: 6000, label: '6000', color: this.$isDark() ? '#79FFD7' : '#01ab79' },
            { gt: 4000, lte: 5000, label: '5000', color: this.$isDark() ? '#56FFCD' : '#00DB9B' },
            { gt: 3000, lte: 4000, label: '4000', color: '#04FFB5' },
            { gt: 2000, lte: 3000, label: '3000', color: this.$isDark() ? '#00DB9B' : '#56FFCD' },
            { gt: 1000, lte: 2000, label: '2000', color: this.$isDark() ? '#01ab79' : '#79FFD7' },
            { gt: 0, lte: 1000, label: '1000', color: this.$isDark() ? '#1F9170' : '#9EFFE2' }
          ],
          textStyle: {
            color: '#babfd0'
          }
        },
        // backgroundColor: '#1a2024',
        series: [
          {
            name: 'threat Map',
            type: 'map',
            mapType: 'world',
            nameMap: nameMap,
            roam: 'move',
            zoom: 1,
            itemStyle: {
              normal: {
                areaColor: 'rgba(0,182,130,0.2)',
                borderColor: 'rgba(86,255,205,0.5)'
              }
            },
            selectedMode: false,
            emphasis: {
              label: {
                show: false
              },
              areaColor: '#BAE7FF',
              borderColor: '#f00',
              itemStyle: {
                areaColor: '#79FFD7'
              }
            },
            select: {
              label: {
                show: false
              }
            },
            data: this.threatmap
          }
        ]
      }
      this.mapChart.setOption(option)
    },
    roamMap(flag) {
      const currentZoom = this.mapChart.getOption().series[0].zoom

      const increaseAmplitude = 1 + flag * 0.2
      const value = currentZoom * increaseAmplitude
      if (value > 3 || value < 0.5) return
      this.mapChart.setOption({
        series: [{
          zoom: flag === 0 ? 1 : value,
          center: [0, 15]
        }]
      })
    }
  },
  computed: {
    isShowSelect() {
      return this.searchValue !== '' && this.isInputFocus
    },
    queryTime() {
      return { query: this.dayOrWeek ? '7d' : '1d' }
    },
    ...mapGetters(['userInfo'])
  },
  watch: {
    'queryTime'() {
      this.getAllData()
    }
  }
}
</script>

<style lang="scss" scoped>
[data-theme='light'] {
  &[lang='en'] {
    .home {
      .quickStart .title {
        background-image: url('~@/assets/img/index/quick-start-light-en.png') !important;
      }
      .titlelogo  {
        background-image: url('~@/assets/img/index/logo-light.png');
      }
    }
  }
  .home {
    // background: url('~@/assets/img/index/box.png') center top/auto 630px no-repeat, url('~@/assets/img/index/box-1.png') center bottom/contain no-repeat;
    background: url(/assets/img/box.d13a85d2.png) center top/auto 630px no-repeat, url(/assets/img/box-1.41b8a3ad.png) center bottom/100% 73.8% no-repeat;
    background-color: #0F1116;
    .quickStart .title {
      background-image: url('~@/assets/img/index/quick-start-light.png')!important;
    }
  }
}

[lang='en'] {
  .home {
    .container  .title {
      background-image: url('~@/assets/img/index/logo-en.png');
    }
    .quickStart .title {
      width: 221px;
      background-image: url('~@/assets/img/index/quick-start-en.png') !important;
    }
  }
}
.home {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  min-width: 1220px;
  position: relative;
  background: url('~@/assets/img/index/box.png') center top/100% 630px no-repeat;
  background-color: #0F1116;
}
.sandFileUpload-labelActive  {
  background-image: url('~@/assets/img/index/box-active.png');
  .sandFileUpload-drag__msg  {
    color: #01ab79;
  }
}
.duration  {
  width: 302px;
  height: 50px;
  border: 1px solid #00ab7a4d;
  border-radius: 2px;
  position: absolute;
  top: 72px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: var(--color-text-1);
  span  {
    width: 26px;
    height: 16px;
    background-image: url('~@/assets/img/index/brain.png');
    background-size: 100% 100%;
    margin-right: 8px;
    opacity: 0.8;
  }
  font  {
    margin: 0 3px;
    color: #02a979;
  }
}
.container  {
  font-size: 14px;
  color: #fff;
  width: 70%;
  min-width: 1200px;
  max-width: 1334px;
  margin: 0 auto;
  .titlelogo  {
    width: 428px;
    height: 92px;
    margin: 0 auto;
    background-image: url('~@/assets/img/index/logo.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .report-search  {
    // width: calc(60% - 120px);
    width: 810px;
    height: 50px;
    margin: 72px auto 0;
    display: table;
    border-radius: 2px;
    &:deep(.el-input__wrapper) {
      width: 100%;
      padding:0;
      background-color: transparent;
      // border: 1px solid #ffffff33;
      backdrop-filter: blur(10px);
      box-shadow: none;
    }
    & :deep(input)  {
      // border: 1px solid rgba(255, 255, 255, 0.2);
      border: 1px solid #ffffff33;
      height: 50px;
      background: var(--home-input);

      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.6);
      padding: 0 75px 0 15px;
      color: #fff;
      border-radius: 2px;
      &::placeholder {
        color: #fff;
        opacity: .6;
      }
      &:focus, &:hover  {
        border: 1px solid #01AB79;
      }
      &:focus {
        // backdrop-filter: blur(10px);
      }
    }
    & :deep(.el-input-group__append)  {
      position: absolute;
      background: none;
      border: none;
      right: 0;
      top: 0;
      cursor: pointer;
      box-shadow: none;
      display: flex;
      align-items: center;
      i {
        line-height: 50px;
        z-index: 2;
        font-size: 24px;
        color: #8b8f8d;
        transition: none;
        cursor: pointer;
        &:hover  {
          color: #01AB79;
        }
      }
    }
    .advancedsearch {
      font-size: 24px;
      color: var(--color-text-2);
      margin-right: 4px;
      &:hover {
        color: #01AB79;
      }
    }
  }
  .search-type  {
    // width: calc(60% - 120px);
    width: 810px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin: 8px auto 0;
    padding: 6px 0;
    // background: #1e242c;
    background: var(--color-bg-3);
    .item  {
      width: 100%;
      height: 50px;
      line-height: 50px;
      cursor: pointer;
      font-size: 14px;
      color: var(--color-text-1);
      padding-right: 5px;
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &.active  {
        // background: #2B3440;
        background: var(--color-bg-4);
      }
      &:hover {
        // background: #353D48;
        background: var(--color-bg-5);
      }
      i  {
        font-size: 20px;
        padding-left: 10px;
        position: relative;
        top: 5px;
      }
    }
  }
  .sandFileUpload-input  {
    display: none;
  }
  .sandFileUpload-drag  {
    // width: 310px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 77px;
    padding: 50px 0;
    cursor: pointer;
    &:hover {
      div {
        color: #01AB79;
      }
    }
    img {
      display: inline-block;
      width: 22px;
      height: 22px;
      margin-right: 6px;
      pointer-events: none;
    }
    div {
      pointer-events: none;
    }
  }
  .statistics, .org  {
    position: relative;.switch  {
      width: 170px;
      height: 40px;
      background: var(--home-switch);
      box-shadow: 0 0 24px 0 var(--home-card-shadow);
      border-radius: 2px;
      margin-bottom: 16px;
      position: absolute;
      right: 0;
      top: -56px;
      user-select: none;
      span  {
        display: inline-block;
        line-height: 40px;
        text-align: center;
        width: 50%;
        font-size: 14px;
        color: var(--color-text-1);
        opacity: 0.7;
        cursor: pointer;
      }
      .active  {
        color: #00ab7a;
        font-weight: 500;
      }
      .block  {
        position: absolute;
        background: var(--home-switch-card);
        box-shadow: 0 0 4px 0 var(--home-switch-card-shadow);
        border-radius: 2px;
        width: 82px;
        height: 32px;
        top: 4px;
        left: 4px;
        transition: left 0.3s;
      }
    }
    .cards  {
      display: flex;
      justify-content: space-between;
      .nopadding-left {
        padding: 20px 0 20px 30px !important;
      }
      .card  {
        width: calc(50% - 8px);
        box-sizing: border-box;
        padding: 20px 30px;
        height: 315px;
        // background: #1a2024;
        background: var(--home-card-background);
        box-shadow: 0 0 24px 0 var(--home-card-shadow);
        // box-shadow: 0 6px 28px 8px #6f97bf08, 0 4px 16px 0 #6f97bf0f, 0 1px 6px -4px #6f97bf14;
        border-radius: 2px;
        .card-title  {
          font-weight: 700;
          font-size: 16px;
          color: var(--color-text-1);
          margin-top: 0;
        }
        .marginleft {
          margin-left: -10px;
        }
        .content  {
          display: flex;
          width: 100%;
          height: 227px;
          box-sizing: border-box;
          margin-top: 30px;
          div  {
            flex: 1;
          }
          .file-type  {
            border-left: 1px dashed var(--color-border-1);
          }
          .right  {
            border-left: 1px dashed var(--color-border-1);
            margin-left: 21px;
            padding-left: 21px;
          }
          .tag  {
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 28px;
            line-height: 28px;
            // cursor: pointer;
            background-size: 8px 8px;
            margin-bottom: 20px;
            border-left: 2px solid #01AB79;
            font-size: 14px;
            color: var(--color-text-1);

            box-sizing: border-box;
            &:nth-child(-n+2) {
              .tagLeft  {
                font-family: PingFangSC-Medium;
              }
            }
            .range  {
              display: inline-block;
              padding: 1px 3px;
              line-height: 14px;
              border: 1px solid #fcad14;
              border-radius: 2px;
              font-size: 8px;
              color: #fcad14;
              transform: scale(0.67);
            }
            .flex  {
              display: flex;
              justify-content: space-between;
              // background-color: #2c3236;
              background-color: var(--home-card-tag);
              flex: 1;
            }
            .tagLeft  {
              padding-left: 12px;
              flex: 1;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              max-width: 222px;
            }
            .tagCount  {
              font-family: DIN Alternate Bold;
              font-weight: 500;
            }
            .stripe  {
              width: 32px;
              background-image: url('~@/assets/img/index/tagRight.svg');

              background-size: 100% 100%;
            }
          }
        }
      }
    }
  }
  .org  {
    margin-top: 16px;
    .tag  {
      border: none !important;
      .flex  {
        background: none !important;
      }
      .stripe  {
        width: 18px !important;
        background: none !important;
      }
      .icon  {
        display: inline-block;
        margin-right: 3px;
        width: 16px;
        height: 16px;
        background-image: url('~@/assets/img/index/clan-3.png');
        background-size: 100% 100%;
        position: relative;
        top: 3px;
      }
      .family-icon  {
        background-image: url('~@/assets/img/index/family-3.png');
      }
    }
  }
  .quickStart  {
    margin: 100px 0 285px;
    .title  {
      width: 180px;
      height: 58px;
      margin: 0 auto;
      font-weight: 500;
      font-size: 30px;
      color: var(--color-text-1);
      background-size: 100% 100%;
      position: relative;
      background-image: url('~@/assets/img/index/quick-start.png');
    }
    .content  {
      width: 100%;
      display: flex;
      margin-top: 40px;
      justify-content: space-between;
      .item  {
        width: calc(33.3% - 11px);
        height: 180px;
        padding: 25px 0 20px 20px;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        background-image: url('~@/assets/img/index/home-card.png');
        background-size: 100% 100%;
        position: relative;
        &.light {
          background: linear-gradient(180deg, #f4f6f8 0%, #fefeff 100%);
          box-shadow: 0 6px 28px 8px #6f97bf0d, 0 4px 16px 0 #6f97bf14;
          .left {
            p {
              &:nth-child(2)  {
                color: #242933;
                opacity: 1;
              }
            }
          }
          .right {
            opacity: .5;
          }
          &:hover {
            background-image: none;
            .left {
              p {
                &:nth-child(1)  {
                  background: #00ab7a;
                  color: #f6f7f9;
                }
                &:nth-child(2)  {
                  color: #00ab7a;
                }
              }
            }
            .right {
              opacity: 1;
            }
          }
        }
        p {
          opacity: .5;
        }
        &:hover  {
          background-image: url('~@/assets/img/index/home-card-active.png');
          p {
            opacity: 1;
          }
        }
        &.default  {
          background-image: url('~@/assets/img/index/home-card-active.png');
          p {
            opacity: 1;
          }
        }
        &:nth-child(1)  {
          .right  {
            background-image: url('~@/assets/img/index/quickStart-bg-book.png');
          }
        }
        &:nth-child(2)  {
          .playicon {
            margin-right: 4px;
            position: relative;
            top: 2px;
          }
          .right  {
            background-image: url('~@/assets/img/index/finger.png');
            background-size: 60% 83%;
            background-position: center;
            bottom: -13px;
          }
        }
        &:nth-child(3) .right  {
          background-image: url('~@/assets/img/index/quickStart-bg-vip.png');
          position: relative;
          bottom: 14px;
        }
        .right  {
          width: 50%;
          height: 167px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          position: absolute;
          bottom: 2px;
          right: 0;
        }
        .left  {
          width: 50%;
          p  {
            color: var(--home-quick-title-font);
            font-family: PingFangSC-Medium;
            font-size: 18px;
            // text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
            // text-shadow: 5px 5px 5px #000;
            &:nth-child(1)  {
              width: inherit;
              height: 26px;
              line-height: 26px;
              text-align: center;
              background: var(--home-quick-title-bg);
              border-radius: 4px;
              font-size: 14px;
              margin: 6px 0 14px;
            }
            &:nth-child(2)  {
              font-size: 18px;
              margin: 18px 0;
              text-shadow: 0px 2px 4px var(--popover-box-shadow1);
            }
            &:nth-child(3)  {
              width: max-content;
              font-size: 14px;
              position: absolute;
              bottom: 20px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  .wrap  {
    height: 500px;
    background-color: var(--home-card-background);
    position: relative;
    box-shadow: 0 0 24px 0 var(--home-card-shadow);
    p  {
      font-size: 16px;
      color: var( --color-text-1);
      position: absolute;
      top: 16px;
      left: 30px;
      z-index: 2;
      font-weight: 500;
    }
    .zoom  {
      display: flex;
      flex-direction: column;
      border-radius: 2px 2px 0px 0px;
      position: absolute;
      right: 30px;
      bottom: 20px;
      z-index: 2;
      box-shadow: 0 0 4px 0 var(--home-switch-card-shadow);
      i  {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        background: var(--home-map-btn-bg);
        cursor: pointer;
        &:hover  {
          color: #01AB79;
        }
      }
    }
  }
  .threatMap  {
    margin-top: 16px;
    width: 100%;
    height: 500px;
    background-size: 20px 20px;
    background-image: linear-gradient(90deg, rgba(42, 50, 56, 0.5) 10%, rgba(0, 0, 0, 0) 10%), linear-gradient(rgba(42, 50, 56, 0.5) 10%, rgba(0, 0, 0, 0) 10%);
    &.light {
      background-image: linear-gradient(90deg, rgba(42, 50, 56, 0.03) 10%, rgba(0, 0, 0, 0) 10%), linear-gradient(rgba(42, 50, 56, 0.03) 10%, rgba(0, 0, 0, 0) 10%);
    }
  }
}

// @media screen and (max-width:1550px) {
//   .box1  {
//     height: 294px !important;
//     // height: 320px !important;
//   }
//   .report-search {
//     margin-top: 32px !important;
//   }
// }

.box1  {
  height: 420px;
  padding-top: 164px;
  box-sizing: content-box;
  position: relative;
}
</style>
