import request from 'app/http'

// 创建订单
const getPaymentPay = params => {
  return request.get('/payment/pay', { params })
}
const getPaymentOrderAdd = params => {
  return request.post('/payment/order/purchase', params)
}
const getPaymentOrderUpgrade = params => {
  return request.post('/payment/order/upgrade', params)
}
const getPaymentOrderRenew = params => {
  return request.post('/payment/order/renew', params)
}
const getPaymentOrderAmount = params => {
  return request.get('/payment/order/amount?', { params })
}
const getPaymentOrderStatus = params => {
  return request.get('/payment/order/status?', { params })
}
const getOrderList = params => {
  return request.get('/payment/order/list?', { params })
}
const getPaymentOrderCancel = params => {
  return request.post('/payment/order/cancel', params)
}
const getPaymentSubscribe = () => {
  return request.get('/payment/subscribe')
}
const getPaymentOrderCheck = () => {
  return request.get('/payment/order/check')
}
const getPaymentOrderPrice = () => {
  return request.get('/payment/order/price')
}
const getPaymentOrderPriceShow = () => {
  return request.get('/payment/order/priceshow')
}

export {
  getPaymentOrderAdd,
  getPaymentOrderUpgrade,
  getPaymentOrderAmount,
  getPaymentOrderStatus,
  getPaymentPay,
  getOrderList,
  getPaymentOrderCancel,
  getPaymentSubscribe,
  getPaymentOrderCheck,
  getPaymentOrderRenew,
  getPaymentOrderPrice,
  getPaymentOrderPriceShow
}
