import common from './common'
import app from './app'
import enums from './enums'
import rule from './rule'
import home from './home'
import detection from './detection'
import userCenter from './user-center'
import http from './http'
import permise from './permise'
import report from './report'
import taskhub from './taskhub'
import help from './help'
import notice from './notice'
import price from './price'
import tracked from './tracked'
import chat from './chat'
import other from './other'

const zh = {
  ...common,
  ...app,
  ...enums,
  ...rule,
  ...home,
  ...detection,
  ...userCenter,
  ...http,
  ...permise,
  ...report,
  ...taskhub,
  ...help,
  ...notice,
  ...price,
  ...tracked,
  ...chat,
  ...other
}
export default zh
