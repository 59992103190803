<template>
  <el-dialog
    custom-class="payResult"
    :title="$t('other.components.payTip')"
    v-model="show"
    width="500px"
    @close="close"
  >
    <span>
      {{ $t('other.components.place') }}
      <span class="countDown">0</span> {{ $t('account_manager.subscription.hour') }}
      <span class="countDown">{{minutes}}</span> {{ $t('account_manager.subscription.minute') }}
      <span class="countDown">{{seconds}}</span> {{ $t('other.components.notPatText1') }}
    </span>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="payed">{{ $t('other.components.payed') }}</el-button>
        <el-button @click="goHelp">{{ $t('other.components.payErr') }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import dayjs from 'dayjs'
const waitTime = 30 * 60 * 1000
export default {
  name: 'orderDialog',
  data () {
    return {
      minutes: 0,
      seconds: 0,
      isCancel: false,
      show: true
    }
  },
  props: {
    startTime: {
      type: String
    }
  },
  created () {
    this.getTime()
  },
  methods: {
    payed () {
      this.$emit('closeDialog', true)
    },
    close () {
      this.$emit('closeDialog')
    },
    goHelp () {
      window.open('https://mall.360.cn/help/show?id=helpcontent_5cee349b1e43e02edaf7000e7c48133c')
    },
    setCountDown () {
      this.minutes = Math.abs(Math.floor(this.timeDown / 60) + 1)
      this.seconds = Math.floor(Math.abs(this.timeDown) - this.minutes * 60)
      setTimeout(() => {
        this.getTime()
      })
    },
    getTime () {
      this.time = dayjs(this.startTime).valueOf()
      this.endTime = this.time + waitTime
      const now = new Date().getTime()
      this.timeDown = (now - this.endTime) / 1000
      if (this.timeDown < 0) {
        this.setCountDown()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.payResult) {
  // background: #21274a;
  .el-dialog__header  {
    text-align: left;
    // background: #242B52;
    // box-shadow: 0 1px 0 0 #2A315D;
    border-radius: 2px 2px 0 0;
    .el-dialog__title  {
      font-size: 14px;
      color: var( --color-unkown-text);
    }
  }
  .el-dialog__body  {
    font-size: 14px;
    color: var( --color-unkown-text);
    padding: 36px 34px 36px 34px;
    border-bottom: 1px solid #2A315D;
    .countDown  {
      font-size: 14px;
    }
  }
  .el-dialog__footer  {
    padding: 12px 20px 12px 20px;
    .el-button  {
      &:first-child  {
        // background: #537EF3;
        color: var(--color-text-1);
      }
      &:last-child  {
        // border: 1px solid #537EF3;
        background: none;
        font-size: 14px;
        // color: #537EF3;
      }
    }
  }
}
.countDown  {
  font-size: 20px;
  color: #cf605b;
  font-family: Verdana-Bold;
}
</style>
