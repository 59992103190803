export default {
  notice: {
    marked: 'Mark as read',
    deleteMessage: 'Delete message',
    receiveTime: 'Receiving time',
    messageType: 'Message type',
    messageTitle: 'Message title',
    deleteDesc: 'Message deletion cannot be recovered. Please confirm whether to delete the message?'
  }
}
