export default {
  docs: {
    title: '帮助文档',
    platform: '平台说明',
    introduction: '平台简介',
    api: '接口说明',
    api_explain: '接口简介',
    api_uselimit: '用户须知',
    api_list: '接口列表',
    submit: '提交检测分析任务',
    search: '搜索历史任务报告',
    get_submitlist: '查询历史任务列表',
    search_taskstatus: '查询任务分析状态',
    get_taskoverview: '查看任务报告内容',
    terms: '用户协议',
    privacy: '隐私政策',
    faq: '常见问题'
  },
  consulting: {
    title: '购买咨询',
    remain: '今日剩余咨询次数',
    name: {
      label: '联系人姓名',
      placeholder: '请输入联系人',
      null: '请输入联系人姓名',
      invalid: '联系人姓名只能包含汉字，字母，数字'
    },
    email: {
      label: '联系人邮箱',
      placeholder: '请输入邮箱',
      invalid: '邮箱不符合要求，请重新输入',
      length_limited: '邮箱长度不能大于30个字符'
    },
    phone: {
      label: '联系人手机',
      placeholder: '请输入手机号码',
      invalid: '手机号码不符合要求，请重新输入'
    },
    org: {
      label: '所属单位',
      placeholder: '请输入公司或组织名称',
      check: '请输入所属单位',
      invalid: '所属单位只能包含汉字，字母，数字'
    },
    count: '月样本分析量',
    count_invalid: '请输入0以上的数字',
    count_placeholder: '请输入每月需分析的样本数量',
    purpose: '咨询目的',
    purpose_invalid: '请选择咨询目的',
    pro: '咨询专业版',
    pro_plus: '咨询专业增强版',
    enterprise: '咨询企业版',
    local: '咨询本地沙箱',
    point_purchase: '咨询积分购买',
    renewal: '咨询订阅续费',
    needs: '咨询需求',
    needs_placeholder: '请输入咨询需求',
    no_remain: '您今日已没有提交次数',
    success: '联系信息已发送成功，我们将在2个工作日内与您取得联系'
  },
  feedback: {
    title: '提交反馈',
    remain: '今日剩余咨询次数',
    type: '反馈类型',
    analysis_result: '分析结果反馈',
    product_feature: '产品功能反馈',
    other_info: '其他信息反馈',
    content: '反馈内容',
    content_placeholder: '请输入反馈内容',
    type_invalid: '请选择反馈类型',
    content_invalid: '请输入反馈内容',
    success: '反馈已提交成功，感谢您的反馈',
    history: '反馈历史',
    delete: '删除反馈',
    id: '反馈ID',
    create_time: '提交时间',
    label_content: '内容',
    dialog: {
      title: '注意',
      attention: '删除反馈信息后该信息无法找回，请确认是否删除？'
    }
  },
  wiki: {
    title: '威胁百科',
    dev: '开发中，敬请期待！'
  }
}
