<template>
  <el-input
    :placeholder="$t('report.analyse.summary.search')"
    v-model.trim="input"
    @keyup.enter="keyDown"
    class="search-input"
  >
    <template #suffix>
      <i @click="keyDown">
        <svg-icon name="sousuo"></svg-icon>
      </i>
    </template>
  </el-input>
</template>

<script setup>
import { ref, defineExpose, defineEmits } from 'vue'

const input = ref('')
const emit = defineEmits(['keydown'])

const resetInput = () => {
  input.value = ''
  emit('keydown', input)
}

const keyDown = () => {
  emit('keydown', input)
}

defineExpose({
  resetInput
})
</script>

<style lang="scss" scoped>
.search-input {
  width: 100%;

  :deep(.el-input__suffix) {
    cursor: pointer;
    line-height: 30px;
    padding: 0 9px;
    right: 0;

    img {
      width: 18px;
      height: 18px;
      opacity: 0.7;
      transition-duration: 0.2s;
      vertical-align: middle;
    }
  }

  :deep(.el-input__inner:focus::placeholder), :deep(.el-input__inner:hover::placeholder) {
    // ft_color(color-header-input-placehold-focus);
  }

  :deep(.el-input__inner) {
    // bg_color(color-header-input-bg);
    // background: rgba(51, 62, 107, 0.5);
    // border: 1px solid #333E6B;
    height: 30px;
    font-size: 14px;
    line-height: 30px;
    // border-color: transparent;
    // ft_color(color-header-input-placehold-focus);

    &:focus, &:hover {
      &+.el-input__suffix {
        img {
          opacity: 1;
        }
      }
    }

    &::placeholder {
      // ft_color(color-header-input-placehold);
      // transition-duration: .2s;
    }
  }
}
</style>
