export default {
  tracked: {
    title: '威胁追踪',
    titleDesc: '360沙箱云每日处理百万级、高质量的威胁样本，海量安全数据的处理和运营能力有助于精准把握最新威胁态势，威胁类型涵盖恶意软件、APT 组织、已知漏洞和漏洞利用行为等，帮助您及早采取相应的防御措施。',
    opsCount: '运营样本数',
    blackCount: '黑样本数',
    networkCount: '网络活动',
    behaviorCount: '行为分类',
    kind: '种',
    maliciousSoftware: '恶意软件',
    aptOrg: 'APT 组织',
    timeScope: {
      year: '近一年',
      sixMonth: '近 6 个月',
      month: '近 1 个月',
      week: '近 1 个周'
    },
    taskCount: '任务数',
    m_on_m: '环比',
    hashCount: 'HASH 数量',
    ipCount: 'IP 数量',
    domainCount: '域名数量',
    showMore: '展示更多',
    close: '收起',
    date: '日期'
  }
}
