<template>
  <div class="standard-layout">
    <app-header />
    <div class="standard-layout__content" :class="{noFooter : noFooter}">
      <!-- <div class="standard-layout__sidebar" v-if="show">
        <sidebar />
      </div>
      <div
        :class="[
          'standard-layout__main',
          { sidebar: show || $route.name === 'statistics' }
        ]"
      >
        <router-view />
      </div> -->
      <div class="standard-layout__sidebar">
        <sidebar v-if="showSidebar" />
      </div>
      <div
        :class="[
          'standard-layout__main',
          { sidebar: show || $route.name === 'statistics' }
        ]"
      >
        <div class="router-view__wrap">
          <router-view />
          <app-footer v-if="!noFooter"></app-footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import AppHeader from 'components/app/app-header'
import AppFooter from 'components/app/app-footer'
import Sidebar from './standard-sidebar.vue'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
// import { useStore } from 'vuex'
// const store = useStore()
const route = useRoute()
// const isLogin = computed(() => store.state.login)
const path = ['help', 'report', 'report_running', 'taskstatus', 'public', 'history', 'teamtask', 'search', 'advanced', 'statistics']
const show = computed(() => path.includes(route.name))
const showSidebar = computed(() => route.name !== 'price' && route.name !== 'Detection')
const noFooterPath = ['report', 'report_running', 'price', 'taskstatus', 'teamtask', 'tracked']
const noFooter = computed(() => noFooterPath.includes(route.name))

</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
.standard-layout {
  width: 100%;
  min-width: 1330px;
  height: 100%;
  &__content {
    width: 100%;
    min-height: calc(100vh - $header-height);
    background-color: var(--color-bg-2);
    display: flex;
    // &.noFooter {
    //   min-height: calc(100vh - $header-height);
    // }
  }
  &__main {
    flex: 1;
    width: 100%;
    .router-view__wrap {
      height: calc(100vh - 56px);
      overflow-y: auto;
      overflow-y: overlay;
    }
    position: relative;
    box-sizing: border-box;
    &.sidebar {
      // margin-left: 60px;
      width: calc(100% - 180px);
    }
  }
  &__sidebar {
    background-color: var(--color-bg-1);
    box-shadow: 0 4px 35px 0 var(--color-bg-2);
    // position: fixed;
    position: relative;
    top: 0;
    left: 0;
    bottom: -60px;
    // width: 60px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // &:hover {
    //   width: 180px;
    // }
  }
}
</style>
