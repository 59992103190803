import {
  INTRANET_LOGIN_URL,
  QIHOO_LOGIN_URL,
  GITHUB_LOGIN_URL
} from '@/constants'

// 内网域账户登录
export const loginIntranet = () => {
  window.location.href = INTRANET_LOGIN_URL + window.location.href
}

// 360账户登录
export const loginQihoo = () => {
  window.location.href = QIHOO_LOGIN_URL + window.location.href
}

// Github账户登录
export const loginGithub = () => {
  window.location.href = GITHUB_LOGIN_URL + window.location.href
}
