export default {
  taskhub: {
    public: {
      title: '公开任务'
    },
    history: {
      title: '我的任务',
      err_hash: '异常哈希提交记录',
      all: '全部',
      file: '文件',
      public: '设为公开',
      private: '设为私有',
      share: '分享给团队',
      share_warn: '请选择要分享的任务',
      delete: {
        label: '删除任务',
        title: '确认删除任务',
        tips: '选中任务大于50个时仅删除任务列表前50个任务',
        warn: '任务删除操作不可恢复，任何人将不再可见所选任务报告的所有内容，请确认是否删除 {html} 个任务？'
      },
      failed: {
        title: '此任务分析失败',
        info: '此任务分析失败无法显示，是否删除此任务？'
      }
    },
    team: {
      title: '团队任务',
      manage: '团队管理',
      admin: '管理员',
      member: '成员',
      nodata: '该团队暂无任务',
      noteam: '暂无团队任务',
      all: '全部',
      file: '文件',
      remove: '移出团队',
      warning: '请选择要移除的任务',
      success: '移出成功'
    },
    search: {
      todetect: '暂未找到匹配您查询的 {type} 的任务，请试试{detect}功能?',
      detect: '任务提交',
      all: '全部任务',
      public: '公开任务',
      history: '我的任务',
      team: '团队任务',
      set_public: '设为公开',
      set_private: '设为私有',
      all_team: '所有团队',
      domain: '域名',
      keyword: '关键词',
      filesize: '文件大小',
      format: '文件格式',
      earliest: '最早检测时间',
      latest: '最新检测时间',
      task_num: '已检测任务数',
      viewed_num: '可查看任务数'
    },
    filter: {
      title: '筛选',
      time_start: '开始日期',
      separator: '至',
      time_end: '结束日期',
      lastweek: '最近一周',
      lastmonth: '最近一个月',
      last_three_month: '最近三个月',
      last_six_month: '最近半年',
      lastyear: '最近一年',
      submitter: '请选择提交人',
      search: '筛选',
      reset: '重置',
      selected: '已选条件',
      all: '全部',
      invalid: '筛选条件无效',
      label: {
        time: '时间范围',
        type: '任务类型',
        tag: '命中标签',
        os_env: '检测环境',
        status: '任务状态',
        score: '任务分值',
        submitter: '提交人'
      }
    },
    statistics: {
      title: '我的统计',
      day: '24小时',
      week: '7天',
      all: '总任务',
      done: '已完成',
      running: '检测中',
      waiting: '等待中',
      failed: '检测失败',
      type: '任务类型占比',
      exploit: '漏洞利用排行',
      result: '判定结果占比',
      cve: '已知漏洞排行',
      file_type: '总文件类型分布',
      malice: '恶意文件类型分布',
      behavior: '行为特征分布',
      environment: '检测环境统计',
      trend: '任务提交趋势',
      threat: '恶意检出趋势',
      tag: '命中标签排行',
      trend_tip: '任务数量：',
      trend_date_tip: '日期：',
      trend_yAxis: '任务数量',
      trend_xAxis: '时间',
      tag_xAxis: '类别',
      tag_yAxis: '数量'
    },
    static: '静态检测',
    nodata: '没有可展示的任务报告'
  }
}
