<template>
  <div class="sidebar-wrapper" :class="{ isOpen: menuOpenStatus }">
    <ul class="list-wrap" :class="{ isOpen: menuOpenStatus }">
      <li
        :class="['list-item', {active: item.value === $route.name || item.related == $route.name}, {hasChild: item.hasChild}, {isStatic: item.value === 'statistics'}]"
        v-for="item in _menus"
        :key="item.value"
        @click="handleClick(item.value)"
      >
        <!-- <span class="item-icon"><img :src="item.icon" :alt="item.label"></span> -->
        <!-- <svg-icon class="item-icon" :name="item.icon"></svg-icon>
        <span class="item-desc">{{$t(`taskhub_sidebar.${item.value}`)}}</span> -->
        <template v-if="menuOpenStatus">
          <svg-icon class="item-icon" :name="item.icon"></svg-icon>
          <span class="item-desc">{{ item.label }}</span>
        </template>
        <el-tooltip
          v-else
          :content="item.label"
          placement="right"
          :offset="10"
          :effect="$isDark() ? 'dark' : 'light'"
        >
          <div style="height: 100%; width: 100%;display: flex;align-items: center;">
            <svg-icon class="item-icon" :name="item.icon"></svg-icon>
          </div>
        </el-tooltip>
      </li>
      <li class="line" :style="{top: `${(_menus.length-1)*46 + 20}px`}" v-if="_menus.find(v => v.value === 'statistics')"></li>
      <!-- <li style="height: 23px;position: relative;"><span style="display: inline-block;position: absolute;top: 11px;left: 10px;right: 10px;height: 1px; background: rgb(60, 75, 141);"></span></li> -->
      <!-- <li
        :class="['list-item', {active: tabList[tabList.length - 1].value === $route.name}]"
        @click="handleClick(tabList[tabList.length - 1].value)"
      >
        <span class="item-icon"><img :src="tabList[tabList.length - 1].icon" :alt="tabList[tabList.length - 1].label"></span>
        <span class="item-desc">{{tabList[tabList.length - 1].label}}</span>
      </li> -->
    </ul>
    <div class="bottom">
      <el-popover
        placement="right-end"
        title="title"
        trigger="focus"
        popper-class="team-item__popper menu-set"
        v-model:visible="visible"
      >
        <template #reference>
          <!-- <el-tooltip
            :content="'设置'"
            placement="right"
            :offset="10"
            :disabled="menuOpenStatus"
          > -->

          <template v-if="menuOpenStatus">
            <div @click="setting" v-if="menuOpenStatus">
              <svg-icon class="item-icon" name="shezhi"></svg-icon>
              <span class="item-desc">{{$t('app.sidebar.setting.label')}}</span>
            </div>
          </template>
          <template v-else>
            <el-tooltip
              :content="$t('app.sidebar.setting.label')"
              placement="right"
              :offset="10"
              :effect="$isDark() ? 'dark' : 'light'"
            >
              <div @click="setting">
                <svg-icon class="item-icon" name="shezhi"></svg-icon>
              </div>
            </el-tooltip>
        </template>
          <!-- </el-tooltip> -->
        </template>
        <div class="child-item__wrap">
          <app-menu-set :menusList="menus.filter(v => v.name !== 'global')" @close="visible=false"></app-menu-set>
        </div>
      </el-popover>
      <el-tooltip
        :content="$t('action.expand')"
        placement="right"
        :offset="10"
        :disabled="menuOpenStatus"
        :effect="$isDark() ? 'dark' : 'light'"
      >
        <div @click="changeMenu">
          <svg-icon class="item-icon" :class="{menuOpenStatus, active}" name="zhankai2"></svg-icon>
          <span class="item-desc">{{ !menuOpenStatus ? $t('action.expand') : $t('action.fold')}}</span>
        </div>
      </el-tooltip>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useGetters } from '@/hooks/useVuex'
import AppMenuSet from 'components/app/app-menu-set'
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import emitter from '@/app/emitter.js'
import { t } from 'app/i18n'

const visible = ref(false)
const menuOpenStatus = ref(false)
const tabList = [
  {
    label: t('taskhub.search.public'),
    value: 'public',
    icon: 'gongkairenwu'
  },
  {
    label: t('taskhub.search.history'),
    value: 'history',
    related: 'err-hash',
    icon: 'woderenwu'
  },
  {
    label: t('taskhub.search.team'),
    value: 'teamtask',
    icon: 'tuanduirenwu',
    hasChild: false
  },
  {
    label: t('app.sidebar.global'),
    value: 'global',
    icon: 'quanzhanrenwu',
    hasChild: false
  },
  {
    label: t('app.sidebar.advanced'),
    value: 'advanced',
    related: 'search',
    icon: 'gaojisousuo'
  },
  {
    label: t('app.sidebar.statistics'),
    value: 'statistics',
    icon: 'wodetongji'
  }
]

const store = useStore()

// store.dispatch('user/getTaskhubMenus')

const login = computed(() => store.getters.isLogin)
const { menus } = useGetters('user', ['menus'])

const _menus = computed(() => {
  const names = menus.value.filter(v => v.show).map(v => v.name)
  return tabList.filter(i => names.includes(i.value))
})

const router = useRouter()
const handleClick = function (route) {
  if (route.name !== route) {
    router.push({ name: route })
  }
}

const setting = () => {
  if (login.value) {
    visible.value = true
  } else {
    emitter.emit('showLogin')
  }
}

const changeMenu = () => {
  menuOpenStatus.value = !menuOpenStatus.value
}
</script>

<style lang="scss" scoped>
.sidebar-wrapper {
  width: 60px;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  transition-duration: 0.5s;
  &.isOpen {
    width: 180px;
  }
}
  .list-wrap{
    margin: 0;
    padding: 0;
    .isStatic {
      margin-top:40px !important;
    }
    .line {
      width:80%;
      left:10%;
      display: inline-block;
      height:10px;
      position: absolute;
      z-index: -1;
      top: 10px;
      border-top: 1px solid var(--color-border-2);
    }
    .list-item {
      margin: 0;
      list-style: none;
      display: flex;
      overflow: hidden;
      align-items: center;
      cursor: pointer;
      height: 46px;
      line-height: 46px;
      .item-icon,
      .item-desc {
        font-size: 14px;
        color:var(--color-text-1);
        display: inline-block;
        flex-shrink: 0;
      }
      .item-icon {
        width: 16px;
        height: 18px;
        margin: 0 22px;
        line-height: 18px;
        font-size: 0;
      }
      &:hover {
        background: var(--sidebar-item-hover-bg);
      }
      &.active {
        background: #00ab7a;
        color: #fff;
        position: relative;
        svg {
          color: #fff;
        }
        span {
          color:#fff;
        }
        // &::before {
        //   content: '';
        //   width: 4px;
        //   height: 100%;
        //   background-image: linear-gradient(-58deg, #1995FF 0%, #3F7FFF 98%);
        //   display: inline-block;
        //   position: absolute;
        //   left: 0;
        //   top: 0;
        // }
        .item-icon {
          img {
            filter: brightness(1.5);
          }
        }
        &.hasChild {
          background: initial;
          &::before {
            width: 0;
          }
        }
      }
    }
  }
  .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    // width: 60px;
    padding-bottom: 20px;
    svg {
      transition: all 0.5s;
    }
    .menuOpenStatus {
      transform: rotateZ(180deg)
    }
    div {
      width: 100%;
      margin: 0;
      list-style: none;
      display: flex;
      overflow: hidden;
      align-items: center;
      cursor: pointer;
      height: 46px;
      line-height: 46px;
      &:hover {
        background: rgba(0,0,0,.2)
      }
      .item-icon {
        width: 16px;
        height: 18px;
        margin: 0 22px;
        line-height: 18px;
        font-size: 0;
        color:var(--color-text-1);
        display: inline-block;
        flex-shrink: 0;
        &.isOpen {
          transform: rotate(180deg);
        }
      }
      .item-desc {
        font-size: 14px;
        color:var(--color-text-1);
        display: inline-block;
        flex-shrink: 0;
      }
    }
  }
  // .bottom {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   padding-bottom: 20px;
  //   div {
  //     width: 100%;
  //     margin: 0;
  //     list-style: none;
  //     display: flex;
  //     overflow: hidden;
  //     align-items: center;
  //     cursor: pointer;
  //     height: 46px;
  //     line-height: 46px;
  //     &:hover {
  //       background: rgba(0,0,0,.2)
  //     }
  //     .item-icon {
  //       width: 16px;
  //       height: 18px;
  //       margin: 0 22px;
  //       line-height: 18px;
  //       font-size: 0;
  //       color: gray;
  //       display: inline-block;
  //       flex-shrink: 0;
  //     }
  //     .item-desc {
  //       font-size: 14px;
  //       color: gray;
  //       display: inline-block;
  //       flex-shrink: 0;
  //       position: relative;
  //     }
  //     .menu-set {
  //       // position: absolute;
  //       z-index: 2000;
  //       left: 200px;
  //     }
  //   }
  // }
</style>
