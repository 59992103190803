<template>
  <div class="app-lang" v-if="!isProduct" @click="handleCommand">
    <svg-icon class="app-lang__icon" :name="icon"></svg-icon>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { watchSwitchLang } from 'app/i18n'
import { watchSwitchTheme } from 'app/style'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { getItem, setItem } from '@/utils/storage'
import { useRoute } from 'vue-router'
const i18n = useI18n()
const route = useRoute()
const store = useStore()
const icon = ref('')
const set = () => {
  if (getItem('THEME') === 'dark') {
    icon.value = getItem('LANG') === 'en' ? 'a-yingwen-shense' : 'zhongwen-shense'
  } else {
    icon.value = getItem('LANG') === 'en' ? 'a-yingwen-qianse1' : 'a-zhongwen-qianse1'
  }
}
set()
watchSwitchLang((l) => {
  set()
})
watchSwitchTheme((l) => {
  set()
})
if (route.query.lang) {
  const lang = route.query.lang === 'en' ? 'en' : 'zh'
  setItem('LANG', lang)
  i18n.locale.value = lang
  store.commit('language/setLanguage', lang)
}
const isProduct = window.location.host === 'ata.360.net' || window.location.host === 'ata.360.cn'

function handleCommand() {
  const lang = getItem('LANG') === 'en' ? 'zh' : 'en'
  // i18n.locale.value = lang
  // store.commit('language/setLanguage', lang)
  setItem('LANG', lang)
  location.reload()
}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
.app-lang {
  &__icon {
    font-size: 24px;
    color: var(--color-text-2);
    cursor: pointer;
  }
}
:deep(.active) {
  color: var(--color-priamry);
}
</style>
