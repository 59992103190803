export default {
  tracked: {
    title: 'TRACING',
    titleDesc: '360 Sandbox Cloud processes millions of high-quality threat samples every day. The processing and operation capabilities of massive security data help to accurately grasp the latest threat trends. Threat types include malware, APT organizations, known vulnerabilities and exploits. etc. to help you take appropriate defensive measures as early as possible.',
    opsCount: 'operating samples',
    blackCount: 'black samples',
    networkCount: 'Network activity',
    behaviorCount: 'behavior classification',
    kind: 'types',
    maliciousSoftware: 'malware',
    aptOrg: 'APT organization',
    timeScope: {
      year: 'Nearly a year',
      sixMonth: 'last 6 months',
      month: 'last month',
      week: 'last week'
    },
    taskCount: 'Tasks',
    m_on_m: 'month-on-month',
    hashCount: 'HASH',
    ipCount: 'IP',
    domainCount: 'Domain',
    showMore: 'show More',
    close: 'Close',
    date: 'Date'
  }
}
