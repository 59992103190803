import request from 'app/http'

const postChatMessage = (params, signal) => {
  return request.post('/ai/chat', params, { signal })
  // return request({
  //   method: 'post',
  //   url: '/gpt/chat',
  //   data: params,
  //   signal
  // })
}

const cleanContext = () => {
  return request.post('/ai/chat/clean')
}

export {
  postChatMessage,
  cleanContext
}
