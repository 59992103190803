export default {
  rule: {
    invalid: '{field} format error',
    inputRequired: 'Please enter {field}',
    selectRequired: 'Please select {field}',
    lengthMin: '{field} must be longer than {min} characters',
    lengthMax: '{field} must be less than {max} characters long',
    lengthMinMax: '{field} must be {min} to {max} characters long',
    userName: '{field} must be 3-64 digits in Chinese, English, numbers, English periods, underscores, and underlines',
    password: '{field} must contain uppercase letters, lowercase letters, numbers, and special characters',
    email: 'Please enter a valid email address',
    phone: 'Please enter a valid phone number',
    verCode: '{field} must be a 4-digit letter or number',
    confirm: 'Must be consistent with {field}',
    url: '{field} must be a correct URL',
    link: '{field} must be a correct link',
    name: '{field} must be Chinese, English, numbers, English periods, dashes, underlines, and spaces in {min}-{max} digits',
    ip: '{field} must be the correct IP',
    domain: '{field} must be the correct DOMAIN',
    CIDR: '{field} must be the correct network segment address',
    mask: '{field} must be the correct mask address',
    tagRepeat: 'Tags cannot be repeated',
    ioc: '{field} must be the correct IOC',
    port: '{field} must be the correct port number [1-65535]'
  }
}
