export default {
  other: {
    components: {
      upgradeHunter: 'This feature requires upgrading to Pro Plus plan to unlock it.',
      upgradeHunterText1: 'More advanced configuration features for batch samples can only be unlocked by upgrading the service plan.',
      upgradeHunterText2: 'The entered key string does not meet the specified search type, please re-enter it.',
      errString: 'The entered key string does not meet the specified search type, please re-enter it.',
      notPayText: 'You have unpaid orders to be processed. Please go to the order record page to process them first.',
      goTo: 'Go to',
      payTip: 'Payment reminder',
      payed: 'Payment completed',
      payErr: 'Problem with payment',
      place: 'Please',
      notPatText1: 'Within seconds, complete the payment on the payment page of the newly opened web page. Please do not close the window before the payment is completed.',
      report: {
        featureInfo: 'Feature information',
        captchText: '360 Tianyu provides technical support',
        captchBtn: 'Click the button for security verification',
        captchText1: 'To proceed to the next step, please ',
        captchText2: 'Click here to log in',
        visitor: 'visitor',
        malwareGroup: 'Malware family group',
        commonExploits: 'Common exploits',
        ransomware: 'Ransomware behavior',
        evasion: 'Detect evasive behavior',
        known_vul: 'Known vulnerabilities',
        key_vul: 'Critical exploits',
        apt: 'APT organization',
        pending: 'in the line',
        scanning: 'Static',
        dynamic_pending: 'Scheduling',
        running: 'Dynamic',
        completed: 'Completed',
        runningErr: 'Failed',
        agreePay: 'Confirm',
        yuan: 'Yuan',
        agree: 'agree',
        inputText1: 'Please enter a multiple of 10',
        inputText2: 'Please enter a number between 10 and 5000',
        otherAmount: 'Other',
        selectRechargePlan: 'Choose a recharge plan',
        existingPoints: 'Existing points',
        pointsRecharge: 'Points recharge',
        agreePublic: 'Confirm submission of public task',
        agreePublicText: 'The current task will be a public task and can be viewed by any user who obtains the task link, and may appear in the recent "Public Tasks" list, posing a risk of personal privacy leakage. Please confirm whether to submit with public task permissions?',
        analysisReport: 'analysis report',
        urlName: 'URL name',
        urlRiskTip: '(URL may contain security risks, please click with caution)',
        reportUrl: 'Report url',
        qrcodeView: 'Scan the QR code to view',
        downloadTime: 'Download time',
        redemptionSuccessful: 'Redemption successful',
        redemptionDownload: 'Redeem download quota',
        noPoints: 'Not enough points',
        aboutPaid: 'Learn about paid benefits',
        exchangeText: 'The original points redeemed for professional version benefits will be invalid, and the unused time/quota will be converted into points and returned.',
        confirmRecharge: 'Confirm',
        rechargePoints: 'Recharge',
        stayTuned: 'Stay tuned',
        day: 'sky',
        allBenefits: 'Experience all the benefits',
        displayRights: 'Report content display rights',
        displayTime: 'Sample display time range'
      },
      common: {

      }
    },
    views: {
      permise: {
        filesize: 'The file size exceeds the limit of {count}mb. Upgrading to the Pro plan can unlock 50mb.',
        filenumber: 'If a single submission exceeds the limit of {count} samples, upgrading to the Pro Plus plan can unlock 20 single submissions.',
        secondnumber: 'The submission frequency exceeds the limit of {count} times/minute. Upgrade to the Pro plan to unlock 30 times/min.',
        dayTimes: 'The number of samples submitted today has exceeded the limit of {count} per day. Upgrading to the Pro plan can unlock 500 per day.',
        monthTimes: 'The number of samples submitted this month has exceeded the {count}/month limit. Upgrading to the Pro plan can unlock 15,000/mon.',
        filesize2: 'The file size exceeds the limit of {count}mb. Upgrading to the Pro plan can unlock 100mb.',
        filenumber2: 'If a single submission exceeds the limit of {count} samples, upgrading to the Pro Plus plan can unlock 40 single submissions.',
        secondnumber2: 'The submission frequency exceeds the limit of {count} times/minute. Upgrade to Pro Plus plan to unlock 60 times/minute.',
        dayTimes2: 'The number of samples submitted today has exceeded the {count}/day limit. Upgrading to the Pro Plus plan can unlock 5,000 samples/day.',
        monthTimes2: 'The number of samples submitted this month has exceeded the limit of {count}/mon. Upgrading to the Pro Plus plan can unlock 150,000/mon.',
        filesize3: 'File size exceeds 100 mb limit',
        filenumber3: 'single submission exceeds the {count} sample limit',
        secondnumber3: 'Submission frequency exceeds {count} times/min limit',
        dayTimes3: 'The number of samples submitted today has exceeded the {count}/day limit',
        monthTimes3: 'The number of samples submitted this month has exceeded the {count}/mon limit',
        uploadStatus1: 'file does not exist',
        uploadStatus2: 'File is empty',
        uploadStatus3: 'File read failed',
        uploadStatus4: 'File upload failed',
        uploadStatus5: 'File size exceeds limit',
        uploadStatus6: 'The number of tasks exceeds the limit',
        uploadStatus7: 'URL format error',
        uploadStatus8: 'URL domain name resolution failed',
        uploadStatus9: 'URL host access failed',
        uploadStatus10: 'URL page access failed',
        uploadStatus11: 'URL file download failed',
        uploadStatus12: 'URL file size exceeds limit',
        uploadStatus13: 'Hash file download failed',
        uploadStatus14: 'Hash file size exceeds limit',
        uploadStatus15: 'Task quota exceeded limit',
        uploadStatus16: 'hash value does not exist',
        uploadStatus17: 'Server internal error',
        uploadStatus18: 'unknown mistake',
        uploadStatus19: 'Submission Failed'
      },
      sendfileupload: {
        msg1: 'To continue adding files, please drag directly into this area, or click',
        sendfile: 'upload files',
        selected: 'chosen',
        sample: 'sample',
        total: 'total',
        confirm: 'Sure',
        noFolder: 'Disable uploading folders',
        nosamefile: 'It is forbidden to upload files with the same file name'
      },
      sendurlupload: {
        hashPleaceholder: 'Enter or paste MD5, SHA1 or SHA256 for detection',
        hashMsg1: 'There is no Hash in the list, please correct it and submit it.',
        hashMsg2: 'There is an invalid hash in the list, please correct it and submit it.',
        hashMsg3: 'It is forbidden to upload the same hash',
        urlPleaceholder: 'Enter or paste the URL. When submitting in batches, line breaks are required between multiple URLs.',
        urlMsg1: 'There is no URL in the list, please correct it and submit it.',
        urlMsg2: 'There is an invalid URL in the list, please correct it and submit it.',
        urlMsg3: 'It is forbidden to upload the same URL',
        netDelay: 'Network delay, please try again'
      },
      home: {
        detail: 'Details',
        taskNum: 'Number of tasks',
        selectedReports: 'Report Featured',
        inputMsg: 'The entered key string does not meet the specified search type, please re-enter it.',
        usertypeHash: 'The user type does not currently support the submission of hash tasks.',
        noemptyFolder: 'Disable uploading of empty files',
        numberOfNet: ' Number of network connection events: '
      },
      other: {
        loggedAccount: 'Already logged in account',
        openClientLogin: 'Please open the client to complete the login',
        loginSandbox: 'Open the 360 ATA Cloud Client',
        createKey: 'You need to first go to the "Account Information" page to generate the API KEY',
        generateKey: 'Go to generate API KEY',
        isYourAccount: 'Not your account?',
        switchAccount: 'Switch account'
      },
      report: {
        nomal: 'normal',
        malice: 'malicious',
        pemsg: 'For PE samples, machine learning detection model based on dynamic behavioral metadata',
        threatProbability: 'Threat probability',
        machineLearningModel: 'Machine learning detection model based on sample dynamic behavior determination indicators'
      },
      reportPdf: {
        behaviorJudge: 'Behavior Detection',
        testResult: 'Technique Detection',
        allPoints: 'Show all techniques',
        name: 'Name',
        tactic: 'Tactics',
        permission: 'Permissions',
        illustrate: 'Explain',
        source: 'Source',
        index: 'Indicators',
        events: 'event(s)',
        runScreenshot: 'Running Screenshot',
        screenShot: 'Screenshot',
        releaseFiles: 'Dropped Files',
        freeMemory: 'Memory',
        threatIndicator: 'Indicators',
        loading: 'loading...',
        nopermission: 'No permission to view the content of this task report',
        noTask: 'Task detection failed',
        privatePermission: 'This task is private and you do not have access permissions',
        systemError: 'System error',
        taskFailed: 'Task detection failed',
        pointDetection: 'Technique Detection',
        exist: 'Exists',
        attckPoints: 'ATT&CK technique(s)',
        mitreattPoints: 'MITRE ATT&CK™ Technique',
        netOverview: 'Network Overview',
        behaviorRelation: 'Behavioral Diagram',
        processTree: 'Process Tree',
        accessDomain: 'Domains',
        accessHost: 'Hosts',
        accessHttp: 'HTTP',
        accessHttps: 'HTTPS',
        switchMode: 'Switch to simple mode',
        switchFullMode: 'Switch to full mode',
        amplify: 'Zoom in',
        reduce: 'Zoom out',
        refresh: 'Reset',
        behaviorInfo: 'Behavior Info',
        processId: 'Process ID',
        parentProcessId: 'Parent ID',
        userName: 'Username',
        integrityLevel: 'Integrity',
        startTime: 'First seen',
        processType: 'Process type',
        fileTree: 'File path',
        userSid: 'User SID',
        commandLine: 'Command line',
        suspicious: 'Suspicious',
        noThreat: 'Clean',
        startProcess: 'START',
        fileOrNetBehavior: 'FILE / NETWORK BEHAVIORS',
        otherProcess: 'OTHER PROCESS',
        fileInfo: 'File info',
        fileConent: 'File data',
        fileName: 'File name',
        fileSize: 'File size',
        bity: 'Bytes',
        fileType: 'File type',
        fileFormat: 'File format',
        notDetected: 'Clean',
        noRisk: 'No risk',
        engineJuedment: 'Engine',
        contextInfo: 'Context',
        staticEngineJudgment: 'Engine',
        contextProcess: 'Context PID',
        targetProcess: 'Target PID',
        memoryAddress: 'Memory Address',
        memorySize: 'Memory size',
        memoryPrepery: 'Protection',
        staticJudge: 'Static Detection',
        engineName: 'Engine',
        detectionResult: 'Result',
        riskUrl: 'The URL may have security risks, please click with caution',
        targetPlatform: 'Platform',
        taskType: 'Task type',
        testEnvironment: 'Environment',
        submitTime: 'Submission',
        duration: 'Duration',
        running: 'Dynamic',
        taskReport: 'Report',
        sandbox: '360 ATA Cloud',
        staticDetection: 'Static analysis',
        position: '-bit',
        safeBrainSearch: 'TI Search',
        intelligenceIndicator: 'Indicators',
        intelligenceType: 'IOC type',
        threatType: 'Threaten',
        credibilityIndex: 'Confidence',
        severity: 'Risk',
        intelligenceStatus: 'Status',
        threatOrg: 'Organization',
        threatFamily: 'Family',
        threatTags: 'Tags',
        safe: 'Safety'
      },
      taskHub: {
        visitor: 'visitor',
        free: 'Free plan',
        professional: 'Pro plan',
        professionalEdition: 'Pro Plus plan',
        mounth3: 'last 3 months',
        mounth6: 'last 6 months',
        mounth12: 'Nearly a year',
        taskStatus: 'Status',
        all: 'All',
        comlpleted: 'Completed',
        queuing: 'in the line',
        testing: 'Detecting',
        scanning: 'Under static detection',
        dynamicing: 'Under dynamic monitoring',
        tested: 'Completed',
        testFailed: 'Detection failed',
        publickTask: 'Public task',
        file: 'File',
        familytop10: 'Top 10 malicious families',
        category: 'Category',
        number: 'Number',
        orgtop10: 'Top 10 hit threat organizations'
      }
    },
    utils: {
      needLogin: 'Login required',
      needUpgrade: 'Requires upgrade to Pro plan or Pro Plus plan ',
      month: 'moon',
      day: 'day'
    },
    tip: 'hint',
    router: {
      homeTitle: '360 ATA Cloud - Professional advanced threat analysis platform, insight into each behavior of malwares',
      trackedTitle: '360 ATA Cloud - TRACKER',
      chatTitle: 'Introduction to ATA Cloud Assistant - 360 ATA Cloud',
      detectTitle: 'SUBMIT- 360 ATA Cloud',
      priceTitle: 'PRICING - 360 ATA Cloud',
      taskListTitle: 'REPORTS - 360 ATA Cloud',
      errHashTitle: 'Abnormal hash submission record-360 ATA Cloud',
      taskhubTitle: 'REPORTS - 360 ATA Cloud',
      publicTaskTitle: 'Public task - 360 ATA Cloud',
      myTaskTitle: 'History - 360 ATA cloud',
      teamTaskTitle: 'Team Task-360 ATA Cloud',
      advancedSearchTitle: 'Advanced Search-360 ATA Cloud',
      statisticsTitle: 'Statistics - 360 ATA Cloud',
      introductionTitle: 'Platform Introduction-360 ATA Cloud',
      termsTitle: 'User Agreement-360 ATA Cloud',
      privacyTitle: 'Privacy Policy - 360 ATA Cloud',
      faqTitle: 'FAQ - 360 ATA Cloud',
      apiIntroductionTitle: 'Interface Introduction - 360 ATA Cloud',
      userLimitTitle: 'User Instructions - 360 ATA Cloud',
      submitTaskTitle: 'Submit detection analysis task-360 ATA Cloud',
      searchTaskTitle: 'Search history task report-360 ATA Cloud',
      getsubmitlistTitle: 'Query historical task list - 360 ATA Cloud',
      gettaskstatusTitle: 'Query task analysis status - 360 ATA Cloud',
      gettaskoverviewTitle: 'View the task report content - 360 ATA Cloud',
      wikiTitle: 'Threat Encyclopedia - 360 ATA Cloud',
      consultingTitle: 'Feedback - 360 ATA Cloud',
      feedbackTitle: 'Submit feedback - 360 ATA Cloud',
      myMessageTitle: 'My message - 360 ATA Cloud',
      messageSettingTitle: 'Message settings - 360 ATA Cloud',
      accountTitle: 'Account information - 360 ATA Cloud',
      pointTitle: 'My Points - 360 ATA Cloud',
      subscriptionTitle: 'My Plan - 360 ATA Cloud',
      confimOrderTitle: 'Confirm order - 360 ATA Cloud',
      orderTitle: 'Order - 360 ATA Cloud',
      inviteteamTitle: 'The invitation I received - 360 ATA Cloud',
      ownerteamTitle: 'My Team - 360 ATA Cloud'
    },
    http: {
      errType: 'The content of the submitted file name does not comply with the regulations. Please modify it and try again.',
      errDownloadPdf: 'Report download frequency is too high, please try again later',
      serverErr: 'The server asked a question',
      httpCodeErr1: 'Downloading more than {0} PDFs/{1} limit {2}',
      httpCodeErr2: 'Submission frequency exceeds {0} times/{1} limit {2}',
      httpCodeErr3: 'Downloaded {0} more than {1}/{2}limit{3}',
      httpCodeErr4: 'The number of samples submitted for this {0} has exceeded {1}/{2} limit {3}',
      httpCodeErr5: 'This {0} view task report exceeds {1} times/{2} limit {3}',
      httpCodeErr6: 'Search frequency exceeds {0} times/{1} limit {2}',
      httpCodeErr7: 'Downloads exceeded {0}/{1} limit{2}',
      httpCodeErr8: 'The number of searches for this {0} has exceeded {1} times/{2} limit {3}',
      httpCodeErrUpgrade1: ', Upgrade to {0} to unlock {1}/{2}',
      httpCodeErrUpgrade2: ', Upgrade to {0} to unlock {1} times/{2}'
    },
    vnc: {
      prepare: 'VNC链接',
      runing: '连接中',
      closed: '连接已断开',
      mouseControl: '键鼠控制',
      sendKey: '发送 {0} 按键',
      closeConnect: '断开连接',
      dynamicEnd: '动态运行阶段已结束。',
      connectFailed: '连接失败',
      reconnect: '重新连接',
      connectCanceled: '连接已取消',
      connecting: '正在连接...',
      stopConnect: '停止连接'
    },
    hunting: {

    },
    global: {

    }
  }
}
