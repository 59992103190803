import dayjs from 'dayjs'
import rt from 'dayjs/plugin/relativeTime'
// 语言包
import 'dayjs/locale/zh-cn'
// 加载相对时间插件
dayjs.extend(rt)

export function dateFormat(val, format = 'YYYY-MM-DD') {
  if (isNaN(val)) {
    return val
  }
  val = parseInt(val)
  return dayjs(val).format(format)
}

export function dateRelative(val) {
  if (!isNaN(val)) {
    val = parseInt(val)
  }
  return dayjs().to(dayjs(val))
}
