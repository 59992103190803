export default {
  other: {
    components: {
      upgradeHunter: '该功能需要升级专业增强版来解锁。',
      upgradeHunterText1: '只能通过升级服务方案来解锁批量样本的更高级配置功能。',
      upgradeHunterText2: '若想使用积分兑换权益，请逐个提交样本。',
      errString: '输入的关键字符串不符合规定的搜索类型, 请重新输入',
      notPayText: '您有未支付订单待处理，请先前往订单记录页面进行处理',
      goTo: '前往',
      payTip: '付款提示',
      payed: '已完成付款',
      payErr: '付款遇到问题',
      place: '请您在',
      notPatText1: '秒内，在新开的网页上支付页面完成支付，支付完成前请不要关闭该窗口',
      report: {
        featureInfo: '特征信息',
        captchText: '三六零天御提供技术支持',
        captchBtn: '点击按钮进行安全验证',
        captchText1: '想要进行下一步，请',
        captchText2: '点击这里去登录',
        visitor: '访客',
        malwareGroup: '恶意软件家族群',
        commonExploits: '普通漏洞利用',
        ransomware: '勒索病毒行为',
        evasion: '检测逃避行为',
        known_vul: '已知漏洞',
        key_vul: '关键漏洞利用',
        apt: 'APT 组织',
        pending: '排队中',
        scanning: '静态检测中',
        dynamic_pending: '调度中',
        running: '动态检测中',
        completed: '检测完成',
        runningErr: '检测失败',
        agreePay: '确认并支付',
        yuan: '元',
        agree: '同意',
        inputText1: '请输入10的倍数',
        inputText2: '请输入10到5000以内的数字',
        otherAmount: '其他数额',
        selectRechargePlan: '选择充值方案',
        existingPoints: '现有积分',
        pointsRecharge: '积分充值',
        agreePublic: '确认提交公开任务',
        agreePublicText: '当前任务将作为公开任务，可以被获得此任务链接的任何用户查看，并有可能出现在近期的“公开任务”列表中，造成个人隐私泄漏的风险。请确认是否以公开任务权限提交？',
        analysisReport: '分析报告',
        urlName: 'URL名称',
        urlRiskTip: '（URL可能存在安全风险，请谨慎点击）',
        reportUrl: '报告链接',
        qrcodeView: '扫码查看',
        downloadTime: '下载时间',
        redemptionSuccessful: '兑换成功',
        redemptionDownload: '兑换下载配额',
        noPoints: '积分不足',
        aboutPaid: '了解付费权益',
        exchangeText: '原先积分兑换专业版权益将失效, 未用完的时长/配额将折合成积分返还',
        confirmRecharge: '确认兑换',
        rechargePoints: '充积分',
        stayTuned: '敬请期待',
        day: '天',
        allBenefits: '体验全部权益',
        displayRights: '报告内容展示权益',
        displayTime: '样本展示时间范围'
      },
      common: {

      }
    },
    views: {
      permise: {
        filesize: '文件大小超出{count}M限制，升级到专业版可解锁 50M',
        filenumber: '单次提交超过 {count}个样本 限制，升级到专业版可解锁 单次提交20个',
        secondnumber: '提交频率超出 {count}次/分钟 限制，升级到专业版可解锁 30次/分钟',
        dayTimes: '本日提交样本数量已超过 {count}个/日 限制，升级到专业版可解锁 500个/日',
        monthTimes: '本月提交样本数量已超过 {count}个/月 限制，升级到专业版可解锁 15000个/月',
        filesize2: '文件大小超出{count}M限制，升级到专业增强版可解锁 100M',
        filenumber2: '单次提交超过 {count}个样本 限制，升级到专业增强版可解锁 单次提交40个',
        secondnumber2: '提交频率超出 {count}次/分钟 限制，升级到专业增强版可解锁 60次/分钟',
        dayTimes2: '本日提交样本数量已超过 {count}个/日 限制，升级到专业增强版可解锁 5000个/日',
        monthTimes2: '本月提交样本数量已超过 {count}个/月 限制，升级到专业增强版可解锁 150000个/月',
        filesize3: '文件大小超出100M限制',
        filenumber3: '单次提交超过 {count}个样本 限制',
        secondnumber3: '提交频率超出 {count}次/分钟 限制',
        dayTimes3: '本日提交样本数量已超过 {count}个/日 限制',
        monthTimes3: '本月提交样本数量已超过 {count}个/月 限制',
        uploadStatus1: '文件不存在',
        uploadStatus2: '文件为空',
        uploadStatus3: '文件读取失败',
        uploadStatus4: '文件上传失败',
        uploadStatus5: '文件大小超出限制',
        uploadStatus6: '任务数量超出限制',
        uploadStatus7: 'URL格式错误',
        uploadStatus8: 'URL域名解析失败',
        uploadStatus9: 'URL主机访问失败',
        uploadStatus10: 'URL页面访问失败',
        uploadStatus11: 'URL文件下载失败',
        uploadStatus12: 'URL文件大小超出限制',
        uploadStatus13: '哈希文件下载失败',
        uploadStatus14: '哈希文件大小超出限制',
        uploadStatus15: '任务配额超出限制',
        uploadStatus16: 'hash值不存在',
        uploadStatus17: '服务器内部错误',
        uploadStatus18: '未知错误',
        uploadStatus19: '提交失败'
      },
      sendfileupload: {
        msg1: '若要继续添加文件，请直接拖拽入本区域，或点击',
        sendfile: '上传文件',
        selected: '已选择',
        sample: '样本',
        total: '共计',
        confirm: '确定',
        noFolder: '禁止上传文件夹',
        nosamefile: '禁止上传相同文件名文件'
      },
      sendurlupload: {
        hashPleaceholder: ' 输入或粘贴 MD5、SHA1 或 SHA256 以进行检测',
        hashMsg1: '列表中无Hash，请更正后提交',
        hashMsg2: '列表中有无效hash，请更正后提交',
        hashMsg3: '禁止上传相同hash',
        urlPleaceholder: ' 输入或粘贴URL，批量提交时，多个URL之间需换行',
        urlMsg1: '列表中无URL，请更正后提交',
        urlMsg2: '列表中有无效URL，请更正后提交',
        urlMsg3: '禁止上传相同url',
        netDelay: '网络延时，请重试'
      },
      home: {
        detail: '详情',
        taskNum: '任务数',
        selectedReports: '报告精选',
        inputMsg: '输入的关键字符串不符合规定的搜索类型, 请重新输入',
        usertypeHash: '⽤⼾类型暂不⽀持哈希任务的提交',
        noemptyFolder: '禁止上传空文件',
        numberOfNet: '网络连接事件数: '
      },
      other: {
        loggedAccount: '已登录账号',
        openClientLogin: '请打开客户端以完成登录',
        loginSandbox: '打开 360沙箱云 客户端',
        createKey: '需要先前往"账户信息"页面生成 API KEY',
        generateKey: '去生成 API KEY',
        isYourAccount: '不是你的账号？',
        switchAccount: '切换账号'
      },
      report: {
        nomal: '正常',
        malice: '恶意',
        pemsg: '针对 PE 类样本, 基于动态行为元数据的机器学习检测模型',
        threatProbability: '威胁概率',
        machineLearningModel: '基于样本动态行为判定指标的机器学习检测模型'
      },
      reportPdf: {
        behaviorJudge: '行为判定',
        testResult: '技术检测结果',
        allPoints: '显示全部技术点',
        name: '名称',
        tactic: '战术',
        permission: '所需权限',
        illustrate: '说明',
        source: '来源',
        index: '指标',
        events: '个事件',
        runScreenshot: '运行截图',
        screenShot: '截图',
        releaseFiles: '释放文件',
        freeMemory: '释放内存',
        threatIndicator: '威胁指标',
        loading: '数据加载中...',
        nopermission: '无权限查看此任务报告的内容',
        noTask: '任务不存在',
        privatePermission: '该任务为私有数据，您无访问权限',
        systemError: '系统错误',
        taskFailed: '任务检测失败',
        pointDetection: '技术点检测',
        exist: '存在',
        attckPoints: '个 ATT&CK 技术点',
        mitreattPoints: 'MITRE ATT&CK™ 技术点',
        netOverview: '网络概要',
        behaviorRelation: '行为关系图',
        processTree: '进程树',
        accessDomain: '访问域名',
        accessHost: '访问主机',
        accessHttp: '访问HTTP',
        accessHttps: '访问HTTPS',
        switchMode: '切换简单模式',
        switchFullMode: '切换完整模式',
        amplify: '放大',
        reduce: '缩小',
        refresh: '刷新',
        behaviorInfo: '行为信息',
        processId: '进程 ID',
        parentProcessId: '父进程 ID',
        userName: '用户名',
        integrityLevel: '完整性等级',
        startTime: '启动时间',
        processType: '进程类型',
        fileTree: '文件路径',
        userSid: '用户 SID',
        commandLine: '命令行',
        suspicious: '可疑',
        noThreat: '未发现威胁',
        startProcess: '启动进程',
        fileOrNetBehavior: '文件或网络行为',
        otherProcess: '其他进程',
        fileInfo: '文件信息',
        fileConent: '文件内容',
        fileName: '文件名称',
        fileSize: '文件大小',
        bity: '字节',
        fileType: '文件类型',
        fileFormat: '文件格式',
        notDetected: '未检出',
        noRisk: '无风险',
        engineJuedment: '引擎判定',
        contextInfo: '上下文信息',
        staticEngineJudgment: '静态引擎判定',
        contextProcess: '上下文进程',
        targetProcess: '目标进程',
        memoryAddress: '内存地址',
        memorySize: '内存大小',
        memoryPrepery: '内存属性',
        staticJudge: '静态判定',
        engineName: '引擎名称',
        detectionResult: '检出结果',
        riskUrl: 'URL可能存在安全风险，请谨慎点击',
        targetPlatform: '目标平台',
        taskType: '任务类型',
        testEnvironment: '检测环境',
        submitTime: '提交时间',
        duration: '持续时间',
        running: '动态检测',
        taskReport: '任务报告',
        sandbox: '360沙箱云',
        staticDetection: '静态检测',
        position: '位',
        safeBrainSearch: '安全大脑搜索',
        intelligenceIndicator: '情报指标',
        intelligenceType: '情报类型',
        threatType: '威胁类型',
        credibilityIndex: '可信指数',
        severity: '严重程度',
        intelligenceStatus: '情报状态',
        threatOrg: '威胁组织',
        threatFamily: '威胁家族',
        threatTags: '威胁标签',
        safe: '安全'
      },
      taskHub: {
        visitor: '访客',
        free: '免费版',
        professional: '专业版',
        professionalEdition: '专业增强版',
        mounth3: '近3个月',
        mounth6: '近6个月',
        mounth12: '近12个月',
        taskStatus: '任务状态',
        all: '全部',
        comlpleted: '已完成',
        queuing: '排队中',
        testing: '检测中',
        scanning: '静态检测中',
        dynamicing: '动态监测中',
        tested: '检测完成',
        testFailed: '检测失败',
        publickTask: '公开任务',
        file: '文件',
        familytop10: '恶意家族 Top10',
        category: '类别',
        number: '数量',
        orgtop10: '命中威胁组织Top 10'
      }
    },
    utils: {
      needLogin: '需要登录',
      needUpgrade: '需升级到专业版或专业增强版',
      month: '月',
      day: '日'
    },
    tip: '提示',
    router: {
      homeTitle: '360沙箱云 - 专业的高级威胁分析平台，洞悉恶意样本每一行为',
      trackedTitle: '360沙箱云 - 威胁跟踪',
      chatTitle: '沙箱云助手简介 - 360沙箱云',
      detectTitle: '提交分析 - 360沙箱云',
      priceTitle: '升级服务 - 360沙箱云',
      taskListTitle: '任务列表 - 360沙箱云',
      errHashTitle: '异常哈希提交记录 - 360沙箱云',
      taskhubTitle: '任务中心 - 360沙箱云',
      publicTaskTitle: '公开任务 - 360沙箱云',
      myTaskTitle: '我的任务 - 360沙箱云',
      teamTaskTitle: '团队任务 - 360沙箱云',
      advancedSearchTitle: '高级搜索 - 360沙箱云',
      statisticsTitle: '我的统计 - 360沙箱云',
      introductionTitle: '平台简介 - 360沙箱云',
      termsTitle: '用户协议 - 360沙箱云',
      privacyTitle: '隐私政策 - 360沙箱云',
      faqTitle: '常见问题 - 360沙箱云',
      apiIntroductionTitle: '接口简介 - 360沙箱云',
      userLimitTitle: '用户须知 - 360沙箱云',
      submitTaskTitle: '提交检测分析任务 - 360沙箱云',
      searchTaskTitle: '搜索历史任务报告 - 360沙箱云',
      getsubmitlistTitle: '查询历史任务列表 - 360沙箱云',
      gettaskstatusTitle: '查询任务分析状态 - 360沙箱云',
      gettaskoverviewTitle: '查看任务报告内容 - 360沙箱云',
      wikiTitle: '威胁百科 - 360沙箱云',
      consultingTitle: '购买咨询 - 360沙箱云',
      feedbackTitle: '提交反馈 - 360沙箱云',
      myMessageTitle: '我的消息 - 360沙箱云',
      messageSettingTitle: '消息设置 - 360沙箱云',
      accountTitle: '账户信息 - 360沙箱云',
      pointTitle: '我的积分 - 360沙箱云',
      subscriptionTitle: '我的订阅 - 360沙箱云',
      confimOrderTitle: '确认订单 - 360沙箱云',
      orderTitle: '订单记录 - 360沙箱云',
      inviteteamTitle: '我收到的邀请 - 360沙箱云',
      ownerteamTitle: '我的团队 - 360沙箱云'
    },
    http: {
      errType: '提交的文件名内容不合规，请修改后重试',
      errDownloadPdf: '下载报告频率过高，请稍后重试',
      serverErr: '服务器提了一个问题',
      httpCodeErr1: '下载PDF超过{0}个/{1}限制{2}',
      httpCodeErr2: '提交频率超出{0}次/{1}限制{2}',
      httpCodeErr3: '下载{0}超过{1}个/{2}限制{3}',
      httpCodeErr4: '本{0}提交样本数量已超过{1}个/{2}限制{3}',
      httpCodeErr5: '本{0}查看任务报告超出{1}次/{2}限制{3}',
      httpCodeErr6: '搜索频率超出{0}次/{1}限制{2}',
      httpCodeErr7: '下载超过{0}个/{1}限制{2}',
      httpCodeErr8: '本{0}搜索数量已超过{1}次/{2}限制{3}',
      httpCodeErrUpgrade1: ', 升级到{0}可解锁{1}个/{2}',
      httpCodeErrUpgrade2: ', 升级到{0}可解锁{1}次/{2}'
    },
    vnc: {
      prepare: 'VNC链接',
      runing: '连接中',
      closed: 'VNC链接已断开',
      end: 'VNC链接已结束',
      mouseControl: '键鼠控制',
      sendKey: '发送 {0} 按键',
      closeConnect: '断开连接',
      dynamicEnd: '动态运行阶段已结束。',
      connectFailed: '连接失败',
      reconnect: '重新连接',
      connectCanceled: '连接已取消',
      connecting: '正在连接...',
      stopConnect: '停止连接'
    },
    hunting: {

    },
    global: {

    }
  }
}
