import * as enums from 'enums'
import { t } from 'app/i18n'

/**
 * 获取枚举列表
 * @param {*} enumKey
 * @returns
 */
export function enumList(enumKey) {
  const enumObj = enums[enumKey]
  const list = []
  for (const key in enumObj) {
    list.push({
      id: enumObj[key],
      name: t(`${enumKey}.${key}`)
    })
  }
  return list
}

/**
 * 获取枚举值
 * @param {*} enumKey
 * @param {*} enumCode
 * @returns
 */
export function enumValue(enumKey, enumCode) {
  const enumObj = enums[enumKey]
  const val = ''
  for (const key in enumObj) {
    if (key === enumCode) {
      return enumObj[key]
    }
  }
  return val
}

/**
 * 获取枚举名称
 * @param {*} enumKey
 * @param {*} enumId
 * @returns
 */
export function enumName(enumKey, enumId) {
  const enumObj = enums[enumKey]
  const name = ''
  for (const key in enumObj) {
    if (enumObj[key] === enumId) {
      return t(`${enumKey}.${key}`)
    }
  }
  return name
}

export default (app) => {
  app.config.globalProperties.$enums = enums
  app.config.globalProperties.$enumList = enumList
  app.config.globalProperties.$enumValue = enumValue
  app.config.globalProperties.$enumName = enumName
}
