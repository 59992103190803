<template>
  <div class="rechange">
    <el-dialog width="840px" v-model="visible" @close="close" :append-to-body="false" custom-class="rechange-dialog">
      <div class="rechange-title">
        <img src="@/assets/img/rechange.png">
        <span>{{ $t('other.components.report.pointsRecharge') }}</span>
      </div>
      <div class="rechange-msg">
        <span class="rechange-msg__span1">{{ $t('other.components.report.existingPoints') }}</span>
        <span class="rechange-msg__span2">{{scoreInfo && scoreInfo.current_score}}
          <img style="width: 14px;" src="@/assets/img/integral.svg"></span>
        <div class="rechange-msg__span3">{{ $t('other.components.report.selectRechargePlan') }}：
          <span class="rechange-msg__span4">*</span></div>
      </div>
      <div class="rechange-list">
        <div class="rechange-list__item" :class="{'rechange-list__itemActive': itemActive === index}" @click="itemClick(index)" v-for="(item, index) in listData" :key="item">
          {{item}}
          <img style="width: 14px;" src="@/assets/img/integral.svg">
          <img class="rechange-list__item--active" v-if="itemActive === index" src="@/assets/img/rechangeItemActive.svg">
        </div>
        <div class="rechange-list__item rechange-list__item--custom" :class="{'rechange-list__itemActive': itemActive === 5}"  @click="itemClick(5)">
          <el-input class="rechange-list__item--input" :class="{'rechange-list__item--inputErr': itemActive === 5 && customCount % 10 !== 0}" :placeholder="$t('other.components.report.otherAmount')" type="number" :controls="false" v-model="customCount" @input="changeMoney"></el-input>
          <span class="rechange-list__item--inputErrText" v-if="itemActive === 5 && (customCount > 5000 || customCount < 10)">
            <!-- 请输入10到5000以内的数字 -->
            {{ $t('other.components.report.inputText2') }}
          </span>
          <span class="rechange-list__item--inputErrText" v-else-if="itemActive === 5 && (customCount % 10 !== 0)">
            <!-- 请输入10的倍数 -->
            {{ $t('other.components.report.inputText1') }}
          </span>
          <img style="width: 14px;" src="@/assets/img/integral.svg">
          <img class="rechange-list__item--active" v-if="itemActive === 5" src="@/assets/img/rechangeItemActive.svg">
        </div>
      </div>
      <div class="rechange-clause">
        <!-- <span class="rechange-clause__label">服务协议：</span>
        <span class="rechange-clause__label--after">*</span> -->
        <el-checkbox class="rechange-clause__checkbox" v-model="isChecked">
          <!-- 同意 -->
          {{ $t('other.components.report.agree') }}
        </el-checkbox>
        <span class="rechange-clause__checkbox--click" @click="open('/help/docs/terms')">{{ $t('detection.user') }}</span>
      </div>
      <div class="rechange-line"></div>
      <div class="rechange-money">
        ¥ {{countMoney}}
        <!-- <span class="rechange-money__after">{{ $t('other.components.report.yuan') }}</span> -->
      </div>
      <div class="rechange-btn" :class="{'rechange-btn--disabled': btnDisabled}" @click="toPay">{{ $t('other.components.report.agreePay') }}</div>
    </el-dialog>
    <!-- 确认是否支付的弹窗 -->
    <order-dialog v-if="orderDialogShow" @closeDialog="closeOrderDialog" :startTime="nowTime"></order-dialog>
    <!-- 订单重复跳转订单的弹窗 -->
    <to-order-dialog @closeDialog="closeToOrderDialog" v-if="showToOrderLog"></to-order-dialog>
  </div>
</template>

<script>
import { getPaymentOrderAdd, getPaymentPay, getOrderList } from 'services/order.js'
import orderDialog from '@/components/order/dialog1.vue'
import dayjs from 'dayjs'
import { mapState, mapMutations } from 'vuex'
import toOrderDialog from '@/components/order/dialog.vue'
import { t } from 'app/i18n'

export default {
  components: {
    orderDialog,
    toOrderDialog
  },
  data () {
    return {
      visible: false,
      isChecked: false,
      listData: [
        100, 200, 500, 1000, 2000
      ],
      // 自定义的积分数
      customCount: undefined,
      itemActive: undefined,
      // 最后统计的钱数
      countMoney: 0,
      // 防抖作用
      isPay: false,
      // 确认支付的弹窗
      orderDialogShow: false,
      // 重复跳转到订单的弹窗
      showToOrderLog: false
    }
  },
  computed: {
    ...mapState([
      'rechangeDialogVisible'
    ]),
    ...mapState({
      scoreInfo: (state) => state.user.scoreInfo
    }),
    // 选项是空，没有勾选条款，自定义的时候不是5的倍数，自定义时不再范围内
    btnDisabled () {
      return this.itemActive === undefined ||
        !this.isChecked ||
        (this.itemActive === 5 && this.customCount % 10 !== 0) ||
        (this.itemActive === 5 && (this.customCount < 10 || this.customCount > 5000))
    },
    nowTime () {
      return dayjs().format('YYYY/MM/DD HH:mm:ss')
    }
  },
  watch: {
    rechangeDialogVisible (data) {
      this.visible = data
    }
  },
  methods: {
    ...mapMutations([
      'CLOSE_RECHANGE_DIALOG'
    ]),
    // ...mapActions([
    //   'score/getScoreInfo'
    // ]),
    // 关闭的时候重置数据
    close () {
      this.visible = false
      this.isChecked = false
      this.customCount = undefined
      this.itemActive = undefined
      this.countMoney = 0
      this.CLOSE_RECHANGE_DIALOG()
    },
    open (path) {
      window.open(path, '_blank')
    },
    itemClick (index) {
      this.itemActive = index
      // index是5的话就是自定义
      if (index === 5) {
        this.changeMoney(this.customCount || 0)
      } else {
        this.changeMoney(this.listData[this.itemActive])
      }
    },
    changeMoney (data) {
      this.countMoney = (data / 10).toFixed(0)
    },
    toPay () {
      // 进行防抖
      if (this.isPay || this.btnDisabled) return
      this.isPay = true
      // 先添加订单
      getPaymentOrderAdd({
        product_name: 'score',
        product_desc: '积分',
        rec_amount: Number(this.countMoney),
        duration: 1,
        order_type: 'purchase'
      }).then(res => {
        this.isPay = false
        // 已有订单需要跳到订单页面
        if (res.status === 1) {
          this.close()
          this.showToOrderLog = true
          return
        }
        // 服务端其他异常的判断
        if (res.status !== 0) {
          this.$store.commit('SHOWMESSAGE', { type: 'error', message: res.msg, duration: 1000 })
          return
        }
        // 拿到订单号去支付
        getPaymentPay({
          order_id: res.order_id,
          order_type: 'purchase'
        }).then(res => {
          this.isPay = false
          if (res.status !== 0) {
            this.$store.commit('SHOWMESSAGE', { type: 'error', message: res.msg, duration: 1000 })
            return
          }
          // 关闭支付弹窗弹出确认是否支付弹窗，同时打开360支付中心
          this.orderDialogShow = true
          this.close()
          this.open(res.url)
        }).catch(() => {
          this.isPay = false
          this.$store.commit('SHOWMESSAGE', { type: 'error', message: t('http.timeout'), duration: 1000 })
        })
      }).catch(() => {
        this.isPay = false
        this.$store.commit('SHOWMESSAGE', { type: 'error', message: t('http.timeout'), duration: 1000 })
      })
    },
    // 关闭确认支付弹窗更新积分
    closeOrderDialog () {
      // 兑换成功重新加载积分
      getOrderList().then(() => {
        // this.getScoreInfo()
        this.$store.dispatch('user/getScoreInfo')
      })
      this.orderDialogShow = false
    },
    // 如果点叉就只关弹窗，否则就跳转订单页
    closeToOrderDialog (flag) {
      this.showToOrderLog = false
      if (!flag) return
      this.$router.push({
        name: 'order'
      })
    }
  }
}

</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
[data-theme=light]{
  .rechange-list__item{
    background-image: url('~@/assets/img/user-center/rechange-bg-light.png') !important;
    &.rechange-list__itemActive {
      background-color: #ffffff;
    }
  }
}
.rechange  {
  font-size: 14px;
  color: var(--color-text-1);
  & :deep(.rechange-dialog.el-dialog)  {
    background: var(--detect-config-bg);
    box-shadow: 0 2px 8px 0 #00000026;
    border-radius: 2px;
    & .el-dialog__header  {
      padding: 0;
      border-bottom: none;
    }
    & .el-dialog__body  {
      padding: 32px;
    }
    & .el-dialog__headerbtn:focus .el-dialog__close, & .el-dialog__headerbtn:hover .el-dialog__close  {
      color: var(--color-text-1);
    }
  }
  &-title  {
    font-family: "TencentSans W7";
    font-size: 16px;
    color: var(--score-title);
    line-height: 24px;
    overflow: hidden;
    margin-bottom: 20px;
    &>img {
      float: left;
    }
    &>span {
      margin-left: 5px;
      float: left;
    }
  }
  &-msg  {
    color: var(--color-text-1);
    &__span1  {
      font-family: "TencentSans W7";
      margin-right: 8px;
      color: var(--color-text-1);
    }
    &__span2  {
      font-family: "TencentSans W7";
      color: var(--color-text-2);
      & img  {
        transform: translateY(1px);
      }
    }
    &__span3  {
      margin-top: 20px;
      font-size: 14px;
      color: var(--color-text-1);
    }
    &__span4  {
      color: #ff0000;
      font-size: 20px;
      position: relative;
      top: 6px;
    }
  }
  &-list  {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    &__item  {
      height: 80px;
      width: 118px;
      font-family: "TencentSans W7";
      // font-size: 14px;
      font-size: 24px;
      text-align: center;
      line-height: 80px;
      color: var(--score-title);
      // background-color: #434449;
      background-color: var(--user-subscribe-card);
      background-image: url('~@/assets/img/user-center/rechange-bg.png');
      background-size: 100% 100%;
      background-position: top 0 right 0;
      background-repeat: no-repeat;
      border: 1px solid rgba(255, 216, 165, 0.2);
      border-radius: 4px;
      position: relative;
      cursor: pointer;
      &:hover  {
        border: 1px solid #F9DFB0;
        // background: #696665;
      }
      &Active  {
        // background: #696665;
        border: 1px solid #F9DFB0;
        border-radius: 4px;
      }
      &--active  {
        position: absolute;
        right: -8px;
        top: -8px;
      }
      &--custom  {
        width: 118px;
        cursor: unset;
        font-family: unset;
      }
      &--input  {
        display: inline-block;
        width: 90px;
        position: relative;
        &::after  {
          content: '';
          height: 1px;
          width: 90px;
          background: #9E9E9E;
          position: absolute;
          left: 0;
          bottom: 4px;
        }
        & :deep(.el-input__wrapper) {
          background-color: transparent;
          box-shadow:none
        }
        & :deep(.el-input__inner) {
          background: transparent;
          border: none;
          padding: 0;
          color: var(--color-text-1);
          font-size: 16px;
          &::placeholder  {
            font-size: 16px;
            color: #828283;
          }
          &::-webkit-outer-spin-button, &::-webkit-inner-spin-button  {
            -webkit-appearance: none;
          }
          -moz-appearance: textfield;
        }
        &Err  {
          // &::after  {
          //   background: #25FFF7;
          // }
          &Text  {
            font-family: PingFang SC;
            height: 20px;
            width: 288px;
            font-size: 12px;
            text-align: right;
            color: #ff0000;
            position: absolute;
            right: -1px;
            bottom: -25px;
            line-height: 20px;
          }
        }
      }
    }
  }
  &-clause  {
    margin-top: 30px;
    line-height: 32px;
    // font-size: 14px;
    color: #DDDFE7;
    &__label  {
      color: var(--color-text-1);
      &--after  {
        font-size: 20px;
        color: #ff0000;
        margin-right: 12px;
        position: relative;
        top: 6px;
      }
    }
    & :deep(.el-checkbox) {
      font-weight: normal;
      // @include checkbox();
      // .el-checkbox__inner  {
      //   height: 16px;
      //   width: 16px;
      //   transform: translateY(2px);
      // }
      .el-checkbox__label  {
        font-size: 14px;
      }
    }
    &__checkbox  {
      display: inline;
      color: var(--color-text-2);
      :deep(.el-checkbox__input) {
        top: 3px;
        display: inline;
      }
      &--click  {
        color: #00ab7a;
        cursor: pointer;
      }
    }
  }
  &-line  {
    margin-top: 30px;
    height: 1px;
    background: var(--color-border-2);
  }
  &-money  {
    margin-top: 20px;
    font-family: MicrosoftYaHei;
    font-size: 30px;
    color: var(--score-title);
    &__after  {
      font-size: 14px;
      // color: #DDDFE7;
      margin-left: 10px;
    }
  }
  &-btn  {
    width: 108px;
    height: 32px;
    border-radius: 16px;
    background-image: linear-gradient(155deg, #EAC998 0%, #D5A763 100%);
    color: #331503;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
    margin-left: calc(100% - 108px);
    &--disabled  {
      background: #8B8B8B;
      // background-image: linear-gradient(156deg, #f3d6ab 0%, #e5bd81 100%);
      color: #C7C7C7;
      cursor: not-allowed;
    }
  }
}
</style>
