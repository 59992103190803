import { watch, ref, nextTick } from 'vue'
import { useStore } from 'vuex'
import store from '@/store'

// const baseSize = 100

// function setRem() {
//   // 当前页面宽度相对于 1920宽的缩放比例，可根据自己需要修改。
//   const scale = document.documentElement.clientWidth / 1920
//   document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px'
// }

function getColorValue(colorKey) {
  return getComputedStyle(document.documentElement)
    .getPropertyValue(colorKey)
    .trim()
}

/**
 * 获取不同的主题颜色值
 * @param {*} colorKey
 * @returns
 */
export function getColor(colorKey) {
  const store = useStore()
  const colorValue = ref('')
  watch(
    () => store?.getters.theme,
    async () => {
      let color = getColorValue(colorKey)
      if (!color) {
        await nextTick()
        color = getColorValue(colorKey)
      }
      colorValue.value = color
    },
    {
      immediate: true
    }
  )
  return colorValue.value
}

/**
 * 获取不同的主题图片
 * @param {*} img
 * @returns
 */
export function getImg(img, theme) {
  const store = useStore()
  const imgUrl = ref('')
  if (theme) return `../../assets/img/${theme}/${img}`
  watch(
    () => store.getters.theme,
    async () => {
      imgUrl.value = `/assets/img/${store.getters.theme}/${img}`
    },
    {
      immediate: true
    }
  )
  return imgUrl.value
}

/**
 * 根据不同的屏幕尺寸，获取不同的设计尺寸
 * @param {*} px
 * @param {*} noSuffix
 * @returns
 */
export function getPx(px, noSuffix = false) {
  // 1920 * 1080
  const scale = document.body.scrollWidth / 1920
  return (px * scale).toFixed(0) + (!noSuffix ? 'px' : '')
}

/**
 * 初始化系统主题
 */
export function initTheme() {
  const store = useStore()
  window.document.documentElement.setAttribute(
    'data-theme',
    store.getters.theme
  )
}

export function initLang() {
  const store = useStore()
  window.document.documentElement.setAttribute(
    'lang',
    store.getters.language
  )
}

export function isDark() {
  return store?.getters.theme === 'dark'
}

export function watchSwitchTheme(...cbs) {
  watch(
    () => store.getters.theme,
    () => {
      cbs.forEach((cb) => cb(store.getters.language))
    }
  )
}

export default (app) => {
  app.config.globalProperties.$color = getColor
  app.config.globalProperties.$img = getImg
  app.config.globalProperties.$px = getPx
  app.config.globalProperties.$isDark = isDark
  // setRem()
  // window.addEventListener('resize', () => {
  //   setRem()
  // })
}
