<template>
  <div class="help-layout">
    <app-header />
    <div class="help-layout__content">
      <div class="help-layout__sidebar">
        <sidebar />
      </div>
      <div
        :class="[
          'help-layout__main'
        ]"
      >
        <div class="router-view__wrap" :class="{ hideFooter: !showFooter }">
          <router-view />
          <app-footer v-show="showFooter"></app-footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import AppHeader from 'components/app/app-header'
import AppFooter from 'components/app/app-footer'
import sidebar from './sidebar'

const route = useRoute()

const showFooter = computed(() => {
  return ['wiki', 'consulting', 'feedback'].indexOf(route.name) > -1
})

</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
.help-layout {
  width: 100%;
  min-width: 1330px;
  height: 100%;
  &__content {
    width: 100%;
    min-height: calc(100vh - $header-height);
    background-color: var(--color-bg-2);
    display: flex;
    // &.noFooter {
    //   min-height: calc(100vh - $header-height);
    // }
  }
  &__main {
    flex: 1;
    width: 100%;
    .router-view__wrap {
      overflow-y: auto;
      height: calc(100vh - 56px);
    }
    position: relative;
    box-sizing: border-box;
    &.sidebar {
      margin-left: 60px;
      min-width: 1270px;
    }
  }
  &__sidebar {
    background-color: var(--color-bg-1);
    box-shadow: 0 4px 35px 0 var(--color-bg-2);
    // position: fixed;
    position: relative;
    top: 0;
    left: 0;
    bottom: -120px;
    // width: 60px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // &:hover {
    //   width: 180px;
    // }
  }
}
</style>
