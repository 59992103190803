export default {
  permise: {
    visitor: {
      fileSize: {
        msg: 'The file size exceeds the 20 mb limit, upgrade to the Pro plan to unlock 50 mb'
      },
      // 每次个数
      once: {
        msg: 'If a single submission exceeds the 1 sample limit, upgrade to the professional version to unlock 20 single submissions'
      },
      // 每分钟次数
      minTimes: {
        msg: 'The submission frequency exceeds the limit of 4 requests/ min. Upgrade to the professional version to unlock 30 requests/ min.'
      },
      dayTimes: {
        msg: 'The number of samples submitted today has exceeded the 100 tasks/day limit. Upgrading to the professional version can unlock 500 tasks/day.'
      },
      monthTimes: {
        msg: 'The number of samples submitted this month has exceeded the 3,000  tasks/mon limit. Upgrading to the Pro plan can unlock 15,000  tasks/mon.'
      }
    },
    free: {
      fileSize: {
        msg: 'The file size exceeds the 20 mb limit, upgrade to the Pro plan to unlock 50 mb'
      },
      // 每次个数
      once: {
        msg: 'If a single submission exceeds the 1 sample limit, upgrade to the Pro plan to unlock 20 single submissions'
      },
      // 每分钟次数
      minTimes: {
        msg: 'The submission frequency exceeds the limit of 10  requests/ min. Upgrade to the Pro plan to unlock 30  requests/ min'
      },
      dayTimes: {
        msg: 'The number of samples submitted today has exceeded the 100 tasks/day limit. Upgrading to the Pro plan can unlock 500 tasks/day.'
      },
      monthTimes: {
        msg: 'The number of samples submitted this month has exceeded the 3,000 tasks/mon limit. Upgrading to the Pro plan can unlock 15,000 tasks/mon.'
      }
    },
    professional: {
      fileSize: {
        msg: 'The file size exceeds the 50 mb limit, upgrade to Pro Plus plan to unlock 100 mb'
      },
      once: {
        msg: 'If you submit more than 20 samples in a single time, you can unlock 40 samples in a single submission by upgrading to the Pro Plus plan.'
      },
      minTimes: {
        msg: 'The submission frequency exceeds the limit of 30 requests/ min. Upgrade to Pro Plus plan to unlock 60 requests/ min.'
      },
      dayTimes: {
        msg: 'The number of samples submitted today has exceeded the 500 tasks/day limit. Upgrading to the Pro Plus plan can unlock 5,000 tasks/day.'
      },
      monthTimes: {
        msg: 'The number of samples submitted this month has exceeded the 15,000 tasks/mon limit. Upgrading to the Pro Plus plan can unlock 150,000 tasks/mon.'
      }
    },
    enterprise: {
      fileSize: {
        msg: 'File size exceeds 100 mb limit'
      },
      once: {
        msg: 'Submit more than 40 samples in a single time limit'
      },
      minTimes: {
        msg: 'Submission frequency exceeds 60 requests/ min limit'
      },
      dayTimes: {
        msg: 'The number of samples submitted today has exceeded the 5,000 tasks/day limit'
      },
      monthTimes: {
        msg: 'The number of samples submitted this month has exceeded the 150,000 tasks/mon limit'
      }
    },
    qihoo: {
      fileSize: {
        msg: 'File size exceeds 100 mb limit'
      },
      once: {
        msg: 'Submit more than 40 samples in a single time limit'
      },
      minTimes: {
        msg: 'Submission frequency exceeds 600 requests/ min limit'
      },
      dayTimes: {
        msg: 'The number of samples submitted today has exceeded the 2,000,000 tasks/day limit'
      },
      monthTimes: {
        msg: 'The number of samples submitted this month has exceeded the 6,000,000 tasks/mon limit'
      }
    }
  }
}
