import request from 'app/http'

const getAccountInfo = (params) => {
  return request.get('/account/get/user', params)
}

const getAccountSubscribe = (params) => {
  return request.get('/account/get/user/quota', params)
}

const editAccountInfo = (params, onUploadProgress) => {
  return request({
    method: 'post',
    url: '/account/edit/user',
    data: params,
    headers: {
      'Content-type': 'multipart/form-data'
    },
    onUploadProgress
  })
}

const editUserAvatar = (params, onUploadProgress) => {
  return request({
    method: 'post',
    url: '/account/edit/user/avatar',
    data: params,
    headers: {
      'Content-type': 'multipart/form-data'
    },
    onUploadProgress
  })
}

const resetApiKey = (data) => {
  return request({ url: '/account/api_key/edit', method: 'post', data })
}

const sendEmailCode = (data) => {
  return request({ url: '/account/secure/email/send', method: 'post', data })
}

const verifyEmailCode = (data) => {
  return request({ url: '/account/secure/email/verify', method: 'post', data })
}

const sendModifyEmailCode = (data) => {
  return request({ url: '/account/email/send', method: 'post', data })
}

const modifyEmail = (data) => {
  return request({ url: '/account/email/edit', method: 'post', data })
}

const sendPhoneCode = (data) => {
  return request({ url: '/account/secure/phone/send', method: 'post', data })
}

const verifyPhoneCode = (data) => {
  return request({ url: '/account/secure/phone/verify', method: 'post', data })
}

const modifyUserName = (data) => {
  return request({ url: '/account/edit/user/username', method: 'post', data })
}

const sendSMSCode = (data) => {
  return request({ url: '/account/phone/send', method: 'post', data })
}
const modifyPhone = (data) => {
  return request({ url: '/account/phone/edit', method: 'post', data })
}

export {
  getAccountInfo,
  getAccountSubscribe,
  editAccountInfo,
  editUserAvatar,
  resetApiKey,
  sendEmailCode,
  verifyEmailCode,
  sendModifyEmailCode,
  modifyEmail,
  modifyUserName,
  sendSMSCode,
  modifyPhone,
  sendPhoneCode,
  verifyPhoneCode
}
