const coordinates = [
  [
    [
      [
        110.106396,
        20.026963
      ],
      [
        110.144585,
        20.074176
      ],
      [
        110.243135,
        20.077548
      ],
      [
        110.291178,
        20.056754
      ],
      [
        110.318279,
        20.109015
      ],
      [
        110.387265,
        20.113509
      ],
      [
        110.526467,
        20.0753
      ],
      [
        110.562191,
        20.109577
      ],
      [
        110.655814,
        20.134296
      ],
      [
        110.689075,
        20.164067
      ],
      [
        110.717408,
        20.148901
      ],
      [
        110.744509,
        20.074176
      ],
      [
        110.778386,
        20.068556
      ],
      [
        110.822118,
        20.025276
      ],
      [
        110.871393,
        20.011784
      ],
      [
        110.940994,
        20.028649
      ],
      [
        110.969327,
        20.010097
      ],
      [
        111.013675,
        19.850349
      ],
      [
        111.043856,
        19.763658
      ],
      [
        111.071573,
        19.629025
      ],
      [
        111.008747,
        19.604226
      ],
      [
        110.920668,
        19.552926
      ],
      [
        110.844292,
        19.450278
      ],
      [
        110.78085,
        19.395543
      ],
      [
        110.729727,
        19.378611
      ],
      [
        110.706321,
        19.3199
      ],
      [
        110.676756,
        19.286583
      ],
      [
        110.619474,
        19.152118
      ],
      [
        110.585597,
        18.880594
      ],
      [
        110.578206,
        18.78489
      ],
      [
        110.499366,
        18.751466
      ],
      [
        110.495054,
        18.65002
      ],
      [
        110.367555,
        18.631878
      ],
      [
        110.329366,
        18.64265
      ],
      [
        110.246215,
        18.609764
      ],
      [
        110.121795,
        18.512772
      ],
      [
        110.090382,
        18.399262
      ],
      [
        110.022629,
        18.360083
      ],
      [
        109.919767,
        18.375415
      ],
      [
        109.785492,
        18.339639
      ],
      [
        109.749767,
        18.193617
      ],
      [
        109.726362,
        18.177701
      ],
      [
        109.661688,
        18.175427
      ],
      [
        109.584696,
        18.143589
      ],
      [
        109.527413,
        18.169174
      ],
      [
        109.467051,
        18.173722
      ],
      [
        109.441182,
        18.199301
      ],
      [
        109.355566,
        18.215216
      ],
      [
        109.287813,
        18.264655
      ],
      [
        109.17448,
        18.260109
      ],
      [
        109.138756,
        18.268064
      ],
      [
        109.108575,
        18.323736
      ],
      [
        108.944735,
        18.31408
      ],
      [
        108.888068,
        18.412319
      ],
      [
        108.776583,
        18.441837
      ],
      [
        108.68912,
        18.447513
      ],
      [
        108.644772,
        18.486672
      ],
      [
        108.641077,
        18.565529
      ],
      [
        108.663866,
        18.673261
      ],
      [
        108.65278,
        18.740701
      ],
      [
        108.593033,
        18.809246
      ],
      [
        108.595497,
        18.872102
      ],
      [
        108.637997,
        18.924747
      ],
      [
        108.630606,
        19.002835
      ],
      [
        108.598577,
        19.056004
      ],
      [
        108.591186,
        19.141378
      ],
      [
        108.609048,
        19.276417
      ],
      [
        108.663866,
        19.374095
      ],
      [
        108.765496,
        19.401187
      ],
      [
        108.806148,
        19.450842
      ],
      [
        108.855424,
        19.469459
      ],
      [
        108.92872,
        19.524169
      ],
      [
        108.993394,
        19.586752
      ],
      [
        109.048829,
        19.620007
      ],
      [
        109.093792,
        19.689877
      ],
      [
        109.147379,
        19.705087
      ],
      [
        109.169553,
        19.736628
      ],
      [
        109.159082,
        19.790684
      ],
      [
        109.264407,
        19.904929
      ],
      [
        109.300748,
        19.917868
      ],
      [
        109.349407,
        19.898741
      ],
      [
        109.411001,
        19.895365
      ],
      [
        109.498464,
        19.873422
      ],
      [
        109.526797,
        19.943743
      ],
      [
        109.585312,
        19.98817
      ],
      [
        109.712195,
        20.017406
      ],
      [
        109.76147,
        19.981422
      ],
      [
        109.820601,
        19.994355
      ],
      [
        109.855093,
        19.984234
      ],
      [
        109.965346,
        19.993792
      ],
      [
        109.997375,
        19.979735
      ],
      [
        110.106396,
        20.026963
      ]
    ]
  ],
  [
    [
      [
        113.266165,
        8.126459
      ],
      [
        113.293882,
        8.176211
      ],
      [
        113.349317,
        8.231286
      ],
      [
        113.354244,
        8.304115
      ],
      [
        113.386273,
        8.289314
      ],
      [
        113.386273,
        8.238392
      ],
      [
        113.349933,
        8.172657
      ],
      [
        113.288955,
        8.119351
      ],
      [
        113.343157,
        8.193978
      ],
      [
        113.266165,
        8.126459
      ]
    ]
  ],
  [
    [
      [
        111.463311,
        17.077742
      ],
      [
        111.452224,
        17.093184
      ],
      [
        111.542151,
        17.120062
      ],
      [
        111.559397,
        17.087465
      ],
      [
        111.493492,
        17.058867
      ],
      [
        111.523673,
        17.10405
      ],
      [
        111.463311,
        17.077742
      ]
    ]
  ],
  [
    [
      [
        117.708319,
        15.182817
      ],
      [
        117.71879,
        15.229007
      ],
      [
        117.72495,
        15.131418
      ],
      [
        117.827812,
        15.11178
      ],
      [
        117.726798,
        15.105425
      ],
      [
        117.708319,
        15.182817
      ]
    ]
  ],
  [
    [
      [
        111.734324,
        16.197196
      ],
      [
        111.790374,
        16.220752
      ],
      [
        111.789758,
        16.25005
      ],
      [
        111.716462,
        16.248901
      ],
      [
        111.790374,
        16.274749
      ],
      [
        111.81686,
        16.2242
      ],
      [
        111.779903,
        16.197771
      ],
      [
        111.734324,
        16.197196
      ]
    ]
  ],
  [
    [
      [
        113.896887,
        7.607259
      ],
      [
        113.921524,
        7.63869
      ],
      [
        114.029314,
        7.670119
      ],
      [
        114.095219,
        7.721111
      ],
      [
        114.211632,
        7.786918
      ],
      [
        114.268298,
        7.870496
      ],
      [
        114.414892,
        7.952872
      ],
      [
        114.47279,
        7.968871
      ],
      [
        114.540543,
        7.945761
      ],
      [
        114.555326,
        7.89124
      ],
      [
        114.540543,
        7.862199
      ],
      [
        114.464167,
        7.81478
      ],
      [
        114.419819,
        7.765577
      ],
      [
        114.407501,
        7.683164
      ],
      [
        114.368696,
        7.63869
      ],
      [
        114.289856,
        7.617341
      ],
      [
        114.157429,
        7.56159
      ],
      [
        114.058879,
        7.537865
      ],
      [
        113.98743,
        7.536085
      ],
      [
        113.919677,
        7.566929
      ],
      [
        113.896887,
        7.607259
      ]
    ]
  ],
  [
    [
      [
        111.649324,
        16.255794
      ],
      [
        111.681969,
        16.254646
      ],
      [
        111.598817,
        16.198345
      ],
      [
        111.606825,
        16.17766
      ],
      [
        111.690592,
        16.210986
      ],
      [
        111.611136,
        16.156397
      ],
      [
        111.56802,
        16.162718
      ],
      [
        111.577875,
        16.208687
      ],
      [
        111.649324,
        16.255794
      ]
    ]
  ],
  [
    [
      [
        113.976959,
        8.872658
      ],
      [
        113.994821,
        8.876796
      ],
      [
        114.060111,
        8.816493
      ],
      [
        114.037321,
        8.781016
      ],
      [
        114.013299,
        8.836595
      ],
      [
        113.976959,
        8.872658
      ]
    ]
  ],
  [
    [
      [
        113.956017,
        8.840142
      ],
      [
        113.977575,
        8.841325
      ],
      [
        114.012068,
        8.794024
      ],
      [
        113.975111,
        8.792842
      ],
      [
        113.956017,
        8.840142
      ]
    ]
  ],
  [
    [
      [
        111.97454,
        16.323563
      ],
      [
        112.047221,
        16.360309
      ],
      [
        112.08233,
        16.338492
      ],
      [
        112.061388,
        16.314375
      ],
      [
        111.995482,
        16.30691
      ],
      [
        111.97454,
        16.323563
      ]
    ]
  ],
  [
    [
      [
        111.739251,
        16.452717
      ],
      [
        111.765737,
        16.495176
      ],
      [
        111.744179,
        16.551964
      ],
      [
        111.786679,
        16.520417
      ],
      [
        111.739251,
        16.452717
      ]
    ]
  ],
  [
    [
      [
        114.194386,
        8.764458
      ],
      [
        114.222103,
        8.784563
      ],
      [
        114.248588,
        8.724245
      ],
      [
        114.201161,
        8.727793
      ],
      [
        114.194386,
        8.764458
      ]
    ]
  ],
  [
    [
      [
        112.232619,
        16.996508
      ],
      [
        112.292981,
        16.967897
      ],
      [
        112.222764,
        16.960457
      ],
      [
        112.203054,
        16.983347
      ],
      [
        112.232619,
        16.996508
      ]
    ]
  ],
  [
    [
      [
        114.617536,
        9.965214
      ],
      [
        114.685905,
        9.979357
      ],
      [
        114.672355,
        9.927496
      ],
      [
        114.642173,
        9.917477
      ],
      [
        114.617536,
        9.965214
      ]
    ]
  ],
  [
    [
      [
        115.837712,
        9.709358
      ],
      [
        115.870972,
        9.778942
      ],
      [
        115.925791,
        9.7813
      ],
      [
        115.901153,
        9.671021
      ],
      [
        115.867277,
        9.650377
      ],
      [
        115.837712,
        9.709358
      ]
    ]
  ],
  [
    [
      [
        114.689601,
        10.345677
      ],
      [
        114.717318,
        10.380402
      ],
      [
        114.747499,
        10.372163
      ],
      [
        114.725941,
        10.319189
      ],
      [
        114.689601,
        10.345677
      ]
    ]
  ],
  [
    [
      [
        113.769387,
        7.636911
      ],
      [
        113.827902,
        7.646399
      ],
      [
        113.814967,
        7.603107
      ],
      [
        113.773699,
        7.601921
      ],
      [
        113.769387,
        7.636911
      ]
    ]
  ],
  [
    [
      [
        109.463972,
        7.344453
      ],
      [
        109.536037,
        7.448882
      ],
      [
        109.653065,
        7.559218
      ],
      [
        109.72205,
        7.575825
      ],
      [
        109.816289,
        7.57286
      ],
      [
        109.904984,
        7.551507
      ],
      [
        109.948716,
        7.523036
      ],
      [
        109.938861,
        7.504647
      ],
      [
        109.791651,
        7.524815
      ],
      [
        109.709115,
        7.511172
      ],
      [
        109.654297,
        7.479138
      ],
      [
        109.571761,
        7.373529
      ],
      [
        109.513247,
        7.320122
      ],
      [
        109.463972,
        7.314781
      ],
      [
        109.463972,
        7.344453
      ]
    ]
  ],
  [
    [
      [
        116.273181,
        8.879752
      ],
      [
        116.305826,
        8.916993
      ],
      [
        116.332311,
        8.901033
      ],
      [
        116.294123,
        8.857879
      ],
      [
        116.273181,
        8.879752
      ]
    ]
  ],
  [
    [
      [
        112.537509,
        8.846646
      ],
      [
        112.598487,
        8.859061
      ],
      [
        112.639755,
        8.818267
      ],
      [
        112.57077,
        8.81531
      ],
      [
        112.537509,
        8.846646
      ]
    ]
  ],
  [
    [
      [
        112.476531,
        16.001168
      ],
      [
        112.448814,
        16.005194
      ],
      [
        112.462364,
        16.044299
      ],
      [
        112.588016,
        16.070749
      ],
      [
        112.612037,
        16.039124
      ],
      [
        112.570154,
        16.010945
      ],
      [
        112.476531,
        16.001168
      ]
    ]
  ],
  [
    [
      [
        114.469095,
        10.83618
      ],
      [
        114.55471,
        10.900815
      ],
      [
        114.587355,
        10.90904
      ],
      [
        114.593514,
        10.856747
      ],
      [
        114.565181,
        10.836767
      ],
      [
        114.513442,
        10.84852
      ],
      [
        114.475254,
        10.814436
      ],
      [
        114.469095,
        10.83618
      ]
    ]
  ],
  [
    [
      [
        112.409393,
        16.295425
      ],
      [
        112.528886,
        16.318395
      ],
      [
        112.538741,
        16.289107
      ],
      [
        112.475915,
        16.289107
      ],
      [
        112.40077,
        16.263262
      ],
      [
        112.409393,
        16.295425
      ]
    ]
  ],
  [
    [
      [
        112.349031,
        16.911805
      ],
      [
        112.30222,
        16.962746
      ],
      [
        112.334249,
        16.962746
      ],
      [
        112.349031,
        16.911805
      ]
    ]
  ],
  [
    [
      [
        115.500177,
        9.898026
      ],
      [
        115.518039,
        9.933979
      ],
      [
        115.581481,
        9.917477
      ],
      [
        115.54822,
        9.869144
      ],
      [
        115.500177,
        9.898026
      ]
    ]
  ],
  [
    [
      [
        116.48876,
        10.395704
      ],
      [
        116.542346,
        10.419832
      ],
      [
        116.514629,
        10.349208
      ],
      [
        116.637817,
        10.3651
      ],
      [
        116.644592,
        10.335082
      ],
      [
        116.566368,
        10.304472
      ],
      [
        116.467202,
        10.309182
      ],
      [
        116.461658,
        10.349208
      ],
      [
        116.48876,
        10.395704
      ]
    ]
  ],
  [
    [
      [
        114.507899,
        8.119943
      ],
      [
        114.595978,
        8.157851
      ],
      [
        114.624311,
        8.149559
      ],
      [
        114.595978,
        8.120536
      ],
      [
        114.530073,
        8.103358
      ],
      [
        114.507899,
        8.119943
      ]
    ]
  ],
  [
    [
      [
        114.669891,
        8.21056
      ],
      [
        114.726557,
        8.21056
      ],
      [
        114.74134,
        8.18924
      ],
      [
        114.691449,
        8.185094
      ],
      [
        114.669891,
        8.21056
      ]
    ]
  ],
  [
    [
      [
        115.16757,
        8.386402
      ],
      [
        115.202678,
        8.395873
      ],
      [
        115.299381,
        8.37042
      ],
      [
        115.315395,
        8.356213
      ],
      [
        115.285214,
        8.314772
      ],
      [
        115.18112,
        8.345557
      ],
      [
        115.16757,
        8.386402
      ]
    ]
  ],
  [
    [
      [
        113.895039,
        8.005015
      ],
      [
        113.940003,
        8.01805
      ],
      [
        113.9708,
        7.944576
      ],
      [
        113.904894,
        7.963538
      ],
      [
        113.895039,
        8.005015
      ]
    ]
  ],
  [
    [
      [
        115.436119,
        9.393691
      ],
      [
        115.456445,
        9.416712
      ],
      [
        115.469996,
        9.358861
      ],
      [
        115.450286,
        9.345282
      ],
      [
        115.436119,
        9.393691
      ]
    ]
  ],
  [
    [
      [
        113.638192,
        8.976689
      ],
      [
        113.719496,
        9.019829
      ],
      [
        113.730583,
        9.003874
      ],
      [
        113.654823,
        8.961913
      ],
      [
        113.638192,
        8.976689
      ]
    ]
  ],
  [
    [
      [
        116.457347,
        9.174619
      ],
      [
        116.494919,
        9.179935
      ],
      [
        116.481984,
        9.136814
      ],
      [
        116.457347,
        9.174619
      ]
    ]
  ],
  [
    [
      [
        111.572948,
        16.469931
      ],
      [
        111.588962,
        16.492307
      ],
      [
        111.614216,
        16.440092
      ],
      [
        111.572948,
        16.469931
      ]
    ]
  ],
  [
    [
      [
        113.939387,
        8.875023
      ],
      [
        113.931995,
        8.841325
      ],
      [
        113.893807,
        8.862608
      ],
      [
        113.912285,
        8.88921
      ],
      [
        113.939387,
        8.875023
      ]
    ]
  ],
  [
    [
      [
        114.62,
        11.432049
      ],
      [
        114.621232,
        11.518831
      ],
      [
        114.661884,
        11.522348
      ],
      [
        114.652644,
        11.437327
      ],
      [
        114.62,
        11.432049
      ]
    ]
  ],
  [
    [
      [
        112.010881,
        3.850164
      ],
      [
        112.052765,
        3.87643
      ],
      [
        112.055228,
        3.840015
      ],
      [
        112.010881,
        3.850164
      ]
    ]
  ],
  [
    [
      [
        114.910723,
        10.863211
      ],
      [
        114.934129,
        10.903165
      ],
      [
        114.959998,
        10.89259
      ],
      [
        114.931049,
        10.841469
      ],
      [
        114.910723,
        10.863211
      ]
    ]
  ],
  [
    [
      [
        109.936397,
        7.848566
      ],
      [
        109.953027,
        7.888869
      ],
      [
        110.023245,
        7.93865
      ],
      [
        110.078063,
        7.949317
      ],
      [
        110.082991,
        7.896574
      ],
      [
        110.050346,
        7.846195
      ],
      [
        109.988136,
        7.812408
      ],
      [
        109.936397,
        7.823671
      ],
      [
        109.936397,
        7.848566
      ]
    ]
  ],
  [
    [
      [
        114.696992,
        11.004203
      ],
      [
        114.710543,
        11.040027
      ],
      [
        114.766593,
        11.110489
      ],
      [
        114.799854,
        11.105205
      ],
      [
        114.793079,
        11.076435
      ],
      [
        114.710543,
        11.001853
      ],
      [
        114.696992,
        11.004203
      ]
    ]
  ],
  [
    [
      [
        116.727128,
        11.501828
      ],
      [
        116.738215,
        11.514727
      ],
      [
        116.772092,
        11.445537
      ],
      [
        116.765316,
        11.430289
      ],
      [
        116.727128,
        11.501828
      ]
    ]
  ],
  [
    [
      [
        113.845764,
        10.018836
      ],
      [
        113.856851,
        10.12193
      ],
      [
        113.872249,
        10.123108
      ],
      [
        113.865474,
        10.002927
      ],
      [
        113.845764,
        10.018836
      ]
    ]
  ],
  [
    [
      [
        114.791847,
        8.160812
      ],
      [
        114.812173,
        8.110466
      ],
      [
        114.777064,
        8.11402
      ],
      [
        114.791847,
        8.160812
      ]
    ]
  ],
  [
    [
      [
        111.690592,
        16.58752
      ],
      [
        111.717078,
        16.594401
      ],
      [
        111.724469,
        16.559993
      ],
      [
        111.690592,
        16.58752
      ]
    ]
  ],
  [
    [
      [
        111.761425,
        16.061549
      ],
      [
        111.823635,
        16.073048
      ],
      [
        111.828563,
        16.049474
      ],
      [
        111.791606,
        16.028773
      ],
      [
        111.761425,
        16.061549
      ]
    ]
  ],
  [
    [
      [
        112.507328,
        16.465915
      ],
      [
        112.499321,
        16.493455
      ],
      [
        112.575081,
        16.537051
      ],
      [
        112.586784,
        16.52558
      ],
      [
        112.507328,
        16.465915
      ]
    ]
  ],
  [
    [
      [
        115.28275,
        10.192015
      ],
      [
        115.333257,
        10.200259
      ],
      [
        115.288294,
        10.172581
      ],
      [
        115.28275,
        10.192015
      ]
    ]
  ],
  [
    [
      [
        111.216935,
        19.882987
      ],
      [
        111.216319,
        19.881862
      ],
      [
        111.216935,
        19.882424
      ],
      [
        111.216935,
        19.882987
      ]
    ]
  ],
  [
    [
      [
        114.703151,
        16.170189
      ],
      [
        114.704383,
        16.199494
      ],
      [
        114.802934,
        16.215007
      ],
      [
        114.816484,
        16.198345
      ],
      [
        114.703151,
        16.170189
      ]
    ]
  ],
  [
    [
      [
        114.665579,
        7.59006
      ],
      [
        114.703767,
        7.614968
      ],
      [
        114.72163,
        7.591839
      ],
      [
        114.671739,
        7.563963
      ],
      [
        114.665579,
        7.59006
      ]
    ]
  ],
  [
    [
      [
        115.97753,
        9.322256
      ],
      [
        115.999088,
        9.293915
      ],
      [
        115.943037,
        9.269705
      ],
      [
        115.926407,
        9.311038
      ],
      [
        115.97753,
        9.322256
      ]
    ]
  ],
  [
    [
      [
        115.258113,
        8.509504
      ],
      [
        115.296301,
        8.510687
      ],
      [
        115.271048,
        8.476957
      ],
      [
        115.258113,
        8.509504
      ]
    ]
  ],
  [
    [
      [
        114.688985,
        11.468993
      ],
      [
        114.720398,
        11.491861
      ],
      [
        114.737644,
        11.463715
      ],
      [
        114.722246,
        11.429116
      ],
      [
        114.688985,
        11.468993
      ]
    ]
  ],
  [
    [
      [
        114.493116,
        10.717449
      ],
      [
        114.539312,
        10.793278
      ],
      [
        114.562717,
        10.777996
      ],
      [
        114.493116,
        10.717449
      ]
    ]
  ],
  [
    [
      [
        117.770529,
        10.773294
      ],
      [
        117.801942,
        10.839706
      ],
      [
        117.832739,
        10.836767
      ],
      [
        117.835819,
        10.803857
      ],
      [
        117.798862,
        10.753308
      ],
      [
        117.770529,
        10.773294
      ]
    ]
  ],
  [
    [
      [
        116.638433,
        10.50397
      ],
      [
        116.699411,
        10.517501
      ],
      [
        116.70865,
        10.492792
      ],
      [
        116.638433,
        10.50397
      ]
    ]
  ],
  [
    [
      [
        114.242429,
        10.242066
      ],
      [
        114.292936,
        10.293876
      ],
      [
        114.326197,
        10.284457
      ],
      [
        114.263371,
        10.239711
      ],
      [
        114.242429,
        10.242066
      ]
    ]
  ],
  [
    [
      [
        113.221817,
        8.074332
      ],
      [
        113.269861,
        8.119943
      ],
      [
        113.283411,
        8.111058
      ],
      [
        113.221817,
        8.074332
      ]
    ]
  ],
  [
    [
      [
        110.459946,
        8.116389
      ],
      [
        110.461793,
        8.128236
      ],
      [
        110.568351,
        8.172657
      ],
      [
        110.599764,
        8.156666
      ],
      [
        110.554184,
        8.09388
      ],
      [
        110.471032,
        8.071962
      ],
      [
        110.459946,
        8.116389
      ]
    ]
  ],
  [
    [
      [
        111.216935,
        19.882987
      ],
      [
        111.216935,
        19.882424
      ],
      [
        111.216319,
        19.881862
      ],
      [
        111.216935,
        19.882987
      ]
    ]
  ],
  [
    [
      [
        112.345952,
        8.925859
      ],
      [
        112.384756,
        8.947138
      ],
      [
        112.392763,
        8.919357
      ],
      [
        112.345952,
        8.925859
      ]
    ]
  ],
  [
    [
      [
        111.657947,
        8.672789
      ],
      [
        111.713382,
        8.671606
      ],
      [
        111.717694,
        8.649721
      ],
      [
        111.665955,
        8.623101
      ],
      [
        111.657947,
        8.672789
      ]
    ]
  ],
  [
    [
      [
        117.258068,
        10.320366
      ],
      [
        117.274698,
        10.358037
      ],
      [
        117.299336,
        10.313891
      ],
      [
        117.258068,
        10.320366
      ]
    ]
  ],
  [
    [
      [
        111.925265,
        8.070777
      ],
      [
        111.95483,
        8.10632
      ],
      [
        112.013344,
        8.093288
      ],
      [
        112.018888,
        8.065445
      ],
      [
        111.965301,
        8.047673
      ],
      [
        111.925265,
        8.070777
      ]
    ]
  ],
  [
    [
      [
        114.212864,
        16.040849
      ],
      [
        114.306487,
        16.057524
      ],
      [
        114.307718,
        16.033949
      ],
      [
        114.212864,
        16.040849
      ]
    ]
  ],
  [
    [
      [
        112.222148,
        3.880609
      ],
      [
        112.264032,
        3.897921
      ],
      [
        112.249249,
        3.859715
      ],
      [
        112.222148,
        3.880609
      ]
    ]
  ],
  [
    [
      [
        111.539071,
        7.54439
      ],
      [
        111.566788,
        7.606073
      ],
      [
        111.612368,
        7.592432
      ],
      [
        111.583419,
        7.543203
      ],
      [
        111.539071,
        7.54439
      ]
    ]
  ],
  [
    [
      [
        110.609003,
        8.01094
      ],
      [
        110.641648,
        8.031084
      ],
      [
        110.642879,
        7.989018
      ],
      [
        110.609003,
        8.01094
      ]
    ]
  ],
  [
    [
      [
        110.460561,
        7.79996
      ],
      [
        110.485199,
        7.82782
      ],
      [
        110.511684,
        7.805888
      ],
      [
        110.487663,
        7.783362
      ],
      [
        110.460561,
        7.79996
      ]
    ]
  ],
  [
    [
      [
        115.509416,
        8.490568
      ],
      [
        115.514344,
        8.519563
      ],
      [
        115.558691,
        8.523114
      ],
      [
        115.55438,
        8.460978
      ],
      [
        115.521735,
        8.460386
      ],
      [
        115.509416,
        8.490568
      ]
    ]
  ],
  [
    [
      [
        112.248633,
        3.945078
      ],
      [
        112.259104,
        3.973729
      ],
      [
        112.284974,
        3.948062
      ],
      [
        112.248633,
        3.945078
      ]
    ]
  ],
  [
    [
      [
        113.600004,
        6.96213
      ],
      [
        113.62341,
        6.94253
      ],
      [
        113.580294,
        6.920554
      ],
      [
        113.600004,
        6.96213
      ]
    ]
  ],
  [
    [
      [
        114.599058,
        8.846054
      ],
      [
        114.612608,
        8.876796
      ],
      [
        114.665579,
        8.899851
      ],
      [
        114.68221,
        8.880934
      ],
      [
        114.645869,
        8.844872
      ],
      [
        114.599058,
        8.846054
      ]
    ]
  ],
  [
    [
      [
        111.670266,
        7.651143
      ],
      [
        111.691208,
        7.711625
      ],
      [
        111.726317,
        7.730005
      ],
      [
        111.749722,
        7.703917
      ],
      [
        111.707223,
        7.648771
      ],
      [
        111.670266,
        7.651143
      ]
    ]
  ],
  [
    [
      [
        116.045283,
        10.095423
      ],
      [
        116.070537,
        10.128998
      ],
      [
        116.09579,
        10.093656
      ],
      [
        116.067457,
        10.065968
      ],
      [
        116.045283,
        10.095423
      ]
    ]
  ],
  [
    [
      [
        114.854057,
        7.244154
      ],
      [
        114.869455,
        7.199042
      ],
      [
        114.819564,
        7.193106
      ],
      [
        114.854057,
        7.244154
      ]
    ]
  ],
  [
    [
      [
        117.266691,
        10.691581
      ],
      [
        117.293176,
        10.735085
      ],
      [
        117.369553,
        10.742727
      ],
      [
        117.418212,
        10.702752
      ],
      [
        117.404661,
        10.671002
      ],
      [
        117.266691,
        10.691581
      ]
    ]
  ],
  [
    [
      [
        114.868223,
        7.983685
      ],
      [
        114.914419,
        8.007385
      ],
      [
        114.907643,
        7.951094
      ],
      [
        114.868223,
        7.983685
      ]
    ]
  ],
  [
    [
      [
        112.527654,
        5.794309
      ],
      [
        112.562146,
        5.8205
      ],
      [
        112.562762,
        5.759187
      ],
      [
        112.527654,
        5.794309
      ]
    ]
  ],
  [
    [
      [
        114.448153,
        16.033949
      ],
      [
        114.465399,
        16.067299
      ],
      [
        114.521449,
        16.056374
      ],
      [
        114.448153,
        16.033949
      ]
    ]
  ],
  [
    [
      [
        112.523342,
        5.656189
      ],
      [
        112.528886,
        5.68715
      ],
      [
        112.56153,
        5.677028
      ],
      [
        112.565842,
        5.630586
      ],
      [
        112.5449,
        5.616295
      ],
      [
        112.523342,
        5.656189
      ]
    ]
  ],
  [
    [
      [
        117.347995,
        10.090121
      ],
      [
        117.373864,
        10.106615
      ],
      [
        117.385567,
        10.063612
      ],
      [
        117.347995,
        10.090121
      ]
    ]
  ],
  [
    [
      [
        112.244322,
        8.874432
      ],
      [
        112.28559,
        8.888619
      ],
      [
        112.281278,
        8.854923
      ],
      [
        112.244322,
        8.874432
      ]
    ]
  ],
  [
    [
      [
        112.89229,
        7.844417
      ],
      [
        112.93171,
        7.867533
      ],
      [
        112.929862,
        7.82782
      ],
      [
        112.89229,
        7.844417
      ]
    ]
  ],
  [
    [
      [
        112.583088,
        5.561511
      ],
      [
        112.616349,
        5.568657
      ],
      [
        112.642834,
        5.489449
      ],
      [
        112.614501,
        5.465626
      ],
      [
        112.583088,
        5.561511
      ]
    ]
  ],
  [
    [
      [
        116.695099,
        16.345382
      ],
      [
        116.717889,
        16.373513
      ],
      [
        116.747454,
        16.360309
      ],
      [
        116.747454,
        16.32184
      ],
      [
        116.713577,
        16.296573
      ],
      [
        116.695099,
        16.345382
      ]
    ]
  ],
  [
    [
      [
        112.650842,
        5.106965
      ],
      [
        112.678559,
        5.121268
      ],
      [
        112.719211,
        5.075975
      ],
      [
        112.682871,
        5.048559
      ],
      [
        112.655769,
        5.055711
      ],
      [
        112.650842,
        5.106965
      ]
    ]
  ],
  [
    [
      [
        113.832213,
        19.158335
      ],
      [
        113.799568,
        19.199024
      ],
      [
        113.80696,
        19.223319
      ],
      [
        113.875945,
        19.236878
      ],
      [
        113.920293,
        19.223319
      ],
      [
        113.914749,
        19.171899
      ],
      [
        113.874097,
        19.151553
      ],
      [
        113.832213,
        19.158335
      ]
    ]
  ],
  [
    [
      [
        111.638853,
        7.907242
      ],
      [
        111.651788,
        7.932724
      ],
      [
        111.713382,
        7.927391
      ],
      [
        111.712766,
        7.887091
      ],
      [
        111.638853,
        7.907242
      ]
    ]
  ],
  [
    [
      [
        112.993304,
        19.472279
      ],
      [
        112.993304,
        19.525861
      ],
      [
        113.029028,
        19.52868
      ],
      [
        113.048123,
        19.493714
      ],
      [
        112.993304,
        19.472279
      ]
    ]
  ],
  [
    [
      [
        112.557219,
        5.109349
      ],
      [
        112.601567,
        5.11948
      ],
      [
        112.610806,
        5.09147
      ],
      [
        112.568922,
        5.071803
      ],
      [
        112.557219,
        5.109349
      ]
    ]
  ],
  [
    [
      [
        112.350263,
        5.621654
      ],
      [
        112.385372,
        5.64309
      ],
      [
        112.385988,
        5.6157
      ],
      [
        112.350263,
        5.621654
      ]
    ]
  ],
  [
    [
      [
        112.907072,
        4.993128
      ],
      [
        112.910768,
        5.038427
      ],
      [
        112.952652,
        5.047963
      ],
      [
        112.943413,
        4.991936
      ],
      [
        112.907072,
        4.993128
      ]
    ]
  ],
  [
    [
      [
        115.361591,
        13.948788
      ],
      [
        115.423185,
        13.97724
      ],
      [
        115.438583,
        13.943562
      ],
      [
        115.397315,
        13.924979
      ],
      [
        115.361591,
        13.948788
      ]
    ]
  ],
  [
    [
      [
        114.948911,
        7.508799
      ],
      [
        115.013585,
        7.526002
      ],
      [
        115.012353,
        7.485071
      ],
      [
        114.981556,
        7.476172
      ],
      [
        114.948911,
        7.508799
      ]
    ]
  ],
  [
    [
      [
        116.152457,
        9.579586
      ],
      [
        116.187565,
        9.595515
      ],
      [
        116.189413,
        9.565426
      ],
      [
        116.152457,
        9.579586
      ]
    ]
  ],
  [
    [
      [
        112.233851,
        15.696109
      ],
      [
        112.20367,
        15.713965
      ],
      [
        112.251713,
        15.743337
      ],
      [
        112.233851,
        15.696109
      ]
    ]
  ],
  [
    [
      [
        111.553854,
        7.807666
      ],
      [
        111.603745,
        7.861606
      ],
      [
        111.619759,
        7.840268
      ],
      [
        111.585267,
        7.771505
      ],
      [
        111.553854,
        7.807666
      ]
    ]
  ],
  [
    [
      [
        112.612037,
        5.367938
      ],
      [
        112.62374,
        5.401892
      ],
      [
        112.690878,
        5.406658
      ],
      [
        112.685334,
        5.371512
      ],
      [
        112.640371,
        5.347684
      ],
      [
        112.612037,
        5.367938
      ]
    ]
  ],
  [
    [
      [
        112.226459,
        16.759471
      ],
      [
        112.211061,
        16.796134
      ],
      [
        112.262184,
        16.778376
      ],
      [
        112.226459,
        16.759471
      ]
    ]
  ],
  [
    [
      [
        116.749302,
        9.056465
      ],
      [
        116.740679,
        9.028693
      ],
      [
        116.699411,
        9.048783
      ],
      [
        116.749302,
        9.056465
      ]
    ]
  ],
  [
    [
      [
        114.926122,
        16.036824
      ],
      [
        114.910723,
        16.002318
      ],
      [
        114.895325,
        16.036249
      ],
      [
        114.926122,
        16.036824
      ]
    ]
  ],
  [
    [
      [
        113.938771,
        15.835457
      ],
      [
        113.9708,
        15.839487
      ],
      [
        113.973263,
        15.805523
      ],
      [
        113.938771,
        15.835457
      ]
    ]
  ],
  [
    [
      [
        112.64653,
        16.385568
      ],
      [
        112.660081,
        16.426893
      ],
      [
        112.677327,
        16.388438
      ],
      [
        112.64653,
        16.385568
      ]
    ]
  ],
  [
    [
      [
        115.758256,
        10.461021
      ],
      [
        115.801987,
        10.463375
      ],
      [
        115.776118,
        10.434543
      ],
      [
        115.758256,
        10.461021
      ]
    ]
  ],
  [
    [
      [
        112.512255,
        9.544775
      ],
      [
        112.567074,
        9.554216
      ],
      [
        112.568922,
        9.517042
      ],
      [
        112.50856,
        9.525893
      ],
      [
        112.512255,
        9.544775
      ]
    ]
  ],
  [
    [
      [
        117.299336,
        11.077609
      ],
      [
        117.304263,
        11.027107
      ],
      [
        117.264227,
        11.062929
      ],
      [
        117.299336,
        11.077609
      ]
    ]
  ],
  [
    [
      [
        117.691073,
        11.048836
      ],
      [
        117.690457,
        11.016536
      ],
      [
        117.653501,
        11.046487
      ],
      [
        117.691073,
        11.048836
      ]
    ]
  ],
  [
    [
      [
        114.610145,
        15.649447
      ],
      [
        114.610761,
        15.615451
      ],
      [
        114.581195,
        15.625247
      ],
      [
        114.610145,
        15.649447
      ]
    ]
  ],
  [
    [
      [
        114.166668,
        9.384246
      ],
      [
        114.194386,
        9.391329
      ],
      [
        114.175291,
        9.34233
      ],
      [
        114.166668,
        9.384246
      ]
    ]
  ]
]

export default coordinates
