export default {
  detection: {
    tab: {
      file: '文件提交',
      url: 'URL提交',
      hash: '哈希提交',
      fileLabelText: '点击或拖动至此处上传文件以进行检测',
      urlPlaceholder: '输入或粘贴 URL 以进行检测',
      hashPlaceholder: '输入或粘贴 MD5、SHA1 或 SHA256 以进行检测'
    },
    reference: '沙箱云服务提供的高级威胁分析信息仅可作为您评估安全风险的参考',
    file_list_name: {
      file: '文件提交列表',
      url: 'URL提交列表',
      hash: '哈希提交列表'
    },
    single_configurable_info: '可以针对单个样本进行任务配置',
    drag_add_file: '释放鼠标按键以添加文件',
    file_upload_status: {
      file: {
        success: '已上传',
        failed: '上传失败'
      },
      url: {
        success: '已验证',
        failed: '验证失败'
      },
      hash: {
        success: '已下载',
        failed: '下载失败'
      }
    },
    running_map: {
      file: '上传中',
      url: '验证中',
      hash: '下载中'
    },
    check_enums: {
      downloaded: '已下载',
      download_failed: '下载失败',
      verified: '已验证',
      verify_failed: '验证失败',
      verify_failed_type_not_found: '无法确认url类型',
      content_to_large: '内容过大',
      downloading: '下载中'
    },
    file_total_status: {
      size: '总大小',
      num: '文件总数',
      uploaded: '已上传',
      uploading: '上传中',
      failed: '上传失败'
    },
    url_total_status: {
      url_num: 'URL总数',
      verified: '已验证',
      verifying: '验证中',
      failed: '验证失败',
      size: '总大小',
      file_num: '文件总数',
      downloaded: '已下载',
      downloading: '下载中',
      download_failed: '下载失败'
    },
    totol_size_too_large: '文件总大小超出提交限额',
    add_file: '添加文件',
    type_error: '格式错误',
    permissions: {
      label: '任务权限',
      active: '公开',
      inactive: '私有'
    },
    share_team: {
      label: '分享至团队',
      placeholder: '选择团队'
    },
    agreement: '同意{user}和{privacy}，请勿提交个人隐私信息及违反法律法规的信息。', // 保留{user} {privacy}
    exsit_err_item: '列表中存在{0}的文件，请清理后再提交检测!',
    err_text: '上传失败',
    err_url_text: '验证失败的 URL 或下载失败',
    user: '《360沙箱云用户协议》',
    privacy: '《360沙箱云隐私政策》',
    submit: '提交检测',
    clean_up: '清理问题项',
    submit_after_clear: '列表中存在验证失败的 URL 或下载失败的文件，请清理后再提交检测!',
    textMap: {
      hash: {
        placeholder: '输入或粘贴内容以添加 MD5、SHA1 或 SHA256 任务',
        msg1: '列表中无hash，请更正后提交',
        msg2: '列表中有无效hash，请更正后提交',
        msg3: '禁止上传相同hash'
      },
      url: {
        placeholder: '输入或粘贴内容以添加 URL 任务',
        msg1: '列表中无URL，请更正后提交',
        msg2: 'URL 格式错误',
        msg3: '禁止上传相同url'
      }
    },
    errText: {
      empty: '暂无可配置的任务，请等待处理完成或添加新的项',
      progress: '此任务{0}尚未完成，暂时无法设置任务配置',
      failed: '此任务{0}失败，无法设置任务配置，请清理该项'
    },
    slot: {
      download: '下载',
      upload: '上传',
      verify: '验证'
    },
    back_global: '返回全局配置',
    gloabal_configuration: '全局配置',
    single_configuration: '单项配置',
    use_gloabal_configuration: '使用全局配置',
    file_size_limit: '文件大小超出{0}限制',
    folder_limit: '禁止上传文件夹',
    empty_limit: {
      message: '禁止上传空文件',
      tooltip: '文件为空'
    },
    duplicated_name_limit: '禁止上传相同文件名文件',
    err_config_item: '配置项中存在错误,请更正后操作',
    submitting: '任务提交中',
    submit_success: '文件任务提交完成',
    submit_failed: '提交失败，网络延时，请重新提交',
    url_submit_error: 'url格式错误，请修改后提交',
    err_agreement: '请阅读并同意《360沙箱云用户协议》和《360沙箱云隐私政策》',
    uploadStatus: {
      101: '文件不存在',
      102: '文件为空',
      103: '文件读取失败',
      104: '文件上传失败',
      105: '文件大小超出限制',
      106: '任务数量超出限制',
      107: 'URL格式错误',
      108: 'URL域名解析失败',
      109: 'URL主机访问失败',
      110: 'URL页面访问失败',
      111: 'URL文件下载失败',
      112: 'URL文件大小超出限制',
      113: '哈希文件下载失败',
      114: '哈希文件大小超出限制',
      115: '任务配额超出限制',
      116: 'hash值不存在',
      140: '服务器内部错误',
      150: '未知错误'
    }
  },
  config: {
    basic: {
      title: '基本检测配置',
      strategy: {
        title: '快速检测',
        close: '关闭快速检测',
        intelligent: '智能快速检测',
        enforce: '强制快速检测'
      },
      os: {
        title: '操作系统',
        suffix: '位'
      },
      lang: {
        title: '系统语言',
        zh_label: '中文简体',
        en_label: '英文',
        map: {
          'zh-cn': '中文版',
          'en-us': '英文版',
          'ko-kr': '韩文版',
          // eslint-disable-next-line quote-props
          'any': '多国语言版'
        },
        suffix: '位'
      },
      software_major: {
        title: '应用环境',
        placeholder: '请选择',
        non_select: '暂无可选应用程序'
      },
      system_detail: {
        title: '系统和应用版本详情',
        show: '查看',
        hide: '隐藏'
      }
    },
    dynamic: {
      title: '动态检测配置',
      open_in_browser: '在浏览器中打开',
      custom_detect_type: {
        title: '文件类型',
        placeholder: '自动识别（默认）'
      },
      unzip_password: {
        title: '解压密码',
        placeholder: '输入自定义压缩文件的解压密码'
      },
      specify_subfiles: {
        title: '指定压缩文件中的子文件',
        md5: {
          title: '指定 MD5',
          placeholder: '输入子文件的 MD5 以执行'
        },
        file_name: {
          title: '指定文件名',
          placeholder: '输入子文件的文件名以执行'
        },
        file_type: {
          title: '指定文件类型',
          placeholder: '不指定'
        }
      },
      pwd_to_mso: {
        title: '文档密码',
        placeholder: '输入自定义文档文件的查看密码'
      },
      app_forced: '指定应用打开文档',
      emailer: {
        title: '邮件文件的处理方式',
        placeholder: '选择邮件文件的处理方式',
        options: {
          execute_attach: '提取附件并执行',
          outlook: '用 Outlook 打开'
        }
      },
      cmd: {
        title: '命令行',
        placeholder: '输入自定义执行进程的命令行'
      },
      function: {
        title: '动态库参数',
        placeholder: '请输入函数名',
        arguments: '输入参数值，多个参数值之间用“空格”间隔'
      },
      name_forced: {
        title: '自定义文件名和扩展名',
        placeholder: '输入自定义文件名',
        ext_forced: '输入自定义扩展名'
      },
      runpath: {
        title: '指定目录下执行进程',
        placeholder: '自动设置',
        options: {
          auto: '自动设置',
          desktop: '桌面',
          document: '文档目录',
          user: '用户目录',
          download: '下载目录',
          appdata: '用户数据目录',
          temp: '临时目录',
          windows: '系统目录',
          rootdir: '根目录'
        }
      }
    },
    strategy: {
      title: '检测策略配置',
      timeout: {
        title: '检测时长',
        suffix: '秒',
        professional: '专业版支持至360秒',
        enterprise: '专业增强版支持至660秒'
      },
      android_human: '模拟点击',
      human: '人类操作模拟',
      human_click: '自动点击',
      human_scroll: '自动滚动',
      human_clipbd: '剪贴板填充',
      human_curpos: '鼠标移动',
      human_autoin: '自动安装',
      fast_detect: '快速动态检测',
      fake_reboot: '模拟重启',
      active_reboot: '主动重启',
      pwd_forced: '暴力破解密码',
      timezone: {
        title: '时区',
        placeholder: '自动设置'
      },
      geo: {
        title: '所在地区',
        placeholder: '自动设置'
      },
      datetime: {
        title: '系统日期与时间',
        placeholder: '自动设置'
      },
      env_var: {
        title: '环境变量',
        key_placeholder: '输入变量名',
        value_placeholder: '输入变量值'
      }
    },
    internet: {
      title: '网络检测配置',
      connect: '连接互联网',
      decrypt_https: '解密 HTTPS 流量'
    },
    need_professional: '需要升级为专业版或专业增强版',
    need_enterprise: '需要升级为专业增强版',
    min_timeout: '主动重启检测需要保证分析时长不小于 120 秒'
  }
}
