<template>
  <div class="sidebar-wrapper" :class="{ isOpen: menuOpenStatus }">
    <ul class="list-wrap">
      <template
        v-for="item in tabList"
        :key="item.value"
      >
        <li
          v-if="!item.hasChild"
          :class="['list-item', {active: $route.path.indexOf(item.value) > -1}, {hasChild: item.hasChild}]"
          @click="handleClick(item.value)"
        >
          <template v-if="menuOpenStatus">
            <svg-icon class="item-icon" :name="item.icon"></svg-icon>
            <span class="item-desc">{{ item.label }}</span>
          </template>
          <el-tooltip
            v-else
            :effect="$isDark() ? 'dark' : 'light'"
            :content="item.label"
            placement="right"
            :offset="10"
          >
            <div style="height: 100%; width: 100%;display: flex;align-items: center;">
              <svg-icon class="item-icon" :name="item.icon"></svg-icon>
            </div>
          </el-tooltip>
        </li>
        <el-popover
          v-else
          placement="right-start"
          title="title"
          popper-class="team-item__popper"
          :width="180"
          trigger="hover"
        >
          <template #reference>
            <li
              :class="['list-item', {active: $route.path.indexOf(item.value) > -1}, {hasChild: item.hasChild}]"
              @click="handleClick('ownerteam')"
            >
              <svg-icon class="item-icon" :name="item.icon"></svg-icon>
              <span v-if="menuOpenStatus" class="item-desc">{{ item.label }}</span>
            </li>
          </template>
          <div class="child-item__wrap">
            <div class="child-item" :class="{ active: $route.path.indexOf(subItem.value) > -1}" v-for="subItem in item.children" :key="subItem.value" @click="handleClick(subItem.value)">
              {{ subItem.label }}
            </div>
          </div>
        </el-popover>
      </template>
    </ul>
    <div class="bottom">
      <div @click="logout">
        <template v-if="menuOpenStatus">
          <svg-icon class="item-icon" name="tuichudenglu"></svg-icon>
          <span class="item-desc">{{ $t('app.header.account.logout') }}</span>
        </template>
        <el-tooltip
          v-else
          :effect="$isDark() ? 'dark' : 'light'"
          :content="$t('app.header.account.logout')"
          placement="right"
          :offset="10"
        >
          <div style="height: 100%; width: 100%;display: flex;align-items: center;">
            <svg-icon class="item-icon" name="tuichudenglu"></svg-icon>
          </div>
        </el-tooltip>
      </div>
      <div @click="changeMenu">
        <template v-if="menuOpenStatus">
          <svg-icon class="item-icon" :class="{ isOpen: menuOpenStatus }" name="zhankai2"></svg-icon>
          <span class="item-desc">{{ $t('action.fold') }}</span>
        </template>
        <el-tooltip
          v-else
          :effect="$isDark() ? 'dark' : 'light'"
          :content="$t('action.expand')"
          placement="right"
          :offset="10"
        >
          <div style="height: 100%; width: 100%;display: flex;align-items: center;">
            <svg-icon class="item-icon" :class="{ isOpen: menuOpenStatus }" name="zhankai2"></svg-icon>
          </div>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import emitter from '@/app/emitter.js'
import { t } from 'app/i18n'

const tabList = [
  {
    label: t('app.header.account.info'),
    value: 'account',
    icon: 'zhanghu'
  },
  {
    label: t('point_manager.title'),
    value: 'point',
    icon: 'fufei'
  },
  {
    label: t('subscription_manager.title'),
    value: 'subscription',
    icon: 'dingyue'
  },
  {
    label: t('order_manager.title'),
    value: 'order',
    icon: 'dingdan'
  },
  {
    label: t('app.header.account.team'),
    value: 'team',
    icon: 'tuandui',
    hasChild: true,
    children: [
      {
        label: t('team_manager.owner_team'),
        value: 'ownerteam'
      },
      {
        label: t('team_manager.invitation_received'),
        value: 'inviteteam'
      }
    ]
  }
]
const menuOpenStatus = ref(false)
const router = useRouter()
const handleClick = function (route) {
  if (route.name !== route) {
    router.push({ name: route })
  }
}

const logout = () => {
  emitter.emit('logout')
}

const changeMenu = () => {
  menuOpenStatus.value = !menuOpenStatus.value
}

</script>

<style lang="scss" scoped>
.sidebar-wrapper {
  width: 60px;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  transition-duration: 0.5s;
  &.isOpen {
    width: 180px;
  }
}
  .list-wrap{
    margin: 0;
    padding: 0;
    position: relative;
    // &::after {
    //   content: '';
    //   width:80%;
    //   left:10%;
    //   display: inline-block;
    //   height:1px;
    //   position: absolute;
    //   top: 194px;
    //   background-color:#3f4251;
    // }
    .list-item {
      margin: 0;
      width: 100%;
      list-style: none;
      display: flex;
      overflow: hidden;
      align-items: center;
      cursor: pointer;
      height: 46px;
      line-height: 46px;
      // &:last-child {
      //   margin-top:20px;
      // }
      .item-icon,
      .item-desc {
        font-size: 14px;
        color:var(--color-text-1);
        display: inline-block;
        flex-shrink: 0;
      }
      .item-icon {
        width: 16px;
        height: 18px;
        margin: 0 22px;
        line-height: 18px;
        font-size: 0;
      }
      &:hover {
        background: var(--sidebar-item-hover-bg);
      }
      &.active {
        background: #00ab7a;
        color: #fff;
        position: relative;
        svg {
          color: #fff;
        }
        span {
          color: #fff;
        }
        .item-icon {
          img {
            filter: brightness(1.5);
          }
        }
        &.hasChild {
          &::before {
            width: 0;
          }
        }
      }
    }
  }
  .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    // width: 60px;
    padding-bottom: 20px;
    div {
      width: 100%;
      margin: 0;
      list-style: none;
      display: flex;
      overflow: hidden;
      align-items: center;
      cursor: pointer;
      height: 46px;
      line-height: 46px;
      &:hover {
        background: rgba(0,0,0,.2)
      }
      .item-icon {
        width: 16px;
        height: 18px;
        margin: 0 22px;
        line-height: 18px;
        font-size: 0;
        color:var(--color-text-1);
        display: inline-block;
        flex-shrink: 0;
        transition: all 0.5s;
        &.isOpen {
          transform: rotate(180deg);
        }
      }
      .item-desc {
        font-size: 14px;
        color:var(--color-text-1);
        display: inline-block;
        flex-shrink: 0;
      }
    }
  }
</style>
