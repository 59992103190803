const state = {
  network: {
    tab: 'traffic.alerts',
    page: 1,
    index: 0
  },
  pid: 0
}

const mutations = {
  SET_NETWORK_DETAIL (state, payload) {
    state.network = {
      ...state.network,
      ...payload
    }
  },
  SET_CURRENT_PID(state, payload) {
    state.pid = payload
  }
}

const actions = {

}

const getters = {
  curTab(state) {
    return state.network.tab
  },
  page (state) {
    return state.network.page
  },
  index (state) {
    return state.network.index
  },
  pid (state) {
    return state.pid
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
