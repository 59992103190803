<template>
  <div class="home-footer">
    <div class="footer-wrap">
      <span class="copyright-brain">
        <span></span>
        <p>{{$t('app.footer.brain')}}</p>
      </span>
      <span class="copyright">
        <a>Copyright©2011-{{new Date().getFullYear()}} {{$t('app.footer.company')}} 京B2-20212862</a>
        <a
          class="copyright-link"
          href="https://beian.miit.gov.cn"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{$t('app.footer.icp')}}
        </a>
        <router-link class="copyright-link" target="_blank" to="/license">
          {{$t('app.footer.license')}}
        </router-link>
        <a
          class="copyright-link"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502041971"
          target="_blank"
          rel="noopener noreferrer"
          >{{$t('app.footer.net_security')}}</a
        >
      </span>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
.home {
  &-footer {
    height: 60px;
    box-shadow: 0 -1px 0 0 #7c7f8d33;
    background: var(--detect-config-bg);
    box-sizing: border-box;
    position: relative;
  }
}
@media screen and (min-width: 1900px) {
  .footer-wrap {
    flex-direction: row !important;
    .copyright-brain {
      margin: 0 !important;
    }
    .copyright {
      display: flex;
      align-items: center;
    }
  }
}
.footer-wrap {
  width: 100%;
  box-sizing: border-box;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .copyright-brain {
    margin: 0 auto;
    display: flex;
    width: 193px;
    align-items: center;
    // color: rgba(230,230,230,.6) !important;
    color: var(--color-text-3) !important;
    span {
      width: 16px;
      height: 16px;
      background-image: url('~@/assets/img/index/footer-brain.png');
      background-size: 100% 100%;
      margin-right: 8px;
      opacity: 0.8;
    }
    p {
      margin: 0;
      font-size: 12px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .copyright {
    color: var(--color-text-3) !important;
    padding: 0 12px;
    text-align: center;
    a {
      font-size: 12px;
      font-weight: 400;
      img {
        vertical-align: middle;
        margin-right: 4px;
        margin-bottom: 3px;
      }
    }
    a:link,
    a:visited {
      color: var(--color-text-3) !important;
      text-decoration: none;
    }
    .copyright-link {
      margin-left: 10px;
    }
  }
}
</style>
