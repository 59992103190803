import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import i18n from './i18n'
import installElementPlus from './plugins/element'
import installSocialSharing from './plugins/socialShare'
import installComponents from './components'
import installDirectives from './directives'
import installFilters from 'app/filter'
import installStyles from 'app/style'
import installEnums from 'app/enum'
import installI18ns from 'app/i18n'
import installBus from 'app/bus'
import 'echarts-liquidfill'

// 导入svgIcon
import './assets/js/iconfont'
// 导入全局样式
import 'normalize.css'
import 'animate.css'
import 'swiper/swiper-bundle.css'
import './styles/index.scss'

const app = createApp(App)
// 安装UI组件库
installElementPlus(app)
// 分享
installSocialSharing(app)
// 安装全局组件
installComponents(app)
// 安装全局指令
installDirectives(app)
// 安装全局过滤器
installFilters(app)
// 安装样式相关的全局函数
installStyles(app)
// 安装枚举的全局函数
installEnums(app)
// 安装i18n的全局函数
installI18ns(app)
// 安装busEvent
installBus(app)

app.use(store).use(router).use(i18n).mount('#app')

export default app
