export default {
  rule: {
    invalid: '{field}格式错误',
    inputRequired: '请输入{field}',
    selectRequired: '请选择{field}',
    lengthMin: '{field}长度必须大于 {min} 个字符',
    lengthMax: '{field}长度必须小于 {max} 个字符',
    lengthMinMax: '{field}长度必须是 {min} 到 {max} 个字符',
    userName: '{field}必须是3-64位中文、英文、数字、英文句点、中划线、下划线',
    password: '{field}必须包含大写字母、小写字母、数字、特殊字符',
    email: '请输入有效的邮箱',
    phone: '请输入有效的电话',
    verCode: '{field}必须是4位的字母或数字',
    confirm: '必须与{field}一致',
    url: '{field}必须是正确的URL',
    link: '{field}必须是正确的链接',
    name: '{field}必须是{min}-{max}位的中文、英文、数字、英文句点、中划线、下划线、空格',
    ip: '{field}必须是正确的IP',
    domain: '{field}必须是正确的DOMAIN',
    CIDR: '{field}必须是正确的网段地址',
    mask: '{field}必须是正确的掩码地址',
    tagRepeat: '标签不能重复',
    ioc: '{field}必须是正确的IOC',
    port: '{field}必须是正确的端口号[1-65535]'
  }
}
