import request from 'app/http'

const postTeamShareTask = (params) => {
  // 分享任务到团队
  return request.post('/team/share_task', params)
}
const getTeamShareTask = (params) => {
  // 获取团队的任务列表
  return request.get('/team/share_task', { params })
}
const postDeleteTask = (params) => {
  // 将任务移出团队
  return request.post('/team/delete/task', params)
}
const getTeamTask = (params) => {
  // 获取团队的任务列表
  return request.get('/team/task/list', { params })
}
const postTeamCooperation = (params) => {
  // 创建团队
  return request.post('/team/team_cooperation', params)
}
const putTeamCooperation = (params) => {
  // 修改团队信息
  return request.put('/team/team_cooperation', params)
}
const getTeamMembers = () => {
  // 获取团队成员列表
  return request.get('/team/team_members')
}
const postTeamMembers = (params) => {
  // 邀请团队成员
  return request.post('/team/team_members', params)
}
const putTeamMembers = (params) => {
  // 修改团队成员信息
  return request.put('/team/team_members', params)
}
const deleteTeamMembers = (params) => {
  // 移除团队成员
  return request.delete('/team/team_members', { data: params })
}
const getInviteUser = (params) => {
  // 查询邀请用户
  return request.get('/team/get_invite_user', { params })
}
const deleteTeamCooperation = (params) => {
  // 删除团队
  return request.delete('/team/team_cooperation', { data: params })
}
const getTeamSubmitter = (params) => {
  return request.get('/team/get_team_submitter', { params })
}

export {
  // getUserTeamList,
  postTeamShareTask,
  getTeamShareTask,
  postDeleteTask,
  getTeamTask,
  postTeamCooperation,
  putTeamCooperation,
  getTeamMembers,
  postTeamMembers,
  putTeamMembers,
  deleteTeamMembers,
  getInviteUser,
  deleteTeamCooperation,
  getTeamSubmitter
}
