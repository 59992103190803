export default {
  detection: {
    tab: {
      file: 'File',
      url: 'URL',
      hash: 'Hash',
      fileLabelText: 'Click or drag files here to upload to detect',
      urlPlaceholder: 'Input or paste URL to detect',
      hashPlaceholder: 'Input or paste MD5, SHA1 or SHA256 to detect'
    },
    reference: 'The advanced threat analysis information provided by 360 ATA Cloud service can only be used as a reference to assess security risks',
    file_list_name: {
      file: 'File submission list',
      url: 'URL submission list',
      hash: 'Hash submission list'
    },
    single_configurable_info: 'Single options',
    drag_add_file: 'Release the mouse to add file',
    file_upload_status: {
      file: {
        success: 'Uploaded',
        failed: 'Upload failed'
      },
      url: {
        success: 'Validated',
        failed: 'Validation failed'
      },
      hash: {
        success: 'Downloaded',
        failed: 'Download failed'
      }
    },
    running_map: {
      file: 'Uploading',
      url: 'Validating',
      hash: 'Downloading'
    },
    check_enums: {
      downloaded: 'Downloaded',
      download_failed: 'Download failed',
      verified: 'Validated',
      verify_failed: 'Validation failed',
      verify_failed_type_not_found: 'Unable to confirm URL type',
      content_to_large: 'Content too large',
      downloading: 'Downloading'
    },
    file_total_status: {
      size: 'Total size',
      num: 'File count',
      uploaded: 'Uploaded',
      uploading: 'Uploading',
      failed: 'Upload failed'
    },
    url_total_status: {
      url_num: 'URL count',
      verified: 'Validated',
      verifying: 'Validating',
      failed: 'Validation failed',
      size: 'Total size',
      file_num: 'File count',
      downloaded: 'Downloaded',
      downloading: 'Downloading',
      download_failed: 'Download failed'
    },
    totol_size_too_large: 'The total file size exceeds the submission limit',
    add_file: 'Add file',
    type_error: 'format error',
    permissions: {
      label: 'Task permission',
      active: 'public',
      inactive: 'private'
    },
    share_team: {
      label: 'Share to team',
      placeholder: 'Select team'
    },
    agreement: 'Agree to the {user}and{privacy}.Do not submit personal privacy information or information in violation of laws and regulations.', // 保留{user} {privacy}
    exsit_err_item: 'files with {0} in the list. Please clean them before submitting!',
    err_text: 'Upload failed',
    err_url_text: 'URL verification failed or download failed',
    user: '360 ATA Cloud User Agreement',
    privacy: '360 ATA Cloud Privacy Policy',
    submit: 'Submit',
    clean_up: 'Clean up',
    submit_after_clear: 'Failed to validate this URL, unable to set task configuration, please clear this item.!',
    textMap: {
      hash: {
        placeholder: 'Input or paste content to add MD5, SHA1 or SHA256 task',
        msg1: 'No hash in the list, please correct before submitting',
        msg2: 'Invalid hash in the list, please correct before submitting',
        msg3: 'Do not upload the same hashes'
      },
      url: {
        placeholder: 'Input or paste content to add URL task',
        msg1: 'no URL in the list, please correct before submitting',
        msg2: 'URL format error',
        msg3: 'Do not upload the same URLs'
      }
    },
    errText: {
      empty: 'There are no configurable tasks, please wait for the processing to complete or add a new item.',
      progress: 'The upload of this file has not been completed{0}, so the task configuration cannot be set temporarily.',
      failed: 'Failed to upload this file{0}, unable to set task configuration, please clear this item.'
    },
    slot: {
      download: 'Download',
      upload: 'Upload',
      verify: 'Validat'
    },
    back_global: 'Return to global options',
    gloabal_configuration: 'Global options',
    single_configuration: 'Single options',
    use_gloabal_configuration: 'Use global options',
    file_size_limit: 'File size exceeds limit {0}',
    folder_limit: 'Do not upload folders',
    empty_limit: {
      message: 'Do not upload empty files',
      tooltip: 'File is empty'
    },
    duplicated_name_limit: 'Do not upload files with same name',
    err_config_item: 'Error in config items. Please correct before proceeding',
    submitting: 'Task submission in progress',
    submit_success: 'File task submission completed',
    submit_failed: 'Submission failed due to network delay. Please resubmit',
    url_submit_error: 'URL format error, please modify before submitting',
    err_agreement: 'Please read and agree to the 360 ATA Cloud User Agreement and 360 ATA Cloud Privacy Policy',
    uploadStatus: {
      101: 'File does not exist',
      102: 'File is empty',
      103: 'File read failed',
      104: 'File upload failed',
      105: 'File size exceeds limit',
      106: 'Task count exceeds limit',
      107: 'URL format error',
      108: 'URL domain resolution failed',
      109: 'URL host access failed',
      110: 'URL page access failed',
      111: 'URL file download failed',
      112: 'URL file size exceeds limit',
      113: 'Hash file download failed',
      114: 'Hash file size exceeds limit',
      115: 'Task quota exceeds limit',
      116: 'Hash value does not exist',
      140: 'Server internal error',
      150: 'Unknown error'
    }
  },
  config: {
    basic: {
      title: 'Basic Detection Config',
      strategy: {
        title: 'Fast detection',
        close: 'Close fast detection',
        intelligent: 'Auto fast detection',
        enforce: 'Force fast detection'
      },
      os: {
        title: 'OS',
        suffix: '-bit'
      },
      lang: {
        title: 'Language',
        zh_label: 'Simplified Chinese',
        en_label: 'English',
        map: {
          'zh-cn': 'Chinese',
          'en-us': 'English',
          'ko-kr': 'Korean',
          // eslint-disable-next-line quote-props
          'any': 'Multi-Language'
        },
        suffix: '-bit'
      },
      software_major: {
        title: 'Application',
        placeholder: 'Please select',
        non_select: 'No optional applications available'
      },
      system_detail: {
        title: 'OS and application version details',
        show: 'Show',
        hide: 'Hide'
      }
    },
    dynamic: {
      title: 'Dynamic Detection Config',
      open_in_browser: 'Open URL in browser',
      custom_detect_type: {
        title: 'File type',
        placeholder: 'Auto recognize (default)'
      },
      unzip_password: {
        title: 'Decompress password',
        placeholder: 'Enter the password to extract for compressed files'
      },
      specify_subfiles: {
        title: 'Specify sub files in compressed files',
        md5: {
          title: 'Specify MD5',
          placeholder: 'Enter MD5 of sub file to execute'
        },
        file_name: {
          title: 'Specify file name',
          placeholder: 'Enter file name of sub file to execute'
        },
        file_type: {
          title: 'Specify file type',
          placeholder: 'Not specified'
        }
      },
      pwd_to_mso: {
        title: 'Document password',
        placeholder: 'Enter viewing password for document files'
      },
      app_forced: 'Open document in Application',
      emailer: {
        title: 'Email file handle method',
        placeholder: 'Select email file handle method',
        options: {
          execute_attach: 'Extract attachments and execute',
          outlook: 'Open with Outlook'
        }
      },
      cmd: {
        title: 'Command line',
        placeholder: 'Input command line for the execution process'
      },
      function: {
        title: 'DLL parameters',
        placeholder: 'Input function',
        arguments: 'Input parameter value. Multiple parameters are separated by spaces'
      },
      name_forced: {
        title: 'Custom file name and extension',
        placeholder: 'Input custom file name',
        ext_forced: 'Input custom extension'
      },
      runpath: {
        title: 'Execute process under specified directory',
        placeholder: 'Auto setting',
        options: {
          auto: 'Auto setting',
          desktop: 'Desktop',
          document: 'Document directory',
          user: 'User directory',
          download: 'Download directory',
          appdata: 'UserData directory',
          temp: 'Temp directory',
          windows: 'System directory',
          rootdir: 'Root directory'
        }
      }
    },
    strategy: {
      title: 'Detection Policy Config',
      timeout: {
        title: 'Detection duration',
        suffix: 's',
        professional: 'Pro plan supports up to 360s',
        enterprise: 'Pro Plus plan supports up to 660s'
      },
      android_human: 'Simulate clicking',
      human: 'Human operation simulation',
      human_click: 'Auto clicking',
      human_scroll: 'Auto scrolling',
      human_clipbd: 'Clipboard padding',
      human_curpos: 'Mouse movement',
      human_autoin: 'Auto installation',
      fast_detect: 'Fast dynamic detection',
      fake_reboot: 'Simulate reboot',
      active_reboot: 'Active reboot',
      pwd_forced: 'Violent password cracking',
      timezone: {
        title: 'Time zone',
        placeholder: 'Auto setting'
      },
      geo: {
        title: 'Location',
        placeholder: 'Auto setting'
      },
      datetime: {
        title: 'System date and time',
        placeholder: 'Auto setting'
      },
      env_var: {
        title: 'Environment variable',
        key_placeholder: 'Input variable name',
        value_placeholder: 'Input variable value'
      }
    },
    internet: {
      title: 'Network Detection Config',
      connect: 'Internet connection',
      decrypt_https: 'Decrypt HTTPS traffic'
    },
    need_professional: 'Need to upgrade to Pro plan or Pro Plus plan',
    need_enterprise: 'Need to upgrade to Pro Plus plan',
    min_timeout: 'Active reboot detection requires ensuring that Detection duration is not less than 120 seconds'
  }
}
