export default {
  notice: {
    marked: '标记为已读',
    deleteMessage: '删除消息',
    receiveTime: '接收时间',
    messageType: '消息类型',
    messageTitle: '消息标题',
    deleteDesc: '消息删除不可恢复，请确认是否删除消息？'
  }
}
