import { LANG } from '@/constants'
import { getItem, setItem } from '@/utils/storage'
export default {
  namespaced: true,
  state: () => ({
    value: getItem(LANG) || 'zh'
  }),
  mutations: {
    /**
     * 设置国际化
     */
    setLanguage(state, lang) {
      setItem(LANG, lang)
      state.value = lang
    }
  },
  actions: {}
}
