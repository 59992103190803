import request from 'app/http'

const getMessageList = (params) => {
  // 获取当前用户消息列表
  return request.get('/message/message_list', { params })
}
const putMessage = (params) => {
  // 修改消息状态（未读变已读）
  return request.post('/message/mark/read', params)
}
const deleteMessage = (params) => {
  // 删除消息
  return request.post('/message/delete', params)
}

const getTeamMessage = (params) => {
  return request.get('/team/invitation', { params })
}

const postConsumeMesssage = (params) => {
  // 修改消息状态, 接受，拒绝等
  return request.post('/team/invitation', params)
}
export {
  getMessageList,
  putMessage,
  deleteMessage,
  getTeamMessage,
  postConsumeMesssage
}
