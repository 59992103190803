import { getTeamMembers } from 'services/team'

const state = {
  teamlist: [],
  shared_ids: []
}

const mutations = {
  SET_TEAMLIST (state, payload) {
    state.teamlist = payload
  },
  SET_SHARED_IDS(state, ids) {
    state.shared_ids = ids
  }
}

const actions = {
  getTeamMembers ({ commit }) {
    return new Promise((resolve, reject) => {
      getTeamMembers().then(res => {
        commit('SET_TEAMLIST', res)
        resolve()
      })
    })
  }
}

const getters = {
  teamList: state => state.teamlist,
  shared_ids: state => state.shared_ids
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
