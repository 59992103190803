<template>
  <el-dialog
    title="登录"
    :model-value="$store.getters.loginOpened"
    @close="onClose"
    width="22%"
  >
    <div>360登录</div>
    <div>github登录</div>
  </el-dialog>
</template>

<script setup>
import { useStore } from 'vuex'

const store = useStore()

const init = async () => {}

const onClose = () => {
  store.commit('app/toggleLoginOpened')
}

init()
</script>

<style lang="scss" scoped></style>
