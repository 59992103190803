<template>
  <div class="back-top">
    <AppChat :showGpt="showGpt" />
    <el-tooltip class="item" :effect="$isDark() ? 'dark' : 'light'" :content="$t('app.home.questionnaire')" placement="left">
      <span @click="open('https://wenjuan.lap.360.cn/sv/6e614d38d7d4f')"></span>
    </el-tooltip>
    <el-tooltip class="item" :effect="$isDark() ? 'dark' : 'light'" :content="$t('app.home.contact')" placement="left">
      <span @click="open('/help/feedback')"></span>
    </el-tooltip>
    <el-tooltip class="item" :effect="$isDark() ? 'dark' : 'light'" :content="$t('app.home.backtop')" placement="left">
       <span :class="{ hide: backTopHide }" @click="handleScrollTop"></span>
    </el-tooltip>
  </div>
</template>

<script setup>
import AppChat from '../app/app-chat'
import { ref, defineProps, onBeforeMount, onUnmounted } from 'vue'

const backTopHide = ref(true)

const props = defineProps({
  isContainer: {
    default: '',
    type: String
  },
  showGpt: {
    default: false,
    type: Boolean
  }
})

const handleScrollTop = () => {
  props.isContainer ? document.querySelector(props.isContainer).scrollTo({ top: 0, behavior: 'smooth' }) : window.scrollTo({ top: 0, behavior: 'smooth' })
}
const handleScroll = (e) => {
  const top = props.isContainer ? document.querySelector(props.isContainer).scrollTop : document.documentElement.scrollTop
  if (top > 100) {
    backTopHide.value = false
  } else {
    backTopHide.value = true
  }
}
const open = (url) => {
  window.open(url)
}

onBeforeMount(() => {
  if (props.isContainer) {
    try {
      setTimeout(() => {
        document.querySelector(props.isContainer) && document.querySelector(props.isContainer).addEventListener('scroll', handleScroll)
      }, 2000)
    } catch (error) {
    }
  } else {
    window.addEventListener('scroll', handleScroll)
  }
})
onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<style lang="scss" scoped>

[data-theme='light'] .back-top {
  span {
    box-shadow: 0 1px 5px 0 #c7c7c780;
    &:nth-child(5)  {
      background-image: url('~@/assets/img/index/Questionnaire-l.svg');
    }
    &:nth-child(6)  {
      background-image: url('~@/assets/img/index/customer-service-l.svg');
    }
    &:nth-child(7)  {
      background-image: url('~@/assets/img/index/back-top-l.svg');
    }
  }
}
.back-top  {
  // position: fixed;
  // z-index: 20000;
  // right: 30px;
  // bottom: 100px;
  // cursor: pointer;
  // visibility: visible;
  span  {
    position: fixed;
    z-index: 20000;
    right: 30px;
    bottom: 100px;
    border-radius: 22px;
    cursor: pointer;
    display: block;
    width: 44px;
    height: 44px;
    background-color: var(--home-map-btn-bg);
    border-radius: 100%;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    // margin-bottom: 12px;
    &:nth-child(5)  {
      bottom: 212px;
      background-image: url('~@/assets/img/index/Questionnaire.svg');
      &:hover  {
        background-image: url('~@/assets/img/index/Questionnaire-active.svg');
      }
    }
    &:nth-child(6)  {
      bottom: 156px;
      background-image: url('~@/assets/img/index/customer-service.svg');
      &:hover  {
        background-image: url('~@/assets/img/index/customer-service-active.svg');
      }
    }
    &:nth-child(7)  {
      bottom: 100px;
      background-image: url('~@/assets/img/index/back-top.svg');
      &:hover  {
        background-image: url('~@/assets/img/index/back-top-active.svg');
      }
      margin-bottom: 0;
    }
    &:hover  {
      // filter: brightness(1.3);
    }
    &.hide  {
      visibility: hidden;
    }
  }
}
</style>
