import request from 'app/http'

export function login(data) {
  return request({ url: '/authentication/login', method: 'post', data })
}

export function logout() {
  return request({ url: '/authentication/logout', method: 'post' })
}

export function getUserInfo() {
  return request({ url: '/account/user', method: 'get' })
}

export function getAccountProfile() {
  return request.get('/account/profile')
}

export function getTiToken() {
  return request.get('/account/get/ti/token')
}

export function getAccountSign(params) {
  return request.get('/ti/auth_login', { params })
}

export function checkLogin() {
  return request.get('authentication/is_login')
}

export function loginGithub(params) {
  return request({
    url: '/authentication/github/login/redirect',
    method: 'get',
    params
  })
}

export function wx_auth(data) {
  return request({ url: '/wx/auth', method: 'post', data })
}
