export default {
  docs: {
    title: 'Help Docs',
    platform: 'Site Guides',
    introduction: 'Site Intro',
    api: 'API Guides',
    api_explain: ' API Intro',
    api_uselimit: 'User Limit',
    api_list: 'Api List',
    submit: 'Submit Tasks',
    search: 'Search Tasks',
    get_submitlist: 'Get Submit List',
    search_taskstatus: 'Get Task Status',
    get_taskoverview: 'Get Task Overview',
    terms: 'User Agreement',
    privacy: 'Privacy Policy',
    faq: 'FAQ'
  },
  consulting: {
    title: 'Purchase Consultation',
    remain: 'Number of remaining contacts today',
    name: {
      label: 'Name',
      placeholder: 'Please type in your name',
      null: 'Please type in your name',
      invalid: 'Contact names can only contain Chinese characters, letters, and numbers.'
    },
    email: {
      label: 'Email Address',
      placeholder: 'Please input the email address',
      invalid: 'The email address does not meet the requirements, please re-enter it.',
      length_limited: 'Email length cannot be greater than 30 characters'
    },
    phone: {
      label: 'Phone Number',
      placeholder: 'Please enter the phone number',
      invalid: 'Mobile phone number does not meet the requirements, please re-enter'
    },
    org: {
      label: 'Company Name',
      placeholder: 'Please enter company name',
      check: 'Please enter company name',
      invalid: 'The company name can only contain Chinese characters, letters, and numbers.'
    },
    count: 'Analyses per month',
    count_invalid: 'Please enter a number above 0',
    count_placeholder: 'Please enter the monthly analysis quantity',
    purpose: 'Contact purpose',
    purpose_invalid: 'Please select the purpose of contact',
    pro: 'Consult the Pro plan',
    pro_plus: 'Consult the Pro Plus plan',
    enterprise: 'Consult the Enterprise plan.',
    local: 'Consult the local sandbox',
    point_purchase: 'Inquire about points purchase',
    renewal: 'Consultation on subscription renewal',
    needs: 'message',
    needs_placeholder: 'Please enter a message',
    no_remain: 'The number of contacts today has been exhausted',
    success: 'The contact information has been sent successfully, we will contact you within 2 working days'
  },
  feedback: {
    title: 'Feedback',
    remain: 'Number of feedbacks remaining today',
    type: 'feedback type',
    analysis_result: 'Analysis result feedback',
    product_feature: 'Product feature feedback',
    other_info: 'Other information feedback',
    content: 'message',
    content_placeholder: 'Please enter a message',
    type_invalid: 'Please enter feedback type',
    content_invalid: 'Please enter feedback content',
    success: 'Feedback has been submitted successfully, thank you for your feedback',
    history: 'Feedback history',
    delete: 'Delete feedback',
    id: 'Feedback ID',
    create_time: 'Submission time',
    label_content: 'message',
    dialog: {
      title: 'Attention',
      attention: 'After deleting the feedback information, the information cannot be retrieved. Please confirm whether to delete it?'
    }
  },
  wiki: {
    title: 'Threat Wiki',
    dev: 'Under development, please stay tuned!'
  }
}
