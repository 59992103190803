import request from 'app/http'

const getGlobalViewMonitor = params => {
  return request.get('/stats/globalview/realtime_monitor/', { params })
}
const getGlobalViewAnalysisresult = params => {
  return request.get('/stats/globalview/analysisresult/', { params })
}
const getGlobalViewThreatmap = params => {
  return request.get('/stats/globalview/threatmap/', { params })
}
const getGlobalViewHotTags = params => {
  return request.get('/stats/globalview/toptags/', { params })
}
const getGlobalViewHotOrgs = params => {
  return request.get('/stats/globalview/hottags/', { params })
}
export {
  getGlobalViewMonitor,
  getGlobalViewAnalysisresult,
  getGlobalViewThreatmap,
  getGlobalViewHotTags,
  getGlobalViewHotOrgs
}
