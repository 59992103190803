export default {
  home: {
    navList: {
      detect: 'SUBMIT',
      taskhub: 'REPORTS',
      help: 'HELP',
      ti: 'SECURITY BRAIN',
      price: 'PRICING',
      screen: 'TRENDS',
      tracked: 'TRACKER'
    },
    copyright: 'Copyright©2011-2023 360.net 版权所有 三六零数字安全科技集团有限公司',
    icpNum: '京ICP证080047号',
    icpCopyNum: '京ICP备20015286号-8',
    internetNum: '京公网安备11010502041971号'
  }
}
