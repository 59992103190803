import request from 'app/http'
import qs from 'qs'

const getTaskReportOverview = (params, token) => {
  if (token) {
    return request.get(`/task/report/overview/${params}?_token=${token}`)
  }
  return request.get(`/task/report/overview/${params}`)
}

const getTaskReportTags = (params) => {
  return request.get(`/task/report/tags/${params}`)
}

const getTaskUserSingle = (params) => {
  return request.get('/task/view', { params })
}

const postTaskReanalysis = (params) => {
  return request.post(`/task/reanalysis/${params}`)
}

const postTaskSubmitReanalysis = (params) => {
  return request.post('/task/submit/reanalysis', params)
}

const getTaskReportBehaviorEventsByPid = ({
  taskid,
  pid,
  eventType,
  mainkey,
  page
}) => {
  return request.get(
    `/task/report/behavior/events/${taskid}/${pid}/${eventType}`,
    {
      params: {
        mainkey,
        page,
        size: 50
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, {
          indices: false
        })
      }
    }
  )
}

const getTaskReportBehaviorProcess = (params) => {
  return request.get(`/task/report/behavior/process/${params}`)
}

const getTaskReportBehaviorNetwork = (id, params) => {
  return request.get(`/task/report/behavior/network/${id}`, { params })
}

const getTaskReportBehaviorSignatures = (taskid, pid) => {
  return request.get(`/task/report/behavior/signatures/${taskid}/${pid}`)
}

const getTaskReportStatic = (params) => {
  return request.get(`/task/report/static/${params}`)
}

const getTaskReportNetwork = (taskId, params) => {
  return request.get(`/task/report/network/${taskId}`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, {
        indices: false
      })
    }
  })
}

const getTaskReportShots = (params) => {
  return request.get(`/task/report/shots/${params}`)
}

const getResubmitConfig = (params) => {
  return request.get(`/task/report/resubmit/config/${params}`)
}

const getTaskReportDropped = (id, params) => {
  return request.get(`/task/report/dropped/${id}`, {
    params,
    paramsSerializer: params => {
      return qs.stringify(params, {
        indices: false
      })
    }
  })
}

const getTaskReportThreat = (id, params) => {
  return request.get(`/task/report/threaten/quota/${id}`, {
    params,
    paramsSerializer: params => {
      return qs.stringify(params, {
        indices: false
      })
    }
  })
}

const getImage = (params) => {
  return request.get(`/images/images/${params}`)
}

const downloadSample = (params) => {
  return request({
    url: params,
    method: 'get',
    responseType: 'blob'
  })
}

const getTaskReportMemory = (id, params) => {
  return request.get(`/task/report/memdumps/${id}`, {
    params,
    paramsSerializer: params => {
      return qs.stringify(params, {
        indices: false
      })
    }
  })
}

const getTaskReportZserver = (params) => {
  return request.get(`/task/report/zserver/${params}`)
}

const getTaskReportZserverInfo = (params) => {
  return request.get(`/task/report/zserver_info/${params}`)
}

const deleteReport = (params) => {
  return request.get(`/task/delete/${params}`)
}
const getTaskReportGraph = (params) => {
  return request.get(`/task/report/associate/analysis/${params}`)
}

const getTaskReportGraphExpand = (params) => {
  return request.get(`/task/report/associate/analysis/task/${params}`)
}

const getMenus = () => {
  return request.get(`/menu/get/task/menus`)
}

const setMenus = (params) => {
  return request.post(`/menu/set/task/menus`, params)
}

const downloadReportPDF = (id) => {
  return request({
    url: `/task/report/download/pdf/${id}`,
    method: 'get',
    responseType: 'blob'
  })
}

const getDownloadPcapUrl = (params) => {
  return request.get(`/task/get/pcap/${params}`)
}

const getVncToken = (params) => {
  return request.get(`/task/report/vnc/${params}`)
}

export {
  getTaskReportOverview,
  getTaskReportTags,
  getTaskUserSingle,
  postTaskSubmitReanalysis,
  getTaskReportBehaviorEventsByPid,
  getTaskReportBehaviorProcess,
  getTaskReportBehaviorNetwork,
  getTaskReportBehaviorSignatures,
  getTaskReportStatic,
  getTaskReportNetwork,
  getTaskReportShots,
  getTaskReportDropped,
  getTaskReportThreat,
  postTaskReanalysis,
  getImage,
  downloadSample,
  getTaskReportMemory,
  getTaskReportZserver,
  getTaskReportZserverInfo,
  deleteReport,
  getTaskReportGraph,
  getTaskReportGraphExpand,
  getMenus,
  setMenus,
  downloadReportPDF,
  getDownloadPcapUrl,
  getVncToken,
  getResubmitConfig
}
