export default {
  home: {
    navList: {
      detect: '提交分析',
      taskhub: '任务中心',
      help: '帮助中心',
      price: '升级服务',
      screen: '威胁态势',
      tracked: '威胁追踪'
    },
    copyright: 'Copyright©2005-2021 360.cn 版权所有 360互联网安全中心',
    icpNum: '京ICP证080047号',
    icpCopyNum: '京ICP备20015286号-8',
    internetNum: '京公网安备11010502041971号'
  }
}
