const state = {
  filter: {
    tasktype: '',
    score: '',
    tag: '',
    filetype: '',
    hash: ''
  }
}

const mutations = {
  SET_OPTIONS (state, payload) {
    state.filter = { ...state.filter, ...payload }
  }
}

const actions = {

}

const getters = {
  score (state) {
    return state.filter.score
  },
  tasktype (state) {
    return state.filter.tasktype
  },
  tag (state) {
    return state.filter.tag
  },
  filetype (state) {
    return state.filter.filetype
  },
  hash (state) {
    return state.filter.hash
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
