import Cookies from 'js-cookie'

export function getCookie(name) {
  return Cookies.get(name)
}

export function setCookie(name, val, options = {}) {
  Cookies.set(name, val, options)
}

export function removeCookie(name, options = {}) {
  Cookies.remove(name, options)
}
