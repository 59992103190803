/**
 * http-code
 */
module.exports = {
  HTTP_CODE_SUCCESS: 0, // 成功
  HTTP_CODE_ERROR_AUTH: 401, // token 错误
  HTTP_CODE_ERROR_FORBIDDEN: 403, // 认证失败
  HTTP_CODE_ERROR_FILE: 406, // 提交的文件名内容不合规
  HTTP_CODE_ERROR_OTHER: 404, // 其他错误
  HTTP_CODE_ERROR_LIMIT: 429, // 用户提交下载超过限制
  HTTP_CODE_ERROR_SERVER: 500 // 文件名内容不合规
}
