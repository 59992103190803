export default {
  permise: {
    visitor: {
      fileSize: {
        msg: '文件大小超出20M限制，升级到专业版可解锁 50M'
      },
      // 每次个数
      once: {
        msg: '单次提交超过 1个样本 限制，升级到专业版可解锁 单次提交20个'
      },
      // 每分钟次数
      minTimes: {
        msg: '提交频率超出 4次/分钟 限制，升级到专业版可解锁 30次/分钟'
      },
      dayTimes: {
        msg: '本日提交样本数量已超过 100个/日 限制，升级到专业版可解锁 500个/日'
      },
      monthTimes: {
        msg: '本月提交样本数量已超过 3000个/月 限制，升级到专业版可解锁 15000个/月'
      }
    },
    free: {
      fileSize: {
        msg: '文件大小超出20M限制，升级到专业版可解锁 50M'
      },
      // 每次个数
      once: {
        msg: '单次提交超过 1个样本 限制，升级到专业版可解锁 单次提交20个'
      },
      // 每分钟次数
      minTimes: {
        msg: '提交频率超出 10次/分钟 限制，升级到专业版可解锁 30次/分钟'
      },
      dayTimes: {
        msg: '本日提交样本数量已超过 100个/日 限制，升级到专业版可解锁 500个/日'
      },
      monthTimes: {
        msg: '本月提交样本数量已超过 3000个/月 限制，升级到专业版可解锁 15000个/月'
      }
    },
    professional: {
      fileSize: {
        msg: '文件大小超出50M限制，升级到专业增强版可解锁 100M'
      },
      once: {
        msg: '单次提交超过 20个样本 限制，升级到专业增强版可解锁 单次提交40个'
      },
      minTimes: {
        msg: '提交频率超出 30次/分钟 限制，升级到专业增强版可解锁 60次/分钟'
      },
      dayTimes: {
        msg: '本日提交样本数量已超过 500个/日 限制，升级到专业增强版可解锁 5000个/日'
      },
      monthTimes: {
        msg: '本月提交样本数量已超过 15000个/月 限制，升级到专业增强版可解锁 150000个/月'
      }
    },
    enterprise: {
      fileSize: {
        msg: '文件大小超出100M限制'
      },
      once: {
        msg: '单次提交超过 40个样本 限制'
      },
      minTimes: {
        msg: '提交频率超出 60次/分钟 限制'
      },
      dayTimes: {
        msg: '本日提交样本数量已超过 5000个/日 限制'
      },
      monthTimes: {
        msg: '本月提交样本数量已超过 150000个/月 限制'
      }
    },
    qihoo: {
      fileSize: {
        msg: '文件大小超出100M限制'
      },
      once: {
        msg: '单次提交超过 40个样本 限制'
      },
      minTimes: {
        msg: '提交频率超出 600次/分钟 限制'
      },
      dayTimes: {
        msg: '本日提交样本数量已超过 2000000个/日 限制'
      },
      monthTimes: {
        msg: '本月提交样本数量已超过 6000000个/月 限制'
      }
    }
  }
}
