/**
 * 本地持久化存储的KEY值
 */
// token
export const TOKEN = 'TOKEN'
// 国际化
export const LANG = 'LANG'
// 主题
export const THEME = 'THEME'
// gpt
export const GPT_LIST = 'GPT_LIST'
