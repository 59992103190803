const getters = {
  token: (state) => state.user.token,
  userInfo: (state) => state.user.userInfo,
  isLogin: (state) => JSON.stringify(state.user.userInfo) !== '{}',
  gid: (state) => state.user.userInfo.gid || 0,
  theme: (state) => state.theme.value,
  language: (state) => state.language.value,
  isIntranet: (state) => state.app.isIntranet,
  loginOpened: (state) => state.app.loginOpened,
  authHeader(state) {
    if (state.token) {
      // return `Bearer ${state.token}`
      return state.token
    }
    return ''
  },
  username(state) {
    return state.username
  },
  getReportState(state) {
    return state.reportOpen
  },
  getReportTab(state) {
    return state.reportTab
  },
  getLoginState(state) {
    return state.login
  },
  getUserInfo(state) {
    return state.user.userInfo
  },
  roles(state) {
    return state.user.userInfo.group
  },
  scoreInfo(state) {
    return state.scoreInfo
  }
}
export default getters
