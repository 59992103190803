import request from 'app/http'

const getScoreInfo = () => {
  return request.get('/score/get_user_score_info')
}

const getScoreList = (params) => {
  return request.get('/score/get_record_list', { params })
}

const postPurchaseByScore = (params) => {
  return request.post('/score/purchase_by_score', params)
}

export {
  getScoreInfo,
  postPurchaseByScore,
  getScoreList
}
