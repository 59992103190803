<template>
  <div class="app-menu-home">
    <div
      v-for="item in menuList"
      :key="item.path"
      class="app-menu-home__item"
      @click="onClick(item)"
    >
      {{ $t(`app.menu.${item.name}`) }}
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useState } from '@/hooks/useVuex'

const { tiToken } = useState('user', ['tiToken'])

const router = useRouter()

const menuList = computed(() => {
  const tiPath = tiToken.value ? `https://ti.360.net/#/homepage?sign=${tiToken.value}&from=ata` : 'https://ti.360.net/#/homepage'
  return [
    {
      path: '/detect',
      name: 'submit'
    },
    {
      path: '/taskhub',
      name: 'task'
    },
    // {
    //   path: '/hunting',
    //   name: 'hunting'
    // },
    {
      path: '/help',
      name: 'help'
    },
    {
      path: '/price',
      name: 'upgrade'
    },
    {
      path: tiPath,
      name: 'ti',
      type: 'open'
    },
    {
      path: '/screen/',
      name: 'trends',
      type: 'open'
    },
    {
      path: '/tracked',
      name: 'track'
    }
  ]
})

// const menuList = ref([
//   {
//     path: '/detect',
//     name: 'submit'
//   },
//   {
//     path: '/taskhub',
//     name: 'task'
//   },
//   {
//     path: '/help',
//     name: 'help'
//   },
//   {
//     path: '/price',
//     name: 'upgrade'
//   },
//   {
//     path: 'https://ti.360.net',
//     name: 'ti',
//     type: 'open'
//   },
//   {
//     path: 'https://ata.360.net/screen/',
//     name: 'trends',
//     type: 'open'
//   },
//   {
//     path: '/tracked',
//     name: 'track'
//   }
// ])

const onClick = (item) => {
  if (item.type === 'open') {
    window.open(item.path, '_blank')
  } else {
    router.push(item.path)
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
.app-menu-home {
  height: $header-height;
  display: flex;
  align-items: center;
  &__item {
    position: relative;
    display: inline-block;
    // width: 104px;
    padding: 0 18px;
    height: $header-height;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-text-1);
    cursor: pointer;
    &:hover {
      color: var(--color-priamry);
    }
  }
}
@media screen and (max-width: 1500px) {
  .app-menu-home {
    &__item {
      padding: 22px 13px;
    }
  }
}
</style>
