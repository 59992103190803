import axios from 'axios'
import store from '@/store'
import router from '@/router'
import emitter from './emitter'
import qs from 'qs'
import { splitUnit } from 'utils/tools'
import {
  HTTP_CODE_SUCCESS,
  HTTP_CODE_ERROR_AUTH,
  HTTP_CODE_ERROR_FORBIDDEN,
  HTTP_CODE_ERROR_FILE,
  HTTP_CODE_ERROR_OTHER,
  HTTP_CODE_ERROR_LIMIT,
  HTTP_CODE_ERROR_SERVER
} from '@/constants'
import { getCookie } from 'utils'
import { t } from 'app/i18n'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 500000,
  withCredentials: true
})

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // csrf
    const csrftoken = getCookie('csrftoken')
    if (csrftoken !== undefined) {
      config.headers['X-CSRFToken'] = csrftoken
    }
    // jwt
    if (store.getters.token) {
      // config.headers.Authorization = `Bearer ${store.getters.token}`
      config.headers.Authorization = store.getters.token
    }
    config.headers.lang = store.getters.language
    if (config.method === 'get') {
      config.paramsSerializer = (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    if (Object.keys(response.data).includes('code')) {
      if (response.data.code === HTTP_CODE_SUCCESS) {
        return response.data.data
      } else {
        return response.data
      }
    } else if (response.data.type === 'application/octet-stream' || response.data.type === 'application/zip') {
      return response
    } else {
      return response.data
    }
  },
  (error) => {
    const { status, config } = error.response
    if (status === HTTP_CODE_ERROR_AUTH) {
      store.commit('user/logout')
      // 判断是否需要弹出登录确认按钮
      emitter.emit('showLogin')
      if (config.url === '/account/get/user') {
        return Promise.resolve(error.response.data)
      }
    } else if (status === HTTP_CODE_ERROR_FORBIDDEN) {
      router.push('/')
    } else if (status === HTTP_CODE_ERROR_FILE) {
      store.commit('SHOWMESSAGE', { type: 'error', message: t('other.http.errType'), duration: 800 })
    } else if (status === HTTP_CODE_ERROR_LIMIT) {
      // const keymay = {
      //   free: '免费版',
      //   professional: '专业版',
      //   enterprise: '专业增强版'
      // }
      // const groupMap = {
      //   sample: '样本',
      //   pcap: 'PCAP',
      //   memdumps: '释放内存',
      //   dropped: '释放文件'
      // }
      const keymay = {
        free: t('price.free'),
        professional: t('price.speciality'),
        enterprise: t('price.professional')
      }
      const groupMap = {
        sample: t('report.behavior.relation.start.sample'),
        pcap: 'PCAP',
        memdumps: t('report.report_tabs.memdump'),
        dropped: t('report.report_tabs.dropped')
      }
      const { data } = error.response
      if (data.text) {
        data.text().then(resData => {
          const blobData = JSON.parse(resData)
          const nextLevel = blobData.rate.split('/')[1]
          const nextLevelUnit = blobData.next_level ? splitUnit(nextLevel) : { unit: '', count: '' }
          const nextLevelCount = blobData.next_level ? blobData.next_group_limit[nextLevel] : 0
          const currentLevelUnit = splitUnit(blobData.rate.split('/')[1])
          const currentLevelCount = blobData.limit
          const type = blobData.rate.split('/')[1].includes('m') ? 'frequency' : 'limit'
          let str = ''
          if (blobData.group === 'download.pdf') {
            if (type === 'frequency') {
              store.commit('SHOWMESSAGE', { type: 'error', message: t('other.http.errDownloadPdf'), duration: 3000 })
            } else {
              // str = `下载PDF超过${currentLevelCount}个/${currentLevelUnit.unit}限制${blobData.next_level ? `, 升级到${keymay[blobData.next_level]}可解锁${nextLevelCount}个/${nextLevelUnit.unit}` : ''}`
              // const slotStr = blobData.next_level ? `, 升级到${keymay[blobData.next_level]}可解锁${nextLevelCount}个/${nextLevelUnit.unit}` : ''
              const slotStr = blobData.next_level ? t('other.http.httpCodeErrUpgrade1', [keymay[blobData.next_level], nextLevelCount, nextLevelUnit.unit]) : ''
              str = t('other.http.httpCodeErr1', [currentLevelCount, currentLevelUnit.unit, slotStr])
              store.commit('SHOWFREQUENCY', str)
            }
          } else {
            if (type === 'frequency') {
              // str = `提交频率超出${currentLevelCount}次/${currentLevelUnit.unit}限制${blobData.next_level ? `, 升级到${keymay[blobData.next_level]}可解锁${nextLevelCount}次/${nextLevelUnit.unit}` : ''}`
              const slotStr = blobData.next_level ? t('other.http.httpCodeErrUpgrade2', [keymay[blobData.next_level], nextLevelCount, nextLevelUnit.unit]) : ''
              str = t('other.http.httpCodeErr2', [currentLevelCount, currentLevelUnit.unit, slotStr])
              store.commit('SHOWFREQUENCY', str)
            } else {
              if (blobData.group.includes('download')) {
                const fileType = groupMap[blobData.group.split('.')[1]] || t('report.behavior.relation.start.sample')
                // str = `下载${fileType}超过${currentLevelCount}个/${currentLevelUnit.unit}的限制${blobData.next_level ? `, 升级到${keymay[blobData.next_level]}可解锁${nextLevelCount}个/${nextLevelUnit.unit}` : ''}`
                const slotStr = blobData.next_level ? t('other.http.httpCodeErrUpgrade1', [keymay[blobData.next_level], nextLevelCount, nextLevelUnit.unit]) : ''
                str = t('other.http.httpCodeErr3', [fileType, currentLevelCount, currentLevelUnit.unit, slotStr])
                if (blobData.current_level !== 'free') {
                  store.commit('SHOWFREQUENCY', str)
                }
              } else {
                // str = `本${currentLevelUnit.unit}提交样本数量已超过${currentLevelCount}个/${currentLevelUnit.unit}限制${blobData.next_level ? `, 升级到${keymay[blobData.next_level]}可解锁${nextLevelCount}个/${nextLevelUnit.unit}` : ''}`
                const slotStr = blobData.next_level ? t('other.http.httpCodeErrUpgrade1', [keymay[blobData.next_level], nextLevelCount, nextLevelUnit.unit]) : ''
                str = t('other.http.httpCodeErr4', [currentLevelUnit.unit, currentLevelCount, currentLevelUnit.unit, slotStr])
                store.commit('SHOWFREQUENCY', str)
              }
            }
          }
          // store.commit('SHOWFREQUENCY', str)
        })
      } else if (data.rate) {
        const nextLevel = data.rate.split('/')[1]
        const nextLevelUnit = data.next_level ? splitUnit(nextLevel) : { unit: '', count: '' }
        const nextLevelCount = data.next_level ? data.next_group_limit[nextLevel] : 0
        const currentLevelUnit = splitUnit(data.rate.split('/')[1])
        const currentLevelCount = data.limit
        const type = data.rate.split('/')[1].includes('m') ? 'frequency' : 'limit'
        let str = ''
        if (data.group === 'view.task') {
          // str = `本${currentLevelUnit.unit}查看任务报告超出 ${currentLevelCount}次/${currentLevelUnit.unit} 限制${data.next_level ? `, 升级到${keymay[data.next_level]}可解锁${nextLevelCount}次/${nextLevelUnit.unit}` : ''}`
          const slotStr = data.next_level ? t('other.http.httpCodeErrUpgrade2', [keymay[data.next_level], nextLevelCount, nextLevelUnit.unit]) : ''
          str = t('other.http.httpCodeErr5', [currentLevelUnit.unit, currentLevelCount, currentLevelUnit.unit, slotStr])
        } else if (data.group === 'submit.task') {
          if (type === 'frequency') {
            // str = `提交频率超出${currentLevelCount}次/${currentLevelUnit.unit}限制${data.next_level ? `, 升级到${keymay[data.next_level]}可解锁${nextLevelCount}次/${nextLevelUnit.unit}` : ''}`
            const slotStr = data.next_level ? t('other.http.httpCodeErrUpgrade2', [keymay[data.next_level], nextLevelCount, nextLevelUnit.unit]) : ''
            str = t('other.http.httpCodeErr2', [currentLevelCount, currentLevelUnit.unit, slotStr])
          } else {
            // str = `本${currentLevelUnit.unit}提交样本数量已超过${currentLevelCount}个/${currentLevelUnit.unit}限制${data.next_level ? `, 升级到${keymay[data.next_level]}可解锁${nextLevelCount}个/${nextLevelUnit.unit}` : ''}`
            const slotStr = data.next_level ? t('other.http.httpCodeErrUpgrade1', [keymay[data.next_level], nextLevelCount, nextLevelUnit.unit]) : ''
            str = t('other.http.httpCodeErr4', [currentLevelUnit.unit, currentLevelCount, currentLevelUnit.unit, slotStr])
          }
        } else if (data.group === 'search') {
          if (type === 'frequency') {
            // str = `搜索频率超出${currentLevelCount}次/${currentLevelUnit.unit}限制${data.next_level ? `, 升级到${keymay[data.next_level]}可解锁${nextLevelCount}次/${nextLevelUnit.unit}` : ''}`
            const slotStr = data.next_level ? t('other.http.httpCodeErrUpgrade2', [keymay[data.next_level], nextLevelCount, nextLevelUnit.unit]) : ''
            str = t('other.http.httpCodeErr6', [currentLevelCount, currentLevelUnit.unit, slotStr])
          } else {
            // str = `本${currentLevelUnit.unit}搜索数量已超过${currentLevelCount}次/${currentLevelUnit.unit}限制${data.next_level ? `, 升级到${keymay[data.next_level]}可解锁${nextLevelCount}次/${nextLevelUnit.unit}` : ''}`
            const slotStr = data.next_level ? t('other.http.httpCodeErrUpgrade2', [keymay[data.next_level], nextLevelCount, nextLevelUnit.unit]) : ''
            str = t('other.http.httpCodeErr8', [currentLevelUnit.unit, currentLevelCount, currentLevelUnit.unit, slotStr])
          }
        } else if (data.group === 'chat.ai') {
          return Promise.reject(error)
        } else if (data.group === 'download.pdf') {
          store.commit('SHOWMESSAGE', { type: 'error', message: t('other.http.errDownloadPdf'), duration: 3000 })
          return
        } else {
          // str = `下载超过${currentLevelCount}个/${currentLevelUnit.unit}的限制${data.next_level ? `, 升级到${keymay[data.next_level]}可解锁${nextLevelCount}个/${nextLevelUnit.unit}` : ''}`
          const slotStr = data.next_level ? t('other.http.httpCodeErrUpgrade1', [keymay[data.next_level], nextLevelCount, nextLevelUnit.unit]) : ''
          str = t('other.http.httpCodeErr7', [currentLevelCount, currentLevelUnit.unit, slotStr])
        }
        // if (type === 'frequency') {
        //   str = `提交频率超出${currentLevelCount}次/${currentLevelUnit.unit}限制${data.next_level ? `, 升级到${keymay[data.next_level]}可解锁${nextLevelCount}次/${nextLevelUnit.unit}` : ''}`
        // } else {
        //   if (data.group.includes('download')) {
        //     str = `下载超过${currentLevelCount}个/${currentLevelUnit.unit}的限制${data.next_level ? `, 升级到${keymay[data.next_level]}可解锁${nextLevelCount}个/${nextLevelUnit.unit}` : ''}`
        //   } else {
        //     str = `本${currentLevelUnit.unit}提交样本数量已超过${currentLevelCount}个/${currentLevelUnit.unit}限制${data.next_level ? `, 升级到${keymay[data.next_level]}可解锁${nextLevelCount}个/${nextLevelUnit.unit}` : ''}`
        //   }
        // }
        store.commit('SHOWFREQUENCY', str)
      } else {
        store.commit('SHOWMESSAGE', { type: 'error', message: data, duration: 3000 })
      }
    } else if (status === HTTP_CODE_ERROR_OTHER) {
      return Promise.reject(error.response.data)
    } else if (status >= HTTP_CODE_ERROR_SERVER) {
      store.commit('SHOWMESSAGE', { type: 'error', message: t('other.http.serverErr'), duration: 3000 })
    }
    return Promise.reject(error)
  }
)

export default service
