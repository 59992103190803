export default {
  taskhub: {
    public: {
      title: 'Public task'
    },
    history: {
      title: 'History',
      err_hash: 'Exception hash commit record',
      all: 'All',
      file: 'File',
      public: 'Set pulic',
      private: 'Set private',
      share: 'Share to teams',
      share_warn: 'Please select a task to share',
      delete: {
        label: 'Delete task',
        title: 'Confirm deletion task',
        tips: 'When more than 50 tasks are selected, only the first 50 tasks in the task list will be deleted.',
        warn: 'Task deletion is irreversible and all content reported by the selected task will no longer be visible to anyone. Are you sure you want to delete {html} tasks?'
      },
      failed: {
        title: 'Analysis failed for this task',
        info: 'The analysis of this task failed and cannot be displayed. Do you want to delete this task?'
      }
    },
    team: {
      title: 'Team Tasks',
      manage: 'my teams',
      admin: 'Administrator',
      member: 'Member',
      nodata: 'This team has no tasks yet',
      noteam: 'No team tasks yet',
      all: 'all',
      file: 'File',
      remove: 'Move out',
      warning: 'Please select a task to remove',
      success: 'Move out successfully'
    },
    search: {
      todetect: 'No tasks matching your query {type} have been found yet. Please try the {detect} function?',
      detect: 'Task submission',
      all: 'All tasks',
      public: 'Public task',
      history: 'History',
      team: 'Team Tasks',
      set_public: 'Set pulic',
      set_private: 'Set private',
      all_team: 'All teams',
      domain: 'Domain',
      keyword: 'Key words',
      filesize: 'File size',
      format: 'file format',
      earliest: 'Earliest time',
      latest: 'Latest time',
      task_num: 'Number of detected tasks',
      viewed_num: 'Viewable number of tasks'
    },
    filter: {
      title: 'filter',
      time_start: 'start date',
      separator: 'to',
      time_end: 'end date',
      lastweek: 'last week',
      lastmonth: 'last month',
      last_three_month: 'last three months',
      last_six_month: 'last six months',
      lastyear: 'last year',
      submitter: 'Please select the submitter',
      search: 'search',
      reset: 'clean',
      selected: 'Selected conditions',
      all: 'all',
      invalid: 'Invalid filter',
      label: {
        time: 'Time limit',
        type: 'Task type',
        tag: 'Tag',
        os_env: 'Environment',
        status: 'Status',
        score: 'Score',
        submitter: 'Submitter'
      }
    },
    statistics: {
      title: 'statistics',
      day: '24 hours',
      week: '7 days',
      all: 'general task',
      done: 'Completed',
      running: 'Detecting',
      waiting: 'Waiting',
      failed: 'Detection failed',
      type: 'Task type rate',
      exploit: 'Trending exploits',
      result: 'Detection result rate',
      cve: 'Trending CVEs',
      file_type: 'Total file type distribution',
      malice: 'Distribution of malicious file types',
      behavior: 'Behavior distribution',
      environment: 'Environment statistics',
      trend: 'Task submission trend',
      threat: 'Malicious detection trend',
      tag: 'Trending tags',
      trend_tip: 'Number of tasks：',
      trend_date_tip: 'Date：',
      trend_yAxis: 'Number of tasks',
      trend_xAxis: 'time',
      tag_xAxis: 'category',
      tag_yAxis: 'Number '
    },
    static: 'Static detection',
    nodata: 'No task report to display'
  }
}
